const Constants = {
    premiumCompleteSku: '8950A884',
    defaultTagColor: '#CFDAD5',
    defaultTagColorRaw: 'CFDAD5',
    categoryTabs: {
        info: 'Info',
        contacts: 'Key Contacts',
        links: 'Useful Links'
    },
    contentSearchType: 'content',
    titleSearchType: 'titles',
    graphicsSearchType: 'graphics',
    links: {
        help: 'https://support.iccsafe.org/?ht_kb_category=digital-codes',
        iccSafeExamCatalog: 'https://www.iccsafe.org/certification-exam-catalog',
        iccSafeRemoteOnlineTesting: 'https://www.iccsafe.org/professional-development/assessment-center/proctored-remote-online-testing/',
        userExams: 'https://my.iccsafe.org/credentials/exams',
        userExamCatalog: 'https://my.iccsafe.org/credentials/exam-catalog',
        quickStart: 'https://support.iccsafe.org/article-categories/quick-start/',
        contactSupport: 'https://www.iccsafe.org/about/contact-icc/',
        toolbarSupportPage: 'https://support.iccsafe.org/ht_kb/digital-codes/using-premium-toolbar-to-navigate-contents/',
        notesSupportPage: 'https://support.iccsafe.org/ht_kb/digital-codes/how-to-create-notes/',
        insightsSupportPage: 'https://support.iccsafe.org/ht_kb/digital-codes/accessing-premium-code-insights/',
        searchSupportPage: 'https://support.iccsafe.org/ht_kb/digital-codes/how-to-perform-advanced-searches/',
        welcomeVideo: 'https://www.youtube.com/playlist?list=PL0sbkPS30egMBqcwuCeZkORVg0c3C_pQi',
        shopPronto101: 'https://shop.iccsafe.org/pronto-101.html',
        premiumAccessContents: 'https://support.iccsafe.org/ht_kb/digital-codes/basic-vs-premium-view/',
        premiumSubscription: 'https://support.iccsafe.org/ht_kb/digital-codes/understanding-types-of-premium-subscription-levels/',
        PremiumTitles: 'https://support.iccsafe.org/ht_kb/digital-codes/how-to-navigate-contents-across-premium-titles/',
        technicalOpinions: 'https://www.iccsafe.org/products-and-services/technical-opinions/',
        clearCalcUrl: "https://www2.clearcalcs.com/l/944993/2023-11-03/6ld8fj",
        shopManagePayments: 'paymentechhpf/paymentoptions/savedcards/'
    },
    userTier: {
        staff: 'Premium Staff',
        complete: 'Premium Complete',
        single: 'Premium Single',
        collection: 'Premium Collection',
        trial: 'Premium Trial',
        basic: 'Basic'
    },
    basicAndBasicPlusLevels: [1, 2],
    singleTitleLevels: [1, 2, 3, 4, 5, 8, 9, 13, 14],
    premiumOnlyBookLevels: [7, 8],
    premiumBasicBookLevels: [9, 10, 11],
    premiumBundledBookLevels: [3, 4],
    premiumCompleteBundleLevels: [5, 6],
    basicAccessLevels: [1, 2, 9, 10, 11, 12, 14], /* Always read only (without login also) */
    nonBasicAccessLevels: [3, 4, 5, 6, 7, 8, 13],
    withCollectionLevels: [13, 14],
    accessLevels: {
        basic: 'BASIC',
        basicPlus: 'BASIC PLUS',
        basicReadOnly: 'BASIC READ ONLY',
        premium: 'PREMIUM',
        premiumLite: 'PREMIUM LITE',
        premiumRequired: 'PREMIUM REQUIRED',
    },
    documentSearchCategory: 'This Title',
    titleSearchAllSlug: 'all-titles',
    completePrices: {
        month: '88',
        memberMonth: '66',
        year: '960',
        memberYear: '720',
    },
    collectionsPrices: {
        startingPerMonth: '30'
    },
    singlesPrices: {
        startingPerMonth: '9'
    },
    contentTypeIccXml: 'ICC XML',
    contentTypeIccXml2: 'ICC XML2.0',
    contentTypeIccEsXml: 'ICC ES XML',
    contentTypePDF: 'PDF',
    contentTypeNewDbSchemaXml: 'New DB schema XML',
    contentTypePdf: 'PDF',
    esAcLink: 'AC Link',
    contentTypeCollection: 'Collection',
    contentTypeComplete: 'Premium Complete',
    astmSlug: 'astm-standards',
    astmCategory: 'ASTM Standards',
    titlesCount: '1000',
    enterpriseAccepted: 'Accepted',
    enterpriseDeclined: 'Declined',
    enterprisePending: 'Pending',
    defaultCodeCycleLocation: 'I-Codes',
    defaultCodeCycleYear: '2024',
    shopEncodesUrl: 'ecodes/account/products/#vrow-0',
    icc_membership_info_url: 'https://www.iccsafe.org/membership/about-membership',
    icc_membership_application_url: 'https://www.iccsafe.org/membership/join-icc',
    preferenceKey: 'preferences:cover_view',
    sales_email_address: 'digitalcodessales@iccsafe.org',
    demoUrl: 'https://calendly.com/iccphil/icc-digital-codes-platform-demo?utm_campaign=dcpteamspg',
    defaultQuickQuestionFilter: 'All'

};

Constants.install = function (Vue) {
    Vue.prototype.$getConst = (key) => {
        return Constants[key];
    };
};

export default Constants;
