import axios from 'axios';

export class QuickConsultApi {
    static async getUserQuickConsultQuestions() {
        const route = Routing.generate('questions_index');
        return axios.get(route).then((res) => {
            const data = res.data;
            return data?.questions || [];
        });
    }

    static async getQuestionDetails(questionId) {
        return axios.get(Routing.generate('question_show', { questionId })).then((res) => {
            return res.data;
        });
    }

    static async postQuestion(data) {
        return axios.post(Routing.generate('question_new'), data).then((res) => {
            return res.data;
        });
    }

    static async titleSearchAutoComplete(postData) {
        return axios.post(Routing.generate('autosuggest_titles'), postData).then((res) => {
            return res.data;
        });
    }

    static async getAttachment(attachmentId) {
        try {
            const response = await axios({
                url: Routing.generate('get_attachment', { id: attachmentId }),
                method: 'GET',
                responseType: 'blob',
            });
            return response.data;
        } catch (error) {
            console.error('Error downloading attachment:', error);
            throw error;
        }
    }

    static async getQuestionWebLinks(questionId) {
        return axios.get(Routing.generate('question_weblinks', {questionId: questionId})).then((res) => {
            return res.data;
        });
    }
}
