import EventBus from "../events";
import {ApiService} from "../../../api/ApiService";

export default {
    data() {
        return {
            currentSubscriptionType: this.$session.has('subscriptionType')
                ? this.$session.get('subscriptionType') : ''
        };
    },
    computed: {
        isActiveUser() {
            return this.$store.getters.getLoggedIn !== ''
        },
        trialStarted: {
            get: function () {
                return this.$store.getters.hasTrialStarted;
            },
            set: function (value) {
                this.$store.commit('setTrialStarted', value);
            }
        },
        trialEndDate() {
            return this.$store.getters.getUser ? this.$store.getters.getUser.trial.remainingDays : 0;
        },
        trialActive: {
            get: function () {
                return this.$store.getters.isTrialActive;
            },
            set: function (value) {
                this.$store.commit('setTrialActive', value);
            }
        },
        trialStatus() {
            if (this.trialStarted) {
                if (!this.trialActive) {
                    return 'expired';
                }
                return 'active';
            }
            return 'neverstarted';
        },
        isEnterpriseOrConcurrentActive() {
            return this.activeEnterpriseProfile || this.activeConcurrentProfile;
        },
        canStartTrial() {

            /** @type {User} */
            const user = this.$store.getters.getUser;

            return user &&
                !user.trial.isTrialActive && user.trial.canStartTrial &&
                !this.hasPremiumSubscription && !this.isEnterpriseOrConcurrentActive
        },
        activeConcurrentCode: {
            get: function () {
                return this.$store.getters.getConcurrentCode;
            },
            set: function (value) {
                this.$store.commit('setConcurrentCode', value);
            }
        },
        premiumSubscriptionSet: {
            get: function () {
                return this.$store.getters.getPremiumSubscriptionSet;
            },
            set: function (value) {
                this.$store.commit('setPremiumSubscriptionSet', value);
            }
        },
        premiumSubscriptions: {
            get: function () {
                return this.$store.getters.getPremiumSubscriptions;
            },
            set: function (value) {
                this.$store.commit('setPremiumSubscriptions', value);
            }
        },
        premiumBundleSet: {
            get: function () {
                return this.$store.getters.getPremiumBundleSet;
            },
            set: function (value) {
                this.$store.commit('setPremiumBundledSet', value);
            }
        },
        premiumBundledTitles: {
            get: function () {
                return this.$store.getters.getPremiumBundledTitles;
            },
            set: function (value) {
                this.$store.commit('setPremiumBundledTitles', value);
            }
        },
        hasPremiumSubscription() {
            return _.size(this.premiumSubscriptions) > 0;
        },
        isEnterpriseAdmin: {
            get: function () {
                return this.$store.getters.isEnterpriseAdmin;
            },
            set: function (value) {
                this.$store.commit('setIsEnterpriseAdmin', value);
            }
        },
        enterpriseId: {
            get: function () {
                return this.$store.getters.enterpriseId;
            },
            set: function (value) {
                this.$store.commit('setEnterpriseId', value);
            }
        },
        hasCompleteSubscription() {
            return this.hasPremiumSubscription && ('complete' === this.currentSubscriptionType);
        },
    },
    methods: {
        startTrial() {
            ApiService.startTrial().then((res) => {
                this.$cookies.set('showTrialStartedBanner', true);
                this.clearUserSession()
                this.$nextTick(() => {
                    window.location.href = Routing.generate("dashboard_landing");
                });
            });
        },
        isActiveAdmin() {
            const user = this.$store.getters.getUser;
            if (user) {
                const {roles, has_admin_access} = user;
                return has_admin_access || Array.isArray(roles) && roles.includes('ROLE_SUPER_ADMIN');
            }
            return false;
        },
        clearPremiumSubscriptions() {
            this.premiumSubscriptions = [];
            this.premiumSubscriptionSet = false;
        },
        isPremiumSubscribed: function (book_sku) {
            return (this.hasPremiumSubscription) ? _.includes(this.premiumSubscriptions, book_sku) : false;
        },
        isTrialExcluded(title) {
            return !this.isPremiumSubscribed(title.sku) &&
                this.trialActive && (
                    title.is_excluded ||
                    title.is_astm
                )
        },
        isContentRestricted(title) {
            let basicViewAllowed = _.includes(
                this.$getConst('basicAccessLevels'),
                title.content_access_level ?
                    title.content_access_level.id :
                    null
            );
            return !(basicViewAllowed || this.isPremiumSubscribed(title.sku));
        },
        /**
         * @param contentAccessLevel {number|null}
         * @returns {boolean}
         */
        isBasicAccessBook(contentAccessLevel) {
            /** @type {Number[]} */
            const basicAccessLevels = this.$getConst('basicAccessLevels');

            return basicAccessLevels.indexOf(contentAccessLevel) !== -1;
        },
        isBasicAndBasicPlus(contentAccessLevel) {
            return _.includes(
                this.$getConst('basicAndBasicPlusLevels'),
                this.getContentAccessLevel(contentAccessLevel)
            );
        },
        isPremiumOnlyBook(contentAccessLevel) {
            return _.includes(
                this.$getConst('premiumOnlyBookLevels'),
                this.getContentAccessLevel(contentAccessLevel)
            );
        },
        isSingleTitle(title) {
            let id = title.content_type?.id;
            if (id) {
                return (
                    _.includes(
                        this.$getConst('singleTitleLevels'),
                        id
                    ));
            }
            return false;
        },
        isBundledPremiumOnlyBook(title) {
            if (this.isSingleTitle(title)) {
                // This is needed for non standardized data.
                let contentAccessLevel = title.content_access_level ? title.content_access_level : title.accessLevel;

                return (
                    _.includes(
                        this.$getConst('premiumBundledBookLevels'),
                        this.getContentAccessLevel(contentAccessLevel)
                    ) ||
                    _.includes(
                        this.$getConst('premiumCompleteBundleLevels'),
                        this.getContentAccessLevel(contentAccessLevel)
                    ) ||
                    _.includes(
                        this.$getConst('withCollectionLevels'),
                        this.getContentAccessLevel(contentAccessLevel)
                    ) ||
                    _.includes(
                        this.$getConst('basicAndBasicPlusLevels'),
                        this.getContentAccessLevel(contentAccessLevel)
                    )
                );
            }
            return false;
        },
        isPremiumBundledBook(contentAccessLevel) {
            return _.includes(
                this.$getConst('premiumBundledBookLevels'),
                this.getContentAccessLevel(contentAccessLevel)
            );
        },
        isPremiumCompleteBundledBook(contentAccessLevel) {
            return _.includes(
                this.$getConst('premiumCompleteBundleLevels'),
                this.getContentAccessLevel(contentAccessLevel)
            );
        },
        hasPremiumComplete() {
            let subscriptionType = this.$session.get('subscriptionType');
            return (!_.isUndefined(subscriptionType)) ? 'complete' === subscriptionType : false;
        },
        getContentAccessLevel(contentAccessLevel) {
            return contentAccessLevel ?
                contentAccessLevel.id :
                0;
        },
        getSubscriptionModalData(book) {
            return {
                documentId: book.document_id,
                isPremiumBundled: this.isPremiumBundledBook(book.content_access_level),
                isPremiumComplete: this.isPremiumCompleteBundledBook(book.content_access_level)
            }
        },
        setSubscriptions() {
            this.$http.get(Routing.generate('subscription_info')).then(
                response => {
                    const {subscriptions, subscriptionType} = response.data;

                    this.premiumSubscriptions = this.getBookSkus(subscriptions);
                    this.$session.set('subscriptionType', subscriptionType);
                    EventBus.fire('set-subscription-type', subscriptionType);
                    if (this.$session.get('subscriptionType').length) {
                        EventBus.fire('set-content-search-categories');
                    }
                    this.$session.set('lastSyncDate', this.user.lastSyncDate);
                    this.$store.commit('setSubscriptionLoaded', true);
                    this.sendPageView();

                    // only cache if there is ANY subscription data to cache
                    this.premiumSubscriptionSet = Array.isArray(subscriptions) && subscriptions.length;
                });
        },
        setBundledTitles() {
            this.premiumBundleSet = false;
            return this.$http.get(Routing.generate('premium_bundled_books')).then(
                response => {
                    this.premiumBundledTitles = this.getBookSkus(response.data);
                    this.$nextTick(() => {
                        this.premiumBundleSet = true;
                    });
                });
        },
        getBookSkus(subscriptions) {
            let skus = [];
            if(subscriptions.length) {
                _.each(subscriptions, (subscription) =>  {
                    let sku = '';
                    if (!_.isUndefined(subscription.sku)) {
                        sku = subscription.sku;
                    } else if (!_.isUndefined(subscription.document)) {
                        sku = subscription.document.sku;
                    }
                    if(sku.length) {
                        skus.push(sku);
                    }
                });
            }

            return skus;
        },
        updatePremiumCompleteConversionUrl(url = null) {

            if (url === null) {
                url = window.location.href;
            }

            return ApiService.updateTrialConversion(url);
        },
        async pathmonkConversionPixel() {
            const d = document;
            const key = "e2RFiCOnSFhcMMeobzPOAUA06";
            let js, si = 'pathmonk-js-track';
            let dv, vi = 'pathmonk-track';
            if (d.getElementById(si)) return;
            if (d.getElementById(vi)) return;
            dv = d.createElement('div');
            dv.id = vi;
            dv.setAttribute("new","off")
            dv.style.cssText = "display:none";
            dv.setAttribute("client_id", key);
            d.body.appendChild(dv);
            js = d.createElement('script');
            js.id = si;
            js.src = 'https://pathmonk-lib.pathmonk.com/plugin/track.min.js';
            d.body.appendChild(js);
        },
    }
}
