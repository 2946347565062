var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "content-container",
        class: _vm.isMobilePort ? "px-4 pt-4" : "",
      },
      [
        _c("hero-banner-new", {
          attrs: {
            title: "Enhance productivity with Premium solutions for teams",
            subtitle:
              "Coordinate team access to ICC Digital Codes Premium content and features for enhanced collaboration. Find tailored solutions that fit your needs and budget.",
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function () {
                return [
                  _c("contact-sales-button", { staticClass: "mr-1" }),
                  _vm._v(" "),
                  _c("get-demo-button", { staticClass: "mx-1" }),
                ]
              },
              proxy: true,
            },
            {
              key: "image",
              fn: function () {
                return [
                  _c("img", {
                    style: _vm.isMobilePort ? "" : "max-width: 580px;",
                    attrs: {
                      alt: "Team looking at a computer screen",
                      src: _vm.asset(
                        require("../../images/teams/teams_header.png")
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "p",
                    { staticClass: "text-body-2 text--secondary mb-0 mt-6" },
                    [
                      _vm._v(
                        "\n                    Already have an Enterprise account or concurrent code?\n                    "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-underline",
                          attrs: { href: _vm.getRoute("security_login") },
                        },
                        [
                          _vm._v(
                            "\n                        Sign in to access\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c(
          "v-row",
          { attrs: { dense: "" } },
          [
            _c(
              "v-col",
              { staticClass: "text-center" },
              [
                _c("h5", { staticClass: "text-h6 text--secondary" }, [
                  _vm._v(
                    "\n                    Leaders in industry and government rely on Digital Codes\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("logo-carousel", { staticClass: "mt-6 mb-16" }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grey lighten-5 py-16" },
      [
        _c(
          "div",
          { staticClass: "text-center", class: _vm.isMobilePort ? "px-4" : "" },
          [
            _c("h4", { staticClass: "text-h4 font-weight-bold oxygen" }, [
              _vm._v(
                "\n                Flexible plans for teams of any size\n            "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mt-4 mb-0" }, [
              _vm._v(
                "\n                Choose from our plans designed for small teams or enterprise-level collaboration\n            "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "v-row",
          { attrs: { justify: "center" } },
          _vm._l(_vm.teamPlans, function (item, key) {
            return _c("v-col", { key: key, attrs: { cols: "auto" } }, [
              _c(
                "div",
                { staticClass: "py-6 fill-height" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "fill-height rounded-lg elevation-4",
                      attrs: { "max-width": "384" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-4" },
                        [
                          _c("p", { staticClass: "text-overline" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.heading) +
                                "\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "h5",
                            { staticClass: "text-h5" },
                            [
                              _c(
                                "v-avatar",
                                {
                                  attrs: {
                                    size: "24",
                                    color: item.title.iconColor,
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "grey darken-4",
                                        size: "16",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        mdi-creation\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.title.text) +
                                  "\n                            "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-body-1 mt-4" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.description) +
                                "\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(item.items, function (listItem, key) {
                            return _c(
                              "v-row",
                              { key: key, attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "auto" } },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-0",
                                        attrs: { color: "teal", left: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        mdi-check-circle-outline\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-col", [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(listItem) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "v-row",
          { attrs: { dense: "", justify: "center" } },
          [
            _c(
              "v-col",
              { attrs: { cols: "auto" } },
              [_c("contact-sales-button")],
              1
            ),
            _vm._v(" "),
            _c(
              "v-col",
              { attrs: { cols: "auto" } },
              [_c("get-demo-button")],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content-container",
        class: _vm.isMobilePort ? "px-4" : "",
      },
      [
        _c("info-banner", {
          attrs: {
            title: "Achieve more together with collaborative tools",
            subtitle:
              "Find exactly what you need with advanced search filters. Stay organized and save time with bookmarking and note features.",
            "text-right": !_vm.isMobilePort,
          },
          scopedSlots: _vm._u([
            {
              key: "image",
              fn: function () {
                return [
                  _c("img", {
                    style: _vm.isMobilePort ? "" : "max-width: 580px;",
                    attrs: {
                      alt: "Digital Codes Premium tools",
                      src: _vm.asset(
                        require("../../images/teams/teams_premium_tools.png")
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "button",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        large: "",
                        href: _vm.getRoute("premium_features"),
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Learn About Premium\n                "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c(
          "v-row",
          _vm._l(_vm.tools, function (item, key) {
            return _c(
              "v-col",
              { key: key, attrs: { cols: "12", sm: "4" } },
              [
                _c("icon-card", {
                  staticClass: "fill-height",
                  attrs: { item: item },
                }),
              ],
              1
            )
          }),
          1
        ),
        _vm._v(" "),
        _c("info-banner", {
          attrs: {
            title: "Operate at scale with Premium Enterprise",
            subtitle:
              "Dedicated access for your entire team, anytime and anywhere. Enhance operational efficiency with our custom solutions and streamlined onboarding.",
          },
          scopedSlots: _vm._u([
            {
              key: "image",
              fn: function () {
                return [
                  _c("img", {
                    style: _vm.isMobilePort ? "" : "max-width: 580px;",
                    attrs: {
                      alt: "Digital Codes Premium tools",
                      src: _vm.asset(
                        require("../../images/teams/team_enterprise.png")
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "button",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [_c("contact-sales-button")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [_c("get-demo-button")],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c(
          "v-row",
          _vm._l(_vm.enterpriseTools, function (item, key) {
            return _c(
              "v-col",
              { key: key, attrs: { cols: "12", sm: "4" } },
              [
                _c("icon-card", {
                  staticClass: "fill-height",
                  attrs: { "icon-color": "cdpBlue", item: item },
                }),
              ],
              1
            )
          }),
          1
        ),
        _vm._v(" "),
        !_vm.isMobilePort
          ? _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "text-h4 font-weight-bold oxygen text-center mt-16 mb-4",
                      },
                      [
                        _vm._v(
                          "\n                    Compare Premium plans for teams\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-simple-table", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      { staticClass: "text-left text-h6" },
                                      [
                                        _vm._v(
                                          "\n                                Features\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      { staticClass: "text-center text-h6" },
                                      [
                                        _vm._v(
                                          "\n                                Premium\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      { staticClass: "text-center text-h6" },
                                      [
                                        _vm._v(
                                          "\n                                Premium Enterprise\n                            "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.compareTable,
                                    function (item, key) {
                                      return _c("tr", { key: key }, [
                                        _c(
                                          "td",
                                          [
                                            item.feature instanceof Object
                                              ? [
                                                  item.feature.link
                                                    ? [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: item.feature
                                                                .link,
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  item.feature
                                                                    .title
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                mdi-open-in-new\n                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.feature.tooltip
                                                    ? [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                    " +
                                                                              _vm._s(
                                                                                item
                                                                                  .feature
                                                                                  .title
                                                                              ) +
                                                                              "\n                                                    "
                                                                          ),
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        mdi-information-outline\n                                                    "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            [
                                                              _vm._v(
                                                                "\n                                                " +
                                                                  _vm._s(
                                                                    item.feature
                                                                      .tooltip
                                                                  ) +
                                                                  "\n                                            "
                                                              ),
                                                            ],
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ]
                                              : [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(item.feature) +
                                                      "\n                                "
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            item.premium === "check"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    mdi-check-circle-outline\n                                "
                                                    ),
                                                  ]
                                                )
                                              : _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.premium
                                                    ),
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            item.enterprise === "check"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    mdi-check-circle-outline\n                                "
                                                    ),
                                                  ]
                                                )
                                              : _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.enterprise
                                                    ),
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2563947375
                      ),
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-body-1 text-center mb-0" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.getRoute("premium_features"),
                            title: "Learn more",
                          },
                        },
                        [_vm._v("Learn more")]
                      ),
                      _vm._v(
                        " about all the included Premium features\n                "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grey lighten-5 py-16 my-16" },
      [_c("customer-testimonials")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-container pb-16" },
      [
        _c(
          "v-row",
          { attrs: { dense: "", justify: "center" } },
          [
            _c("v-col", { staticClass: "text-center", attrs: { cols: "12" } }, [
              _c("h5", { staticClass: "text-h6 font-weight-bold mb-4" }, [
                _vm._v(
                  "\n                    Take the next step towards success - contact our sales team today!\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "v-col",
              { attrs: { cols: "auto" } },
              [_c("contact-sales-button")],
              1
            ),
            _vm._v(" "),
            _c(
              "v-col",
              { attrs: { cols: "auto" } },
              [_c("get-demo-button")],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }