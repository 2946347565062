var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "hero-container",
      class: {
        "py-16": !_vm.isMobilePort,
        "pb-8": _vm.isMobilePort,
      },
      attrs: { align: "center", justify: "center" },
    },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "6", order: _vm.textRight ? 2 : 1 } },
        [
          _c("h2", {
            staticClass: "text-h4 font-weight-bold oxygen mb-6",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _vm.subtitle
            ? _c("h3", {
                staticClass: "text-body-1 mb-6",
                domProps: { innerHTML: _vm._s(_vm.subtitle) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("button"),
          _vm._v(" "),
          _vm._t("footer"),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-col",
        {
          class: { "text-center": _vm.isMediumAndDown },
          attrs: { order: _vm.textRight ? 1 : 2 },
        },
        [_vm._t("image")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }