<template>
    <v-card
        id="premiumAnswersFilter"
        outlined>
        <v-card-text class="pb-2">
            <h5 class="text-h5 font-weight-medium">
                Question and Answers Database
            </h5>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="filters.search"
                        @keyup.enter="handleSearch"
                        outlined
                        dense
                        hide-details
                        label="Search"
                        append-icon="mdi-magnify"
                        @click:append="handleSearch">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-row
                    v-show="expand"
                    align="center">
                    <v-col
                        cols="12"
                        sm="6">
                        <v-autocomplete
                            v-model="filters.documentIds"
                            clearable
                            multiple
                            outlined
                            dense
                            :items="titles"
                            item-value="id"
                            item-text="title"
                            hide-details
                            :loading="titlesLoading"
                            :disabled="titlesLoading"
                            label="Title"
                            :menu-props="{ bottom: true, offsetY: true }">
                            <template v-slot:selection="{ item }">
                                <v-chip
                                    small
                                    color="primaryLight"
                                    class="primary--text">
                                    {{item.title}}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="auto"
                        :class="!isMobilePort && 'grow'">
                        <v-autocomplete
                            v-model="filters.topicIds"
                            chips
                            clearable
                            multiple
                            outlined
                            dense
                            :items="topics"
                            item-value="id"
                            item-text="title"
                            hide-details
                            :loading="topicsLoading"
                            :disabled="topicsLoading"
                            label="Topic"
                            :menu-props="{ bottom: true, offsetY: true }">
                            <template v-slot:selection="{ item }">
                                <v-chip
                                    small
                                    color="cdpBlueLight"
                                    class="cdpBlue--text">
                                    {{item.title}}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="auto">
                        <v-btn
                            :block="isMobilePort"
                            outlined
                            color="primary"
                            @click="$emit('clearFilters')">
                            Reset filters
                        </v-btn>
                    </v-col>
                </v-row>
            </v-expand-transition>
            <v-row v-if="isMobilePort">
                <v-col>
                    <p
                        class="fs-16 primary--text mb-0 text-decoration-underline"
                        @click="expand = !expand">
                        {{expand ? 'Hide filters' : 'Show filters'}}
                    </p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {PremiumAnswersApi} from "../../api/PremiumAnswers";
import Mobile from "../inc/mixins/mobile";

    export default {
        name: 'SearchQuestions',
        emits: ['clearFilters', 'enterClicked'],
        data() {
            return {
                titles: [],
                titlesLoading: true,
                topics: [],
                topicsLoading: true,
                expand: true
            }
        },
        props: {
            filters: {
                required: true,
                type: Object
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            async getFilterTitles() {
                const response = await PremiumAnswersApi.getFilterTitles()
                this.titles = response.data
                this.titlesLoading = false
            },
            async getFilterTopics() {
                const response = await PremiumAnswersApi.getFilterTopics()
                this.topics = response.data
                this.topicsLoading = false
            },
            handleSearch() {
                this.$emit('enterClicked')
            }
        },
        mounted() {
            this.getFilterTitles()
            this.getFilterTopics()
        }
    }
</script>
