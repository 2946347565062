var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitFormData.apply(null, arguments)
                },
              },
            },
            [
              _c("h5", { staticClass: "mb-2 text-h5 font-weight-medium" }, [
                _vm._v("Ask New Question"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-body-1 mb-8" }, [
                _vm._v("Required fields*"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-5" },
                [
                  _c("h6", { staticClass: "text-h6 font-weight-medium" }, [
                    _vm._v("Code Title and Section"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-body-2 text--secondary mb-2" }, [
                    _vm._v(
                      "Select the International Codes (I-Code) that your question applies to."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.isMobilePort ? 12 : 6 } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.titles,
                              loading: _vm.titlesLoading,
                              "search-input": _vm.titleSearch,
                              rules: [_vm.rules.required],
                              "no-filter": "",
                              outlined: "",
                              "hide-no-data": "",
                              clearable: "",
                              "hide-details": "",
                              "append-icon": "",
                              "item-text": "display_title",
                              "item-value": "display_title",
                              label: "Code Title*",
                              placeholder: "Start typing to Search",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.titleSearch = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.titleSearch = $event
                              },
                              keydown: _vm.debouncedGetResults,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c("title-list", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        title: item,
                                        type: "list",
                                        showLinkIcon: false,
                                        listOutline: false,
                                        preventLink: true,
                                        hover: false,
                                        background: "transparent",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.codeTitle,
                              callback: function ($$v) {
                                _vm.codeTitle = $$v
                              },
                              expression: "codeTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.isMobilePort ? 12 : 6 } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.rules.required],
                              "hide-details": "",
                              clearable: "",
                              outlined: "",
                              label: "Section Number*",
                            },
                            model: {
                              value: _vm.sectionNumber,
                              callback: function ($$v) {
                                _vm.sectionNumber = $$v
                              },
                              expression: "sectionNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-5" },
                [
                  _c("h6", { staticClass: "text-h6 font-weight-medium" }, [
                    _vm._v("Question"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-body-2 text--secondary mb-2" }, [
                    _vm._v(
                      "\n                    Include all the information the ICC team would need to answer your question.\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.rules.required],
                              label: "Question Subject*",
                              outlined: "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.subject,
                              callback: function ($$v) {
                                _vm.subject = $$v
                              },
                              expression: "subject",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              rules: [_vm.rules.required],
                              "hide-details": "",
                              label: "Question Details*",
                              outlined: "",
                            },
                            model: {
                              value: _vm.questionDetails,
                              callback: function ($$v) {
                                _vm.questionDetails = $$v
                              },
                              expression: "questionDetails",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-file-input", {
                            attrs: {
                              outlined: "",
                              multiple: "",
                              "hide-details": "",
                              label: "Attachments",
                            },
                            model: {
                              value: _vm.attachments,
                              callback: function ($$v) {
                                _vm.attachments = $$v
                              },
                              expression: "attachments",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              "hide-details": "",
                              rules: [
                                (v) =>
                                  !!v ||
                                  "You must accept the terms and conditions",
                              ],
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        "\n                                    I accept the "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "text-decoration-underline",
                                          attrs: { href: "", target: "_blank" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [_vm._v("Terms of Service")]
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.termsAccepted,
                              callback: function ($$v) {
                                _vm.termsAccepted = $$v
                              },
                              expression: "termsAccepted",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "mb-8" }, [
                          _vm._v(
                            "Submitting your question will use 1 credit. You will have 4 credits remaining."
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  depressed: "",
                                  type: "submit",
                                  loading: _vm.isProcessing,
                                  color: "primary",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Submit Question\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mx-1" }),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  outlined: "",
                                  color: "primary",
                                  href: this.getRoute("quick_consult"),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Cancel\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }