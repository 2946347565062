<template>
    <confirm-dialog
        v-if="dialog"
        :is-open="dialog"
        borderStyle
        showHeaderBorder
        noGrayActionArea
        :fullscreen="isMobilePort"
        @closed="emitClosed('frequency')">
        <template v-slot:title>
            {{ dialogTitle }}
        </template>
        <template v-slot:default>
            <div class="py-2">
                <h3>Title</h3>
                <p class="mb-0">{{ displayDocumentTitle }}</p>
            </div>
            <div class="my-2">
                <h3>License Quantity</h3>
                <p class="mb-0">
                    {{ item.licences}} licenses
                </p>
                <p class="mb-0 green--text darken-3" v-if="hasLicenseRemovalRequest">
                    Changes to {{ item.next_renewal_license }} on auto-renew
                </p>
            </div>
            <div class="my-4">
                <h3>Billing Frequency</h3>
                <v-radio-group
                    v-if="step === 1"
                    v-model="frequency"
                    mandatory
                    column
                    dense
                    hide-details
                    class="mt-0"
                    @change="updateFrequency">
                    <v-radio
                        class="mb-1"
                        :disabled="!getAvailableFrequencies.includes('M')"
                        :label="labelMessages.M"
                        value="M">
                    </v-radio>
                    <v-radio
                        class="mb-1"
                        :disabled="!getAvailableFrequencies.includes('B')"
                        :label="labelMessages.B"
                        value="B">
                    </v-radio>
                    <v-radio
                        class="mb-1"
                        :disabled="!getAvailableFrequencies.includes('C')"
                        :label="labelMessages.C"
                        value="C">
                    </v-radio>
                </v-radio-group>
                <template v-else>
                    <p class="mb-0 green--text darken-3">
                        {{ selectedBillingType }}
                    </p>
                    <p class="mb-0">
                        Changed from {{`${renewalBillingType}
                        ${ isAutoRenewSubscriptionType(item.next_renewal_subscription_type) ? '(on auto-renew)' : '' }`}}
                    </p>
                </template>

                <p
                    class="mb-0"
                    :class="isQuantityChanged ? 'green--text darken-3' : ''">
                    Auto-renews {{ formatAutoRenewDate() }} for ${{ getPrice |  formatPrice }} / {{ selectedBillingType }}
                </p>
                <p class="mb-0" v-if="isQuantityChanged">
                    Changed from ${{ initialPrice | formatPrice }} / {{ renewalBillingType }}
                </p>
            </div>
            <discount-text
                v-if="savingsText.length"
                :savingsText="savingsText">
            </discount-text>
            <div>
                <h3>Payment Method</h3>
                <p v-if="!invalidVisaCart">{{ visaCardNumber }}</p>
                <alert-bar
                    class="mt-3"
                    v-if="invalidVisaCart"
                    id="display_http_messages"
                    :message="`Payment method information not found. <a href='${getShopManagePaymentsPage()}' target='_blank' class='black--text text-decoration-underline'>
                        Update your payment method</a> to continue.`"
                    notificationType="warning">
                </alert-bar>
            </div>
            <p v-if="step === 2 && !invalidVisaCart">
                No payment required. This change will take effect when your subscription auto-renews on {{ formatAutoRenewDate() }}.
            </p>
            <contact-link-info></contact-link-info>
        </template>
        <template v-slot:buttons>
            <v-container class="py-0 px-2">
                <v-row dense>
                    <v-col>
                        <alert-bar
                            dismissible
                            @dismiss="dismissAlert"
                            id="display_http_messages"
                            :message="message"
                            :notificationType="notifyType">
                        </alert-bar>
                    </v-col>
                </v-row>
                <action-buttons
                    :step="step"
                    :isDisableReviewBtn="isDisableReviewBtn"
                    :loadingSubmit="loadingSubmit"
                    @go-back="handleGoBack"
                    @close="emitClosed('frequency')"
                    @review-changes="reviewChanges"
                    @submit-and-pay="submitAndPay">
                </action-buttons>
            </v-container>
        </template>
    </confirm-dialog>
</template>

<script>
    import AlertBar from '../../notifications/AlertBar.vue';
    import ConfirmDialog from '../../ConfirmDialog.vue';
    import DiscountText from './DiscountText.vue';
    import ContactLinkInfo from './ContactLinkInfo.vue';
    import ActionButtons from './ActionButtons.vue';
    import subscriptionChangeMixin from './../subscription-update/subcriptionChange.mixin';
    import Mobile from '../../inc/mixins/mobile';
    import { BillingApi } from '../../../api/BillingApi';

    export default {
        components: { AlertBar, ConfirmDialog, DiscountText, ContactLinkInfo, ActionButtons},
        data() {
            return {
            }
        },
        mixins: [
            Mobile,
            subscriptionChangeMixin
        ],
        props: {
            shopUrl: {
                required: true,
                type: String
            },
            userId: {
                type: String,
                required: false
            }
        },
        computed: {
            frequency: {
                get: function () {
                    return this.getFrequency;
                },
                // setter
                set: function (newValue) {

                }
            },
            isDisableReviewBtn() {
                return this.frequency === this.item.next_renewal_subscription_type || this.invalidVisaCart;
            },
            actionTriggered() {
                return 'Change Duration';
            },
            selectedBillingType() {
                const subscriptionLabels = {
                    'M': 'Monthly',
                    'B': 'Annually'
                };

                return subscriptionLabels[this.frequency] || '3-Year';
            },
            labelMessages() {
                return {
                    M: this.getLabelMessage('M', 'Monthly'),
                    B: this.getLabelMessage('B', 'Annually'),
                    C: this.getLabelMessage('C', '3-Year')
                };
            },
            hasPendingChangeRequest() {
                return this.item.next_renewal_subscription_type != this.item.subscription_type;
            },
            isQuantityChanged() {
                return this.frequency != this.item.next_renewal_subscription_type;
            },
            actionType() {
                // Map subscription types to their relative durations
                const durationMap = { 'M': 1, 'B': 2, 'C': 3 };

                // Get the numeric duration for the prior and current subscription types
                const priorDuration = durationMap[this.item.next_renewal_subscription_type] || 0;
                const currentDuration = durationMap[this.frequency] || 0;

                // Determine if the action is an upgrade or downgrade
                return currentDuration > priorDuration ? 'upgrade' : 'downgrade';
            },
            newValue() {
                return this.isQuantityChanged ? this.selectedBillingType : '';
            },
            priorValue() {
                return this.displayBillingType();
            },
            renewalQuantity(){
                return this.hasLicenseRemovalRequest ? this.item.next_renewal_license : this.item.licences;
            },
            renewalDuration() {
                return this.selectedBillingType;
            }
        },
        methods: {
            getLabelMessage(subscriptionType, subscriptionName) {
                let message = !this.getAvailableFrequencies.includes(subscriptionType)
                ? `${subscriptionName} duration is not available for this title.`
                : subscriptionName;

                if (this.item.subscription_type === subscriptionType) {
                    message = `${message} (current)`;
                }
                if (this.isAutoRenewSubscriptionType(subscriptionType)) {
                    message = `${message} (on auto-renew)`;
                }

                return message;
            },
            isAutoRenewSubscriptionType(subscriptionType) {
                return this.item.next_renewal_subscription_type === subscriptionType && this.hasPendingChangeRequest
            },
            updateFrequency(value) {
                this.setFrequency(value);
            },
            reviewChanges(){
                this.step = 2;
            },
            submitAndPay() {
                this.loadingSubmit = true;
                const request = {
                    subscription_id: this.item.subscription_id,
                    purchaseId:  this.item.parent_increment_id,
                    updated_sku: this.item.sku + this.frequency,
                    current_sku: this.item.sku + this.item.subscription_type,
                    action: this.actionType,
                    userId: this.userId
                }
                BillingApi.updateBillingFrequency(request)
                    .then((response) => {
                        if (response.data.success) {
                            const message = `Subscription to ${this.displayDocumentTitle} updated successfully.`;

                            this.logMessage = message;
                            this.logStatus = 'success';
                            this.logSubscriptionChange();

                            EventBus.fire('sync-billing-page', false);
                            this.dialog = false;
                            this.setBillingUpdateMessage(message);
                        } else {
                            this.logMessage = response.data?.error_message;
                            this.logStatus = 'failed';
                            this.logSubscriptionChange();

                            const notifyObj = {
                                'success': false,
                                'message': response.data?.error_message
                            };
                            this.notifyUser(notifyObj);
                        }
                    })
                    .catch((error) =>{
                        this.logMessage = error.data?.error_message;
                        this.logStatus = 'error';
                        this.logSubscriptionChange();

                        const notifyObj = {
                            'success': false,
                            'message':error.data?.error_message
                        };
                        this.notifyUser(notifyObj);
                    })
                    .finally(() => this.loadingSubmit = false)
            },
        },
        created(){
            EventBus.listen('billing-frequency-update-dialog', (data) => {
                this.subscriptionChangeType = 'Billing Frequency';
                this.resetState();
                this.item = data;
                this.emptyCart();
                this.setFrequency(data.next_renewal_subscription_type);
                //if license remove request is pending then calculate price based on that
                const defaultQuantity = this.hasLicenseRemovalRequest ? data.next_renewal_license : data.licences;
                this.setQuantity(defaultQuantity);
                this.getDocInfoWithPrice(data.sku);
            });
        }
    }
</script>
