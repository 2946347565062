<template>
    <div>
        <new-question-form v-if="askQuestionFormVisible"></new-question-form>
        <question-view v-else-if="showQuestion" :id="showQuestion"></question-view>
        <template v-else>
            <your-questions-intro-block class="mb-5"></your-questions-intro-block>
            <your-questions-list></your-questions-list>
        </template>
    </div>
</template>

<script>
import YourQuestionsIntroBlock from "./questions/YourQuestionsIntroBlock.vue";
import YourQuestionsList from "./questions/YourQuestionsList.vue";
import NewQuestionForm from "./questions/NewQuestionForm.vue";
import QuestionView from "./questions/QuestionView.vue";

export default {
    components: {QuestionView, NewQuestionForm, YourQuestionsList, YourQuestionsIntroBlock},
    data() {
        return {
            askQuestionFormVisible: false,
            showQuestion: null,
        }
    },
    created() {
        const path = window.location.pathname;
        this.askQuestionFormVisible = path.includes('/ask-question');

        const match = (path.match(/\/quick-consult\/(\d+)/));
        if (match !== null) {
            this.showQuestion = match[1];
        }
    }
}
</script>

<style lang="scss" scoped>

</style>