import 'babel-polyfill';
import {BrowserAgent} from "@newrelic/browser-agent";
import Vue from 'vue';
import { mapActions } from 'vuex';
import VueResource from 'vue-resource';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import store from './store/store';
import VueAnalytics from 'vue-analytics';
import VueYoutube from 'vue-youtube';
import ReadMore from 'vue-read-more';
import VueTruncate from 'vue-truncate-filter';
import VueCookies from 'vue-cookies';
import VueScrollTo from 'vue-scrollto';
import moment from 'vue-moment';
import JsonCSV from 'vue-json-csv';
import VueClipboard from 'vue-clipboard2';
import '@mdi/font/css/materialdesignicons.css'
import IdleVue from "idle-vue";
import '@stimulus/polyfills';
import ConstantsIndex from './constants/index';
import VSwitch from 'v-switch-case';
import VueObserveVisibility from 'vue-observe-visibility';
import VueFriendlyIframe from 'vue-friendly-iframe';
import './scss/default.scss';
import './scss/icons.scss';
import './scss/vuetify_override.scss';

if (NEWRELIC_ENABLED) {
    // This uses the Webpack DefinePlugin to inject the values into the code. Those are not vars or constants, webpack does literal search and replace! Beware of quotes!
    // @see: https://webpack.js.org/plugins/define-plugin/
    // @see  webpack.config.js
    new BrowserAgent({
        init: {distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}}, // NREUM.init
        info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:NEWRELIC_LICENSE_KEY,applicationID:NEWRELIC_APPLICATION_ID,sa:1}, // NREUM.info
        loader_config: { accountID:NEWRELIC_ACCOUNT_ID,trustKey:NEWRELIC_ACCOUNT_ID,agentID:NEWRELIC_APPLICATION_ID,licenseKey:NEWRELIC_LICENSE_KEY,applicationID:NEWRELIC_APPLICATION_ID } // NREUM.loader_config
    })
}

const eventsHub = new Vue();

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 1200000, // 20 minutes (for concurrent timeout)
    startAtIdle: false
});

Vue.filter('formatPrice', function (value) {
    if (!value) return '';
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
});

Vue.component('downloadCsv', JsonCSV);

import VueSession from 'vue-session';
Vue.use(VueSession, {persist: true});

import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css');

import Routing from '../../public/bundles/fosjsrouting/js/router.min.js';
const routes = require('../js/fos_js_routes.json');
Routing.setRoutingData(routes);
window.Routing = Routing;

const _ = require('lodash');
require('deepdash')(_);

const Mousetrap = require('mousetrap');

Vue.use(VueTour);
Vue.use(moment);
Vue.use(VueScrollTo);
Vue.use(VueCookies);
Vue.use(VueTruncate);
Vue.use(ReadMore);
Vue.use(VueResource);
Vue.use(VueYoutube);
Vue.use(VueClipboard);
Vue.use(Vuetify);
Vue.use(ConstantsIndex);
Vue.use(VSwitch);
Vue.use(VueObserveVisibility);
Vue.use(VueFriendlyIframe);

Vue.http.interceptors.push(function(request) {
    request.headers.set('X-CSRF-TOKEN', document.getElementById('app').attributes['data-csrf-token'].value);
    return function(response) {
        if (response.headers.map['x-csrf-token'] !== undefined) {
            document.getElementById('app').attributes['data-csrf-token'].value = response.headers.map['x-csrf-token'];
        }
    };
});

import EventBus from './components/inc/events';
import NavigationApp from './components/partials/navigation/Navigation';
import Index from './components/content/index';
import PremiumFeatures from './views/Marketing/PremiumFeatures.vue';
import PricingPage from './views/Pricing/PricingPage.vue';
import Codes from './components/codes/Index';
import TabbedSearch from './views/Search/TabbedSearch.vue';
import Login from './components/login/login.vue';
import LoginModal from './components/modals/login';
import Registration from './components/registration/Registration.vue';
import EnterpriseRegistration from './components/registration/EnterpriseRegistration.vue';
import SignupTrial from "./components/registration/SignupTrial";
import Trial from './components/trial/TrialIndex.vue';
import EnvironmentBanner from './components/banners/environment_banner.vue';
import InfoBanner from './components/banners/info_banner.vue';
import Dashboard from './views/Dashboard/Dashboard.vue';
import DashboardLicense from './components/dashboard/licenses/index.vue';
import DashboardTags from './components/dashboard/tags/index.vue';
import DashboardSharing from './components/dashboard/sharing/index.vue';
import FavoritesSort from './components/dashboard/Favorites.vue';
import PremiumSidebarMobile from './components/sidebar/index_mobile.vue';
import Titles from './components/titles/index.vue';
import Associations from "./components/modals/book_associations.vue";
import BackToTop from './components/partials/buttons/back_to_top.vue';
import Favorite from './components/content/favorite.vue';
import Featured from './components/titles/featured.vue';
import FeedbackChat from './components/content/feedback_chat.vue';
import MobileActions from './components/partials/navigation/MobileActions.vue';
import LandingMap from './components/content/map.vue';
import ClickOutside from './directives/click-outside';
import ClickEvent from './directives/click-event';
import DashboardNotes from './components/dashboard/notes/index';
import CartPage from './components/cart/index.vue';
import ModalConcurrentAccess from './components/modals/ConcurrentAccess';
import PremiumSubscribeDialog from './components/modals/AddToCart.vue';
import PremiumTagDocument from './components/tags/premium_tag_document';
import FooterBlock from './components/partials/footer.vue';
import MigrateNotes from './components/dashboard/notes/migrate.vue';
import MigrateCompareNotes from './components/dashboard/notes/migrateCompare';
import Concurrent from './views/Marketing/PremiumForTeams.vue';
import HotJar from './components/content/partials/hotjar';
import RequestPasswordReset from './components/security/request_password_reset.vue';
import PasswordReset from './components/security/password_reset.vue';
import SubscriptionRequired from './components/modals/SubscriptionRequired.vue';
import PrintingRestrictedPopup from './components/modals/PrintingRestricted.vue';
import ReadonlyContentModal from './components/modals/ContentReadonly.vue';
import AccessLevelPermissions from './components/modals/AccessLevelPermissions.vue';
import NoCategoriesModal from './components/modals/no_categories';
import UpdatesModal from './components/updates/partials/modal';
import SignIn from './components/security/sign_in';
import EnterpriseOverview from './components/enterprise/overview/index.vue';
import EnterpriseActivity from './components/enterprise/activity/index.vue';
import EnterpriseUsers from './components/enterprise/users/index.vue';
import DigitalReference from './components/digital-reference/Index';
import Snippet from './components/snippet/index';
import TitlesLanding from './components/titles/landing/index';
import DigitalReferenceHeader from "./components/digital-reference/DigitalReferenceHeader";
import RevitAddin from './components/content/RevitAddin';
import SwitchUserBanner from "./components/banners/SwitchUserBanner";
import AppSystemBar from "./components/AppSystemBar";
import FullPageLoader from "./components/loaders/FullPage";
import Testimonials from './components/content/Testimonials';
import QuickAccess from "./components/dashboard/QuickAccess";
import ErrorsModal from "./components/errors/ErrorsModal";
import InvitationModal from "./components/enterprise/users/partials/InvitationModal.vue";
import PremiumAnswers from "./views/PremiumAnswers/PremiumAnswers.vue";
import PremiumAnswersView from "./views/PremiumAnswers/PremiumAnswersView.vue";
import AiNavigatorMarketing from "./components/navigator/Marketing.vue";
import ComplianceCalculators from "./views/Marketing/ComplianceCalculators.vue";
import BillingPage from "./components/billing-page/BillingPage.vue";
import DashboardAccount from "./views/Dashboard/Account.vue";

import Helper from './components/inc/mixins/helper';
import CDN from './components/inc/mixins/cdn';
import Premium from './components/inc/mixins/premium';
import Search from './components/inc/mixins/search';
import Favorites from './components/inc/mixins/favorites';
import Updates from './components/inc/mixins/updates';
import Session from './components/inc/mixins/session';
import TitlesMixin from './components/inc/mixins/titles';
import {User} from "./classes/User";
import {ExpirableStorage} from "./classes/ExpirableStorage";
import axios from "axios";
import GlobalError from "./components/banners/GlobalError";
import {TitleSearch} from "./api/TitleSearch";
import {EasyStorage} from "./classes/EasyStorage";
import {ErrorHandler} from "./classes/ErrorHandler";
import {DomUtils} from "./util/DomUtils";
import {AppParameters} from "./AppParameters";
import {bootSession, onUserIdle} from "./bootSession";
import ProfilesMixin from './components/inc/mixins/profiles.mixin'
import {SessionStore} from "./classes/SessionStore";
import AiNavigator from "./components/navigator/AiNavigator.vue";
import ConcurrentTimeoutWarningDialog from "./components/concurrent/ConcurrentTimeoutWarningDialog.vue";
import ConcurrentTimeoutDialog from "./components/concurrent/ConcurrentTimeoutDialog.vue";
import {DateUtil} from "./util/DateUtil";
import {isLocalEnvironment} from "./global";
import FeedbackForm from './components/modals/feedbackForm';

Vue.config.errorHandler = ErrorHandler.vueErrorHandler;

// global objects
window.expirableStorage = ExpirableStorage;
window.errorHandler = ErrorHandler;

Vue.mixin(Helper);
Vue.mixin(CDN);
Vue.mixin(Premium);
Vue.mixin(Search);
Vue.mixin(Favorites);
Vue.mixin(Updates);
Vue.mixin(Session);
Vue.mixin(TitlesMixin);
Vue.mixin(ProfilesMixin);
window.EventBus = EventBus; // EventBus is now globally available

/*
 Vue directive will be available globally
 Simple use 'v-click-outside="method"'
 */
Vue.directive('click-outside-directive', ClickOutside);
Vue.directive('second-event', ClickEvent);

Vue.directive('visible', function (el, binding) {
    el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

// Ability to enable Vue devtools explicitly on any environment when needed
if (sessionStorage.getItem('vue-devtools') !== null) {
    Vue.config.devtools = true;
}

var gaCode = document.getElementById('app').attributes['data-ga_code'].value;
var gaEnabled = document.getElementById('app').attributes['data-ga_enabled'].value === '1';
var gtmEnabled = document.getElementById('app').attributes['data-gtm_enabled'].value === '1';

Vue.use(VueAnalytics, {
    id: gaCode,
    checkDuplicatedScript: true,
    autoTracking: {
        pageviewOnLoad: false
    },
    debug: {
        sendHitTask: gaEnabled
    }
});

const opts = {
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true
        },
        dark: false,
        themes: {
            light: {
                primary: '#0B5940',
                primaryMedium: '#6A998B',
                primaryLight: '#E9EEEC',
                seaFoamGreen: '#A0DEBB',
                secondary: '#f4e9cb',
                accent: '#333333',
                accent2: '#FFA201',
                accent2Light: '#FFE2B9',
                lightgreen: '#cfdad5',
                monotonesLightGreen: '#D0DFDA',
                lightgray: '#e5ebe9',
                primaryShop: '#b30838',
                cdpBlue: '#0a8ef3',
                cdpBlueLight: '#E5EFF7',
                darkBlue: '#252D42',
                black: '#000000',
                teal: '#29A39B',
                grayLight: '#F5F5F5'
            },
            dark: {

            }
        }
    }
}

window.dataLayer = window.dataLayer || [];
window.vm = new Vue({
    el: '#app',
    store,
    vuetify: new Vuetify(opts),
    data: function () {
        return {
            /** @type {User} */
            user: null,
            locationPathname: window.location.pathname.toString(),
            intro_expanded: false,
            search_text: '',
            search_filter_menu: false,
            cdn: '',
            player_vars: {'rel': 0, 'showinfo': 0}
        };
    },
    components: {
        RequestPasswordReset,
        PasswordReset,
        Login,
        LoginModal,
        Registration,
        SignupTrial,
        Trial,
        NavigationApp,
        Index,
        PremiumFeatures,
        PricingPage,
        EnvironmentBanner,
        InfoBanner,
        Dashboard,
        DashboardLicense,
        DashboardTags,
        DashboardSharing,
        Codes,
        TabbedSearch,
        PremiumSidebarMobile,
        Associations,
        Titles,
        BackToTop,
        Favorite,
        Featured,
        FeedbackChat,
        MobileActions,
        LandingMap,
        FavoritesSort,
        DashboardNotes,
        CartPage,
        ModalConcurrentAccess,
        PremiumSubscribeDialog,
        PremiumTagDocument,
        FooterBlock,
        MigrateNotes,
        MigrateCompareNotes,
        Concurrent,
        HotJar,
        SubscriptionRequired,
        PrintingRestrictedPopup,
        ReadonlyContentModal,
        AccessLevelPermissions,
        NoCategoriesModal,
        UpdatesModal,
        SignIn,
        EnterpriseOverview,
        EnterpriseActivity,
        EnterpriseUsers,
        DigitalReference,
        GlobalError,
        Snippet,
        TitlesLanding,
        DigitalReferenceHeader,
        RevitAddin,
        AppSystemBar,
        SwitchUserBanner,
        FullPageLoader,
        Testimonials,
        QuickAccess,
        ErrorsModal,
        InvitationModal,
        PremiumAnswers,
        PremiumAnswersView,
        AiNavigator,
        AiNavigatorMarketing,
        ComplianceCalculators,
        ConcurrentTimeoutWarningDialog,
        ConcurrentTimeoutDialog,
        BillingPage,
        EnterpriseRegistration,
        DashboardAccount,
        FeedbackForm
    },
    methods: {
        ...mapActions('PricingModule', [
            'setMember',
            'setActiveTrial',
            'setTrialDiscountEnabled',
            'setTrialDiscount'
        ]),
        sendPageView(sendPath = null) {
            let xml = '';
            let label = '';
            let path = sendPath ? sendPath : window.location.pathname;
            let loggedIn = this.$store.getters.getLoggedIn;
            let payLoad = {
                page: path,
                title: document.title,
                location: window.location.href,
                dimension1: loggedIn
            };

            // Check to see if URL is /content and add in our custom content dimensions
            let urlParts = _.split(path, '/')
            if (urlParts[1] === 'content') {
                if (this.activeDocumentInfo) {
                    if (this.isPremiumSubscribed(this.activeDocumentInfo.sku) && '1' === this.activeContentPremium) {
                        if (this.activeContentProtection) {
                            label = this.$getConst('accessLevels').premiumLite;
                        } else {
                            label = this.$getConst('accessLevels').premium;
                        }
                    } else {
                        if ('1' == this.activeContentBasic && '1' == this.activeContentProtection) {
                            label = this.$getConst('accessLevels').basic;
                        } else {
                            label = this.$getConst('accessLevels').basicPlus;
                        }
                    }
                    xml = this.isXML ? 'xml' : 'pdf';
                }

                let dataObj = {
                    dimension10: xml,
                    dimension11: _.upperFirst(_.toLower(label)),
                    dimension12: this.activeDocumentInfo.document_id
                }
                if (this.$session.has('active-concurrent')) {
                    _.merge(dataObj, {dimension13: 'concurrent'});
                }
                payLoad = _.merge(payLoad, dataObj);
            }
            if (gaEnabled && !gtmEnabled) {
                this.$ga.page(payLoad);
            }
            if (gtmEnabled) {
                window.dataLayer.push({
                    'event':'dataLayerLoaded',
                    'user_id': loggedIn ? this.$store.getters.getUserId : '0',
                    'logged_in': loggedIn ? 'true' : 'false',
                    'access': xml,
                    'access_type': label,
                    'has_trial': this.trialActive ? 'true' : 'false',
                    'has_premium': this.hasPremiumSubscription,
                    'trial_status': this.trialStatus
                });
            }

            // Custom body class
            if (this.isNonPremium()) {
                document.body.classList.add('iccnonpremium');
                this.loadPathmonkScript();
            }
        },
        isNonPremium() {
            if (
                this.hasPremiumSubscription ||
                this.enterpriseId ||
                this.activeConcurrentCode ||
                this.hasActiveTrial
            ) {
                return false
            }
            return true
        },
        loadPathmonkScript() {
            const pconp = document.getElementsByClassName("iccnonpremium");
            if (pconp.length > 0) {
                const si = "pathmonk-js-sdk";
                const vi = "pathmonk-root";

                if (!document.getElementById(si) && !document.getElementById(vi)) {
                    const dv = document.createElement("div");
                    dv.id = vi;
                    dv.style.display = 'none';
                    dv.setAttribute("setup", "page_plugin");
                    dv.setAttribute("client_id", "e2RFiCOnSFhcMMeobzPOAUA06");
                    document.body.appendChild(dv);

                    const js = document.createElement("script");
                    js.id = si;
                    js.src = "https://pathmonk-lib.pathmonk.com/plugin/plugin.min.js";
                    document.body.appendChild(js);
                }
            }
        },
        ready() {
            this.$ga.event('Videos', 'Loaded', window.location.pathname);
        },
        playing() {
            this.$ga.event('Videos', 'Play', window.location.pathname);
        },
        paused() {
            this.$ga.event('Videos', 'Pause', window.location.pathname);
        },
        ended() {
            this.$ga.event('Videos', 'Finish', window.location.pathname);
        },
        getSubscriptionInfo() {

            // Check to see if subscriptions needs to be updated.
            const update = !this.$session.has('lastSyncDate') ||
            (this.$session.has('lastSyncDate') &&
            this.$session.get('lastSyncDate') < this.user.lastSyncDate);

            if (!this.premiumSubscriptionSet || update) {
                if (update) {
                    this.clearProfiles()
                }
                this.setSubscriptions();
            } else {
                this.$store.commit('setSubscriptionLoaded', true);
                this.sendPageView();
            }

            if (!this.premiumBundleSet || update) {
                this.setBundledTitles();
            }
        },
        loadUser() {

            // TODO: just load this from backend eventually...
            this.$http.get(Routing.generate('get_current_user')).then((response) => {

                if ('user' in response.data) {
                    const user = new User(response.data.user);
                    if (user.is_member) {
                        this.setMember(true)
                    }
                    if (user.trial.isTrialActive) {
                        this.setActiveTrial(true)
                    }

                    this.user = user;
                    this.$store.commit('setGlobalBannerHidden', user.show_global_banner);
                    this.hideBanner = user.show_global_banner;

                    if (user.enterprise_pending_invitations &&
                        user.enterprise_pending_invitations.length &&
                        localStorage.getItem('hide-invitation') === null) {
                        EventBus.fire('show-invitation-modal')
                    }

                    // sync miscellaneous "preferences" from database...
                    if (user.getArrayPreferences('ignore_version_check_for_books')) {
                        this.$session.set('hideVersionNotificationForDocuments',
                            user.getArrayPreferences('ignore_version_check_for_books'));
                    }
                    if (user.getArrayPreferences('book_notes_migrate_ignore_list')) {
                        this.$session.set('hideMigrateNotificationForDocuments',
                        user.getArrayPreferences('book_notes_migrate_ignore_list'));
                    }

                    // TODO: refactor this to make it no longer necessary
                    this.$session.set('showAstmModal', user.show_astm_modal);

                    const trial = response.data.user.trial;
                    this.trialActive = trial ? trial.isTrialActive : false;

                    if (trial && trial.userOptedForTrial) {
                        this.trialStarted = true;
                    }

                    this.$store.commit('setUser', user);

                    if (user.enterprise) {
                        this.isEnterpriseAdmin = user.enterprise.isAdmin;
                        this.enterpriseId = user.enterprise.id;
                    }
                    // sendPageView() will be sent here
                    this.getSubscriptionInfo();

                    if (!user.isCognitoTokenValid) {
                        this.goLogout();
                    } else {
                        this.refreshLastTokenSynchedAt();
                    }

                } else {
                    this.sendPageView();
                }

            }, (error) => {

                // not logged in probably... record visit
                this.sendPageView();
            });
        },
        watchForUrlChanges() {
            let windowLocation = null;

            setInterval(() => {
                const newWindowLocation = window.location.toString();

                if (newWindowLocation !== windowLocation) {

                    EventBus.fire('location:changed', {
                        location: window.location
                    });

                    windowLocation = newWindowLocation;
                }

            }, 100);
        },
        handleSessionFlash() {
            const sessionFlashData = DomUtils.getMetaContents("session_flash");
            if (sessionFlashData) {

                if ('switch_user' in sessionFlashData) {
                    this.$store.commit('setSwitchUser', sessionFlashData.switch_user);
                }

                if ('switch_user_return_url' in sessionFlashData) {
                    this.$store.commit('setSwitchUserReturnUrl', sessionFlashData.switch_user_return_url);
                }
            }
        }
    },
    computed: {
        isIdle() {
            return this.$store.state.idleVue.isIdle;
        }
    },
    created() {
        SessionStore.start();
    },
    beforeMount() {
        this.$store.commit('setGaEnabled', gaEnabled);
        this.$store.commit('setGtmEnabled', gtmEnabled);
        this.$store.commit('setCDNUrl', this.$el.attributes['data-cdn'].value);
        this.$store.commit('setLoggedIn', this.$el.attributes['data-loggedin'].value);
        this.$store.commit('setIsAdmin', this.$el.attributes['data-admin'].value);
        this.activeConcurrentCode = this.$el.attributes['data-concurrent-code'].value;
        // Clear localstorage forcefully if not cleared even after the user logout
        if ('' == this.$store.getters.getLoggedIn) {
            if (_.size(this.$session.getAll()) > 0) {
                this.clearUserSession();
            }
        }
        if (!_.isUndefined(this.$el.attributes['data-user_id'])) {
            this.$store.commit('setUserId', this.$el.attributes['data-user_id'].value);
        }

        if (typeof this.$el.attributes['data-exam-session'] !== 'undefined') {
            // double conversion for not using a script block to pass data
            const examSession = Boolean(parseInt(this.$el.attributes['data-exam-session'].value, 10));

            this.$store.commit('setExamSession', examSession);
        }

        // before all child components are mounted
        TitleSearch.enableFastSearch(EasyStorage.has('TitleSearch:enabled'));

        this.$store.commit('setSearchNavigationCategories', DomUtils.getMetaContents('categories'));
        this.$store.commit('setUpdateCount', DomUtils.getMetaContents('updateCount'));
        this.$store.commit('setTags', DomUtils.getMetaContents('userTags'));
        this.$store.commit('setUserTitles', DomUtils.getMetaContents('userTitles'));

        if (EasyStorage.has('debug:show_scores')) {
            this.$store.commit('setDebugShowResultScores', true);
        }

        Object.assign(AppParameters, DomUtils.getMetaContents('preferences'));
        // Set our pricing module AppParameters
        if (AppParameters.premium_convert_discount_enabled) {
            this.setTrialDiscountEnabled(true)
            this.setTrialDiscount(AppParameters.discount_value_in_percent)
        }
        window['app_parameters'] = AppParameters;
    },
    mounted() {
        this.watchForUrlChanges();

        if (this.$store.getters.getLoggedIn !== '') {
            this.loadUser();
            if (this.$store.getters.getLoginFailedCount >= 3) {
                this.$store.commit('setLoginFailedCount', 0);
            }
        } else {
            this.sendPageView();
        }

        this.handleSessionFlash();

        if (this.activeConcurrentCode) {
            bootSession();

            const idleTimeout = (DateUtil.MILLISECONDS_IN_MINUTE * AppParameters.concurrent_idle_timeout_minutes);

            // when no activity detected for 10 minutes - trigger this
            onUserIdle(() => {

                // if concurrent dialog NOT already showing = show it!
                if (!this.$store.getters.isConcurrentTimeoutWarningDialogVisible) {
                    this.$store.commit('setConcurrentTimeoutWarningDialogVisible', true);
                }

            }, idleTimeout);
        }

        Mousetrap.bind('Q Q Q', () => {

            if (confirm('Clear frontend cache?')) {
                this.clearAllCache();
                window.location.reload();
            }

            return false;
        });

        Mousetrap.bind('left+left+left+x', () => {

            if (window['__xhr_fail_mode']) {
                delete window['__xhr_fail_mode'];
                axios.defaults.baseURL = '';

                alert('XHR Fail Mode: OFF');
            } else {
                window['__xhr_fail_mode'] = true;
                axios.defaults.baseURL = 'https://going.nowhere/';

                alert('ALL XHR requests will fail from now on...');
            }
        });

        Mousetrap.bind('left left left f', () => {

            let newValue = EasyStorage.toggle('TitleSearch:enabled');

            if (newValue) {
                TitleSearch.loadAll(true);
            }

            alert(`Instant Title Search status: ${newValue}. OK to reload`);
            window.location.reload();

        });

        Mousetrap.bind('up up up  s', () => {
            this.$store.commit('setDebugShowResultScores', EasyStorage.toggle('debug:show_scores'));
            EventBus.fire('update-results');
        });

        Mousetrap.bind('up up up h', () => {
            EventBus.fire('blocks:reload');
        });

        Mousetrap.bind('?', () => {
            alert("up + up + up + h => Reload all dynamic content blocks");
        });

        Mousetrap.bind('up up up d', () => {
            alert('Vue Devtools enabled! Reloading...');
            sessionStorage.setItem('vue-devtools', 'enabled');
            window.location.reload();
        });

        EventBus.listen('send-page-view', (data) => this.sendPageView(data));
    }
});
