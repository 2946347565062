// TODO: merge this with ExpirableStorage
export class EasyStorage {

    /**
     * The purpose is to always return a BOOLEAN type
     * @param key
     * @return {boolean}
     */
    static has(key) {
        const value = localStorage.getItem(key);
        return value !== null;
    }

    /**
     * Get item value and then remove
     * @param {string} key
     * @return {string|null}
     */
    static getAndRemove(key) {
        const value = localStorage.getItem(key);
        localStorage.removeItem(key);
        return value;
    }

    /**
     * Remove item from localStorage, but unlike with default removeItem, return TRUE if anything was deleted
     * @param key
     * @return {boolean}
     */
    static removeItemWithStatus(key) {
        return this.getAndRemove(key) !== null;
    }

    /**
     * Returns LATEST toggle status
     * @param key
     * @return {boolean}
     */
    static toggle(key) {

        if (this.has(key)) {
            localStorage.removeItem(key);
            return false;
        } else {
            localStorage.setItem(key, "on");
            return true;
        }
    }

    static watch(key, callback, excludeCurrentDocument = true) {

        addEventListener('storage', (/** @type {StorageEvent} */ event) => {

            if (event.key === key && typeof callback === 'function') {
                callback(event.newValue);
            }

        });
    }

    // get values from some key as Set()
    static setAll(key) {
        const val = localStorage.getItem(key);

        if (typeof val !== 'undefined') {

            try {

                const json = JSON.parse(val);

                if (Array.isArray(json)) {
                    return new Set(json);
                }

            } catch (e) {
                // do nothing
            }
        }

        return new Set();
    }

    static setAdd(key, value) {

        const all = this.setAll(key);
        all.add(value);

        const arr = Array.from(all);
        localStorage.setItem(key, JSON.stringify(arr));
    }

    static setHas(key, value) {
        const all = this.setAll(key);
        return all.has(value);
    }

    static setDelete(key, value) {
        const all = this.setAll(key);
        all.delete(value);

        const arr = Array.from(all);
        localStorage.setItem(key, JSON.stringify(arr));
    }
}
