<template>
    <v-row dense v-if="displayTrialBlock">
        <v-col class="pt-0">
            <v-alert
                class="trial-block-alert-box"
                type="success"
                colored-border
                elevation="0"
                icon="mdi-creation"
                border="left"
                color="accent2"
                :style="`background-color: ${this.$vuetify.theme.themes.light.accent2Light};`">
                <template v-slot:close v-if="!trialActive">
                    <v-btn
                        class="top-right-dismiss"
                        icon
                        color="black"
                        role="button"
                        aria-label="Close icon button will close the subscription trial box"
                        @click="closeTrialBlock">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <div v-if="trialActive">
                    <h4 class="mb-2">Premium Trial - {{ remainingDays }} remaining</h4>
                    <p class="text-body-1" v-if="isPremiumConvertDiscountActive">
                        Subscribe to Premium Complete before your trial ends for {{ activeDiscountVal }}% off!
                    </p>
                </div>
                <div v-else>
                    <h4 class="mb-2">Start your free 14 day Premium Complete trial</h4>
                    <p class="text-body-1">Get access to bookmarks, content search, AI Navigator and more with Premium.</p>
                </div>
                <StartFreeTrial
                    class="mb-2"
                    directStartTrial
                    color="accent2"
                    :maxHeight="35"/>
                <v-btn
                    v-if="trialActive"
                    @click="premiumSubscribe"
                    :loading="isLoading"
                    depressed
                    class="font-weight-bold mb-2"
                    color="accent2">
                    Subscribe to Premium Complete
                </v-btn>
                <v-btn
                    class="font-weight-bold mb-2"
                    outlined
                    depressed
                    target="_blank"
                    :href="getRoute('premium_features')">
                    Learn About Premium
                </v-btn>
                <a
                    v-if="trialActive"
                    class="black--text fs-14 font-weight-bold pa-2"
                    @click="cancelTrial">Cancel Trial
                </a>
                <confirm-dialog :is-open="showCancelConfirmDialog"
                    title="Confirm Cancellation"
                    @ok="confirmCancelTrial"
                    @closed="showCancelConfirmDialog = false">
                        Are you sure you want to cancel your FREE trial? The trial will automatically cancel at the end of the 14
                        day period with no automatic charges being incurred.
                </confirm-dialog>
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
    import StartFreeTrial from '../../components/buttons/StartFreeTrial.vue';
    import ConfirmDialog from '../../components/ConfirmDialog.vue';
    import { ApiService } from '../../api/ApiService';
    import { User } from '../../classes/User';
    import { AppParameters } from '../../AppParameters';
    import mobile from '../../components/inc/mixins/mobile';

    export default {
        data() {
            return {
                isTrialBlockClosed: false,
                isPremiumConvertDiscountActive: AppParameters.premium_convert_discount_enabled,
                activeDiscountVal: AppParameters.discount_value_in_percent,
                isLoading: false,
                showCancelConfirmDialog: false,
            }
        },
        components: {StartFreeTrial, ConfirmDialog},
        mixins: [mobile],
        computed: {
            remainingDays() {
                const user = this.$store.getters.getUser;
                const days = user ? user.trial.remainingDays : 0;

                return `${days} day${days > 1 ? 's' : ''}`;
            },
            displayTrialBlock() {
                const logOutActionTriggered = this.$store.getters.isUserLoggingOut;
                const isSubscriptionLoaded = this.$store.getters.isSubscriptionLoaded;
                const user = this.$store.getters.getUser;
                if (user && !logOutActionTriggered && isSubscriptionLoaded) {
                    const savedPreference = user.getPreference([User.TRIAL_BLOCK_SECTION]);
                    return (typeof savedPreference === 'undefined' && this.canStartTrial && !this.isTrialBlockClosed)
                        || (this.hasActiveTrial && !this.isEnterpriseOrConcurrentActive)
                }

                return false;
            }
        },
        methods: {
            closeTrialBlock() {
                this.isTrialBlockClosed = true;
                ApiService.updatePreference({
                    [User.TRIAL_BLOCK_SECTION]: this.isTrialBlockClosed,
                });
            },
            premiumSubscribe() {
                this.isLoading = true;
                ApiService.logUserClick("subscribe_now");

                const sku = this.$getConst('premiumCompleteSku');

                // will contain document object on success
                ApiService.getDocumentBySku(sku).then((res) => {
                    EventBus.fire('show-premium-subscribe-dialog', res);
                }).catch(() => {
                    alert('Something went wrong. Try again later.');
                })
                .finally(()=> this.isLoading = false)
            },
            cancelTrial() {
                this.showCancelConfirmDialog = true;
            },
            confirmCancelTrial() {
                ApiService.cancelTrial().then(() => {
                    this.clearAllCache();

                    window.location = Routing.generate('trial_signup');
                });
            }
        },
    }
</script>
<style>
    .top-right-dismiss {
        top: 8px;
        right: 8px;
        position: absolute;
    }
    .trial-block-alert-box .v-alert__icon.v-icon{
        margin-right: 8px !important;
    }
</style>