<template>
    <v-card
            id="premiumAnswersFilter"
            outlined>
        <v-card-text>
            <h2 class="mb-5">Quick Consult - code questions answered by an expert</h2>
            <p>Ask code questions on any of the International Codes (I-Codes).
                Our ICC experts will provide a professional opinion of how the codes
                apply to your specific circumstances within 2 business days.
            </p>
            <p v-if="isAuthenticated"><b>{{ creditsAvailable }} credits - buy credits to ask a question.</b> Note that this is a paid service.
                You must buy credits to ask questions, regardless of your Premium subscription status.</p>
            <p v-else>
                <a
                    class="text-decoration-underline"
                    :href="this.getRoute('security_login')">Sign in
                </a> or
                <a
                    class="text-decoration-underline"
                    :href="this.getRoute('signup')">
                    create an account
                </a>
                <b>to buy credits to ask a question.</b> Note that this is a paid service.
                You must buy credits to ask questions, regardless of your Premium subscription status.
            </p>
            <div class="d-flex">
                <v-btn
                    :disabled="!canAskQuestions"
                    color="primary"
                    :href="this.getRoute('ask_question')">
                    Ask New Question
                </v-btn>
                <div class="mx-1"></div>
                <v-btn
                    :disabled="!canPurchaseCredits"
                    outlined
                    color="primary"
                    href="https://shop.iccsafe.org/"
                    target="_blank">
                    Purchase Credits
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import {RandomUtils} from "../../../util/RandomUtils";

export default {
    computed: {
        creditsAvailable() {
            return RandomUtils.randInt(0, 45);
        },
        canAskQuestions() {
            return this.creditsAvailable > 0;
        },
        canPurchaseCredits() {
            return this.isAuthenticated;
        }
    },
    created() {

    }
}
</script>

<style lang="scss" scoped>

</style>