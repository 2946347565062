<template>
    <div class="pa-2">

        <loading class="my-12" v-if="isLoading"></loading>

        <template v-else>
            <v-row dense>
                <v-col :cols="isMobilePort ? '12' : ''">
                    <h2>{{ numberOfQuestionsHeadingText }}</h2>
                </v-col>
                <v-col class="pb-4" :cols="isMobilePort ? '12' : ''">
                    <v-btn-toggle
                        @change="setUserFilter"
                        v-model="toggleFilter"
                        mandatory
                        :class="isMobilePort ? 'd-flex flex-row' : 'float-right mr-2'">
                        <v-btn
                            :dark="hasQuestions"
                            outlined
                            color="primary"
                            value="All"
                            height="40"
                            :class="isMobilePort && 'flex-grow-1'"
                            :disabled="!hasQuestions">
                            All
                        </v-btn>
                        <v-btn
                            outlined
                            color="primary"
                            value="Submitted"
                            height="40"
                            :class="isMobilePort && 'flex-grow-1'"
                            :disabled="!hasQuestions">
                            Submitted
                        </v-btn>
                        <v-btn
                            outlined
                            color="primary"
                            value="InProgress"
                            height="40"
                            :class="isMobilePort && 'flex-grow-1'"
                            :disabled="!hasQuestions">
                            In Progress
                        </v-btn>
                        <v-btn
                            outlined
                            color="primary"
                            value="Answered"
                            height="40"
                            :class="isMobilePort && 'flex-grow-1'"
                            :disabled="!hasQuestions">
                            Answered
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <template v-if="questions.length === 0">
                <p>You haven't asked any questions yet.</p>
            </template>

            <template v-else>
                <v-sheet v-for="question in getUserQuestions" class="border pa-4 mb-4" :key="question.id">
                    <h3 class="primary--text mb-1">
                        <a :href="getQuestionUrl(question)">{{ question.title }}</a>
                    </h3>
                    <p class="text-sm text-muted">
                        <time>{{ DateUtil.timeToMdy(question.createdAt) }}</time>
                    </p>
                    <p v-html="question.description"></p>
                    <v-chip small>{{ question.status.text }}</v-chip>
                </v-sheet>
            </template>

        </template>
    </div>
</template>

<script>
import {QuickConsultApi} from "../../../api/QuickConsultApi";
import Loading from "../../content/partials/loading.vue";
import {DateUtil} from "../../../util/DateUtil";
import Mobile from "../../inc/mixins/mobile";
import {User} from '../../../classes/User';
import {AsyncUtils} from '../../../util/AsyncUtils';
import Constants from '../../../constants';
import {ApiService} from '../../../api/ApiService';

export default {
    components: {Loading},
    data() {
        return {
            isLoading: true,
            questions: [],
            toggleFilter: Constants.defaultQuickQuestionFilter
        }
    },
    mixins:[Mobile],
    computed: {
        DateUtil() {
            return DateUtil;
        },
        numberOfQuestionsHeadingText() {
            return this.getUserQuestions.length === 1 ? '1 question' : `${this.getUserQuestions.length} questions`;
        },
        hasQuestions() {
            return Boolean(this.questions.length);
        },
        getUserQuestions() {
            if (this.hasQuestions) {
                if (this.toggleFilter === 'All') {
                    return this.questions;
                }

                return this.questions.filter(currentVal => currentVal.status.key === this.toggleFilter);
            }

            return this.questions;
        }
    },
    methods: {
        loadQuestions() {
            this.isLoading = true;

            QuickConsultApi.getUserQuickConsultQuestions().then((res) => {
                this.questions = res;
            }).finally(() => {
                this.isLoading = false;
            })
        },
        getQuestionUrl(question) {
            return Routing.generate('quick_consult_question', {
                questionId: question.id,
                slug: question.slug
            });
        },
        setUserFilter() {
            // Set the user preference
            ApiService.updatePreference({[User.QUICK_CONSULT_QUESTION_FILTER]: this.toggleFilter});
        }
    },
    created() {
        if('' !== this.$store.getters.getLoggedIn) {
            this.loadQuestions();

            AsyncUtils.awaitUntil(() => {
                return this.$store.getters.getUser !== null;
            }, 200).then(() => {
                const user = this.$store.getters.getUser;
                const quickQuestionStatus = user.getPreference(User.QUICK_CONSULT_QUESTION_FILTER);

                if(typeof quickQuestionStatus !== 'undefined') {
                    this.toggleFilter = quickQuestionStatus;
                }
            });
        } else {
            this.isLoading = false;
        }
    }
}
</script>
