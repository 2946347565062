<template>
    <div>
        <license-quantity-update
            :userId="userId"
            :shop-url="shopUrl">
        </license-quantity-update>
        <billing-frequency-update
            :userId="userId"
            :shop-url="shopUrl">
        </billing-frequency-update>
    </div>
</template>
<script>

import LicenseQuantityUpdate from './subscription-update/LicenseQuantityUpdate.vue';
import BillingFrequencyUpdate from './subscription-update/BillingFrequencyUpdate.vue';
export default {
    components: {LicenseQuantityUpdate, BillingFrequencyUpdate},
    props: {
        shopUrl: {
            required: true,
            type: String
        },
        userId: {
            type: String,
            required: false
        }
    },
}
</script>
