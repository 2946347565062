import Moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
import { ApiService } from '../../../api/ApiService';
import { BillingApi } from '../../../api/BillingApi';

export default {
    data() {
        return {
            subscriptionChangeType: null,
            dialog: false,
            loadingPrice: false,
            disabledReview: false,
            initialPrice: 0,
            proRatePrice: '',
            loadingProratePrice: false,
            loadingSubmit: false,
            step: 1,
            item: {},
            notifyType: null,
            message: '',
            displayDocumentTitle: '',
            documentId: null,
            loggedId: null,
            logStatus: '',
            logMessage: ''
        }
    },
    computed: {
        ...mapGetters('PricingModule', [
            'cartTotalPrice',
            'getCartItems',
            'getIsMember',
            'displayPrice',
            'billingPeriodShortText',
            'savingsText',
            'getFrequency',
            'getAvailableFrequencies'
        ]),
        ...mapGetters('BillingModule', [
            'getPaymentProfile',
        ]),
        ...mapState('PricingModule', ['quantity']),
        isLoggedIn() {
            return '' !== this.$store.getters.getLoggedIn;
        },
        visaCardNumber() {
            return `${this.getPaymentProfile?.card_type} ${this.getPaymentProfile?.cc_number}`;
        },
        getPrice(){
            return this.getIsMember ? this.cartTotalPrice.memberTotal : this.cartTotalPrice.nonMemberTotal;
        },
        getProRatePrice(){
            return this.proRatePrice * this.addedLicenseCount;
        },
        dialogTitle() {
            return this.step === 1 ?  `Change ${this.subscriptionChangeType}` : 'Review Subscription Change';
        },
        invalidVisaCart() {
            return _.isEmpty(this.getPaymentProfile);
        },
        hasLicenseRemovalRequest() {
            return this.item.licences > this.item.next_renewal_license;
        },
        renewalBillingType() {
            const subscriptionLabels = {
                'M': 'Monthly',
                'B': 'Annually'
            };

            return subscriptionLabels[this.item.next_renewal_subscription_type] || '3-Year';
        },
    },
    methods: {
        ...mapActions('PricingModule', [
            'addToCart',
            'setQuantity',
            'setFrequency',
            'emptyCart'
        ]),
        ...mapActions('BillingModule', [
            'setBillingUpdateMessage'
        ]),
        handleGoBack() {
            this.step = 1;
        },
        emitClosed(action) {
            this.logMessage = `Change ${action} action has been aborted.`;
            this.logStatus = 'aborted';
            this.logSubscriptionChange();
            this.dialog = false;
        },
        getDocInfoWithPrice(sku){
            this.loadingPrice = true;
            ApiService.getDocumentBySku(sku).then((res) => {
                this.displayDocumentTitle = `${res?.year?.year} ${res?.title}`;
                this.documentId = res.id;
                this.addItemToCart(res);
                this.logSubscriptionChange();
            }).catch((error) => {
                console.log('error', error);
                this.message = 'Could not fetch price info, please try after some time.';
                this.notifyType = 'error';
            }).finally(() => this.loadingPrice = false)
        },
        resetState(){
            this.step = 1;
            this.dialog = true;
            this.notifyType =  null;
            this.message =  '';
            this.logStatus = '';
            this.logMessage = '';
            this.loggedId = null;
            this.proRatePrice = '';
        },
        displayBillingType() {
            const subscriptionLabels = {
                'M': 'Monthly',
                'B': 'Annually'
            };

            return subscriptionLabels[this.item.subscription_type] || '3-Year';
        },
        notifyUser(data){
            this.notifyType = !data.success ? 'error' : 'success';
            this.message = data.message;
        },
        hasPrice(title) {
            return !isNaN(parseFloat(this.displayPrice(title))) && isFinite(parseFloat(this.displayPrice(title)))
        },
        dismissAlert(){
            this.message = '';
        },
        addItemToCart(titleObj) {
            const title = _.cloneDeep(titleObj);
            const itemInCart = this.getCartItems.find(item => item.id === title.id);
            if (!itemInCart && this.hasPrice(title)) {
                this.addToCart(title);
                this.initialPrice = this.getPrice;
            }
        },
        formatAutoRenewDate() {
            return `on ${Moment(this.item?.expiration).format('ll')}`;
        },
        getOrderDetailsUrl() {
            return this.item.subscription_detail_url;
        },
        getShopManagePaymentsPage() {
            return this.shopUrl + this.$getConst("links").shopManagePayments;
        },
        logSubscriptionChange() {
            if (this.invalidVisaCart) {
                this.logStatus = 'invalid_card';
                this.logMessage = 'Credit card information is not available or invalid card';
            }
            const request = {
                orderId: this.item.parent_increment_id,
                documentId: this.documentId,
                subscriptionId: this.item.subscription_id,
                actionTriggered: this.actionTriggered,
                priorValue: this.priorValue,
                newValue: this.newValue,
                actionChangeCost: this.getProRatePrice,
                renewalDate:  Moment(this.item?.expiration).format('ll'),
                renewalQuantity: this.renewalQuantity,
                renewalAmount: this.getPrice,
                renewalDuration: this.renewalDuration,
                loggedId: this.loggedId,
                status: this.logStatus,
                message: this.logMessage,
                userId: this.item?.userId
            }
            BillingApi.logSubscriptionChange(request)
                .then((response) => {
                    if (response.data.success) {
                        this.loggedId = response.data.loggedId;
                    }
                })
        }
    }
}
