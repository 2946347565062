<template>
    <v-sheet
        class="rounded py-0 px-2 py-1 my-1 mb-2"
        color="accent2Light">
        <v-row dense>
            <v-col cols="auto">
                <v-icon
                    color="accent2"
                    class="mt-n1">
                    mdi-sale-outline
                </v-icon>
            </v-col>
            <v-col>
                <h4 class="font-weight-bold">
                    Discounts Applied:
                </h4>
                <ul>
                    <li
                        v-for="(item, key) in savingsText"
                        :key="key">
                        {{ item.discount }}% {{ item.label }}
                    </li>
                </ul>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
export default {
    props: {
        savingsText: {
            required: true,
            type: Array
        }
    },
}
</script>
