<template>
    <div>
        <v-container class="testimonials-container">
            <v-row
                :class="{
                    'py-10': !isMobilePort,
                    'flexDirection': isMobilePort
                }"
                align="center"
                justify="center"
                :dense="isMobilePort">
                <v-col
                cols="auto"
                order="1">
                    <h1
                        class="font-weight-light mb-4 oxygen">
                        Customers and<br/>Testimonials
                    </h1>
                    <h2
                        class="font-weight-regular">
                        Inspiring stories, real Digital<br/>Codes customers
                    </h2>
                    <v-btn
                        class="mt-4"
                        dark
                        medium
                        color="primary"
                        @click="goToRoute('trial_signup')">
                        Start FREE Trial Today
                    </v-btn>
                </v-col>
                <v-col order="2">
                    <asset-image
                        width="100%"
                        :src="require('../../images/testimonials/testimonial.webp')"/>
                </v-col>
            </v-row>
            <v-row
                :dense="isMobilePort"
                align="center"
                justify="center">
                <v-col class="text-center py-0">
                    <h2
                        class="font-weight-regular">
                        Our Valued Customers
                    </h2>
                </v-col>
            </v-row>
            <v-row
                class="px-10 py-10"
                :dense="isMobilePort">
                <v-col>
                    <logo-carousel>
                    </logo-carousel>
                </v-col>
            </v-row>
            <v-row
                class="px-3 py-3 testimonialBackground"
                :dense="isMobilePort">
                <v-col class="py-0">
                    <h2
                        class="font-weight-regular text-center py-5 pb-5">
                        What our Valued Customers are Saying <br/>about Digital Codes Premium
                    </h2>
                    <v-carousel
                        v-model="slideNumber"
                        :show-arrows="!isMobilePort">
                            <v-carousel-item
                                v-for="(testimonial, index) in testimonials"
                                :key="index">
                                <div :class="{
                                    'interTestimonialDivWrapper': !isMobilePort || isMobilePort,
                                    'webWidth': !isMobilePort,
                                    'mobileWidth': isMobilePort}">
                                    <div class="interTestimonialDiv">
                                        <blockquote class="quotes">
                                            <p class="py-7 mb-0 black--text">{{ testimonial.content }}</p>
                                        </blockquote>
                                        <p class="font-weight-bold mb-0 mt-3 caption black--text">
                                            {{testimonial.name}}
                                        </p>
                                        <span class="mb-0 mt-3 caption black--text">
                                            {{testimonial.company}}
                                            <br/>
                                            {{testimonial.position}}
                                        </span>
                                    </div>
                                </div>
                            </v-carousel-item>
                    </v-carousel>
                </v-col>
            </v-row>
            <v-carousel
                        height="auto"
                        :show-arrows="!isMobilePort">
                            <v-carousel-item
                                v-for="(caseStudy, index) in caseStudys"
                                :key="index">
                                <a :href="caseStudy.link" target="_blank">
                                    <v-row
                                        class="caseStudy mt-10 px-3 py-3"
                                        :dense="isMobilePort">
                                        <v-col>
                                            <h2 class="primary--text caseStudyHeading pb-5">
                                                {{ caseStudy.heading }}
                                            </h2>
                                            <h3 class="black--text fontSize18">
                                                {{ caseStudy.subHeading }}
                                            </h3>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        class="caseStudyImageWrapper"
                                        :dense="isMobilePort">
                                        <v-col class="pt-0">
                                            <asset-image
                                                :class="{
                                                    'caseStudyImage': !isMobilePort || isMobilePort,
                                                    'imageRight': !isMobilePort,
                                                    'imageRightMobile': isMobilePort}"
                                                :src="require('../../images/testimonials/'+caseStudy.image)"/>
                                        </v-col>
                                    </v-row>
                                </a>
                            </v-carousel-item>
                    </v-carousel>
                    <v-row class="mt-12">
                        <v-col class="text-center">
                            <h2
                                class="font-weight-regular">
                                Enjoying ICC Digital Codes Premium?
                            </h2>
                            <p>
                                Share you experience by leaving a review on Capterra.
                            </p>
                            <a :href="iccCapterraReviewLink" target="_blank">

                                <asset-image
                                class="my-8"
                                :src="require('../../images/testimonials/capterra.jpg')"/>
                            </a>
                            </v-col>
                    </v-row>
        </v-container>
    </div>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';
    import { Carousel, Slide } from 'vue-carousel';
    import AssetImage from "../AssetImage";
    import LogoCarousel from "../banners/LogoCarousel.vue";

    export default {
        data () {
            return {
                caseStudyLink: '',
                iccCapterraReviewLink: 'https://reviews.capterra.com/new/265907',
                testimonials: [
                    {content: "Having all the codes in one place is huge. Setting up your own dash board of favorites is helpful to have quick access. Still working on taking advantage of all the tools available with concurrent access. I plan to be a long time user.", name: "Randal Westacott", company: "City of Goodyear, AZ", position: "Chief Building Official"},
                    {content: "Access to digital codes has enabled us to reference codes in the field. During COVID, when some of our staff is working from home, it's been like having all of our code books with us. I'll admit that I've always loved my paper code books, but the ease of use of our digital codes subscriptions has changed my mind. The biggest benefit to the City of Coppell team has been having access to frequently used codes anywhere.", name: "Suzanne Arnold", company: "City of Coppell", position: "Chief Building Official"},
                    {content: "The codes provided me with access to the codes in the field and send them via email to those that need the information.", name: "Chris Nowling", company: "Chris Nowling Inspection Services, LLC", position: "Owner/Inspector"},
                    {content: "The copy and paste allows me to provide code section in my plan review process. The opinion letters help me understand some of the thinking/reasoning behind code sections. The premium access makes my job much easy and makes my code interpretation much more efficient.", name: "Michael Beard", company: "City of Harker Heights", position: "Building Official"},
                    {content: "I love it because I always have access to the building code, even if I left my book at the office. Super easy to navigate.", name: "Carrie Shell", company: "SQD Architects, LLC", position: "Principal"},
                    {content: "Our office is completely digital, so the digital codes and commentaries tie right in with our office and permitting philosophies.", name: "Daniel Ice", company: "Pima County", position: "Chief Building Official"},
                    {content: "The COVID-19 pandemic pushed the City of San Diego Development Services Department (DSD) to transition from a primarily paper-based permitting and construction plan review process to an electronic process. All new projects, permits and construction changes are now processed digitally online. Paper plans and documents for new projects are now a thing of the past. During the pandemic, construction was still ongoing, and DSD staff still had to perform reviews and inspections while working remotely due to the State-mandated lockdown. We had to incorporate an immediate, efficient and readily available solution. It was a no-brainer:We selected Digital Codes-Premium Complete and have been using it for several months now. I admit that it is a game-changer. All the heavy, bulky volumes of codes, interpretive manuals, and the current and previous editions of reference books no longer fill up my office shelves. And did I mention that these heavy books were not always available to me when I needed them? Now, day or night and or if I am at home working remotely, in a virtual meeting, in an out-of-state ICC Code Action Hearing, symposium or even at the airport waiting for my next flight, I’m just a click away from the Digital Codes-Premium Complete. It’s reassuring to know that I have unlimited access to all – and I mean all – the must-have codes and reference materials that professionals, designers and building code inspectors and officials need. And the cherry on top is that you can cut and paste sections into your document or email;now that is a time-saver! It is just a marvelous tool and resource to have. I absolutely love it!", name: "Mehdi Shadyab", company: "City of San Diego", position: "Senior Structural Engineer"},
                    {content: "Digital codes allows me to search for specific codes, also allows me to print code sections, email code sections, and copy and paste code sections for reference materials for violation and compliance documents.", name: "Matt Taggar", company: "Vermillion Fire EMS", position: "Fire Marshal"},
                    {content: "These digital resources are indispensable.", name: "China Clarke", company: "New York State Dept of State", position: "Senior Architect"},
                    {content: "The ICC Digital Codes makes it very convenient to access the material I need to share with my customers. I can find just what I need and include it in my reviews, orders, and explanations to others, confident that this is the most accurate, updated information. And I can do this from my office or in the field, from my desktop or my phone. ICC Digital Codes are a cost effective way for me to deliver quality results to my customers.", name: "Joseph Iliff", company: "Village of Swansea, Illinois", position: "Building & Zoning Director"},
                    {content: "As our state moves through the process of adopting the next edition of the state building code, access to Digital Codes has been invaluable in keeping abreast as to what the proposed front-end changes to the 2021 IBC/IRC actually mean.", name: "John Nunnari", company: "Massachusetts Chapter of the American Institute of Architects", position: "Executive Director"},
                    {content: "Using ICC Digital Codes Premium has enabled our State of Connecticut team to take our code research and understanding to the next level by using the codes, commentaries, significant changes books, interpretations and code development history in our state code development process, code modification reviews and general support of our industry. We no longer use paper code books and haven't looked back.", name: "Darren Hobbs", company: "State of Connecticut", position: "Director of Regulatory Compliance"},
                    {content: "It has helped me with not only explaining codes, but as a Plumbing 3 instructor, I use it to teach Plumbing codes to my class. Great wealth of resource for not only teachers, inspectors, but for students as well", name: "Walter Walker", company: "Chesterfield County", position: "Residential Combination Inspector"},
                    {content: "The City of Sacramento transitioned to electronic codes for our plan review, permit services, and field inspection staff and we haven't looked back! The variety of licensing options provides flexibility to meet anyone's needs. Of particular use are the Digital Codes Premium Complete which provide access to hundreds of codes, but is still easy to navigate. DCP Complete allows you to save your favorite codes, easily search for key words, bookmark sections, and add personal notes and highlights to make the code your own.", name: "Anna Tekautz", company: "City of Sacramento", position: "Supervising Engineer"}
                ],
                slideNumber: 0,
                slideShow: {
                    paginationColor: '#eeeeee',
                    paginationColorActive: '#0b5940',
                    nextButton:
                    '<button style="top: 0;" type="button"><span class="v-btn__content"><i style="font-size:40px; color: #0b5940;" aria-hidden="true" class="v-icon notranslate material-icons">keyboard_arrow_right</i></span></button>',
                    prevButton:
                    '<button style="top: 0;" type="button"><span class="v-btn__content"><i style="font-size:40px; color: #0b5940;" aria-hidden="true" class="v-icon notranslate material-icons">keyboard_arrow_left</i></span>'
                },
                caseStudys:[
                    {
                        link: 'https://www.iccsafe.org/building-safety-journal/bsj-perspectives/case-study-how-linn-county-unshackled-inspectors-in-the-field/',
                        heading: 'Case study: How Linn County unshackled inspectors in the field',
                        subHeading: 'ICC Digital Codes Premium helps inspectors know more and do more',
                        image: 'case-study-how.webp'
                    },
                    {
                        link: 'https://www.iccsafe.org/building-safety-journal/bsj-perspectives/how-chesterfield-building-inspectors-streamlined-their-inspection-process/',
                        heading: 'Case study: How Chesterfield Building Inspectors Streamlined Their Inspection Process',
                        subHeading: 'ICC Digital Codes Premium helps inspectors know more and do more',
                        image: 'bulider2.webp'
                    }
                ]
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            LogoCarousel,
            Carousel,
            Slide,
            AssetImage
        },
    }
</script>

<style lang="scss" scoped>

    .v-window-x-reverse-transition-leave-to {
         transform: translateX(-100%) !important;
    }

    .v-window-x-reverse-transition-enter {
    transform: translateX(100%) !important;
    }

    .caseStudy {
        background: #EEEEEE;
        width: 100%;

        .caseStudyHeading {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
        }

        .fontSize18 {
            font-size: 18px;
        }
    }

    .caseStudyImageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .caseStudyImage {
            width: 100%;
            object-fit: cover;
            position: relative;
            max-height: 400px;
        }

        .imageRight {
            right: 11px;
        }
        .imageRightMobile {
            right: 4px;
        }
    }
    .flexDirection {
        flex-direction: column;
    }
    .testimonialBackground {
        width: 100%;
        background: #A0DEBB;
        border-radius: 4px;

        .webWidth {
            max-width: 80%;
            min-width: 80%;
        }

        .mobileWidth {
            max-width: 100%;
            min-width: 100%;
        }

        .interTestimonialDivWrapper {
            position: relative;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 80px;
        }

        .interTestimonialDiv {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            padding: 32px 32px;
            background: #FFFFFF;
            border-radius: 12px;
            margin:auto;
        }

        blockquote.quotes {
            position: relative;

            &:before {
                font-family: Roboto,sans-serif;
                color: #275741;
                position: absolute;
                content: open-quote;
                font-size: 4em;
                margin-top: -0.4em;
            }

            &:after {
                font-family: Roboto,sans-serif;
                color: #275741;
                position: absolute;
                content: close-quote;
                font-size: 4em;
                bottom: 0;
                left: 0;
                margin-bottom: -0.8em;
            }
        }

        .v-window__next, .v-window__prev {
            background: transparent;
        }

        .theme--dark.v-btn.v-btn--icon {
            color: #275741;
        }

        .v-carousel__controls {
            background: none;
        }

        .v-carousel__controls {
            padding-bottom: 20px;
        }
    }
    .interTestimonialDivWrapper:after {
        background-image: url('../../images/testimonials/polygon.webp');
        content: '';
        background-repeat: no-repeat;
        content: '';
        display: flex;
        position: relative;
        bottom: 6px;
        width: 64px;
        height: 56px;
        border-radius: 4px;
        left: 50px;
        background-size: 100%;
    }
    .testimonials-container {
        max-width: 1200px;

        .font-weight-regular {
            font-weight: 700 !important
        }

        .oxygen {
            font-family: Oxygen;
        }
        @media (min-width: 641px) {
            h1 {
                font-size: 54px;
                line-height: 68px;
            }
            h2 {
                font-size: 32px;
                line-height: 42px;
            }
        }
    }
</style>
