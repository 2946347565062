<template>
    <v-row
        class="hero-container"
        :class="{
            'py-16': !isMobilePort,
            'pb-8': isMobilePort
        }"
        align="center"
        justify="center">
        <v-col
            cols="12"
            sm="6"
            :order="textRight ? 2 : 1">
            <h2
                class="text-h4 font-weight-bold oxygen mb-6"
                v-html="title">
            </h2>
            <h3
                v-if="subtitle"
                class="text-body-1 mb-6"
                v-html="subtitle">
            </h3>
            <slot name="button">
            </slot>
            <slot name="footer">
            </slot>
        </v-col>
        <v-col
            :order="textRight ? 1 : 2"
            :class="{'text-center': isMediumAndDown}"
        >
            <slot name="image">
            </slot>
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';

    export default {
        name: 'InfoBanner',
        props: {
            title: {
                required: true,
                type: String
            },
            subtitle: {
                required: false,
                type: String
            },
            textRight: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        mixins: [
            Mobile
        ]
    }
</script>

<style
    lang="scss"
    scoped>
    .hero-container {
        img {
            max-width: 100%;
        }
    }
</style>
