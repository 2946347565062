var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "companyLogos" },
    [
      _c(
        "carousel",
        {
          attrs: {
            "per-page": _vm.isMobilePort ? _vm.mobilePerPage : _vm.perPage,
            paginationEnabled: false,
            navigationEnabled: true,
            navigationNextLabel: _vm.slideShow.nextButton,
            navigationPrevLabel: _vm.slideShow.prevButton,
            paginationColor: _vm.slideShow.paginationColor,
            paginationActiveColor: _vm.slideShow.paginationColorActive,
          },
        },
        _vm._l(_vm.companyLogos, function (companyLogo, index) {
          return _c(
            "slide",
            { key: index },
            [
              _c("asset-image", {
                staticClass: "logoContainer",
                attrs: {
                  src: require("../../images/testimonials/" + companyLogo.logo),
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }