<template>
    <div class="companyLogos">
        <carousel
            :per-page="isMobilePort ? mobilePerPage : perPage"
            :paginationEnabled="false"
            :navigationEnabled='true'
            :navigationNextLabel="slideShow.nextButton"
            :navigationPrevLabel="slideShow.prevButton"
            :paginationColor="slideShow.paginationColor"
            :paginationActiveColor="slideShow.paginationColorActive">
            <slide
                v-for="(companyLogo, index) in companyLogos"
                :key="index">
                <asset-image
                    class="logoContainer"
                    :src="require('../../images/testimonials/'+companyLogo.logo)"/>
            </slide>
        </carousel>
    </div>
</template>

<script>
    import AssetImage from "../AssetImage.vue";
    import Mobile from "../inc/mixins/mobile";
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: 'LogoCarousel',
        data() {
            return {
                perPage: 8,
                mobilePerPage: 4,
                slideShow: {
                    paginationColor: '#eeeeee',
                    paginationColorActive: '#0b5940',
                    nextButton:
                        '<button style="top: 0;" type="button"><span class="v-btn__content"><i style="font-size:40px; color: #0b5940;" aria-hidden="true" class="v-icon notranslate material-icons">keyboard_arrow_right</i></span></button>',
                    prevButton:
                        '<button style="top: 0;" type="button"><span class="v-btn__content"><i style="font-size:40px; color: #0b5940;" aria-hidden="true" class="v-icon notranslate material-icons">keyboard_arrow_left</i></span>'
                },
                companyLogos: [
                    {logo: 'virginia-dhcd-logo-1.png'},
                    {logo: 'cal-fire-gov-logo-1.png'},
                    {logo:'new-york-state-gov-logo-1.png'},
                    {logo: 'Los_Angeles_City_Seal_bw.jpg'},
                    {logo: 'bureau-veritas-logo-1.png'},
                    {logo: 'safe-built-logo-1.png'},
                    {logo: 'city-of-saint-paul-minnesota-logo-1.png'},
                    {logo: 'us-fish-and-wildlife-services-logo-1.png'},
                    {logo: 'city-of-austin-texas-logo-1.png'},
                    {logo: 'shums-coda-logo.png'},
                    {logo: 'ssoe-group-architect-magazine-logo-1.png'},
                    {logo: 'intel-logo-1.png'},
                    {logo: 'eea-consulting-engineers-logo-1.png'},
                    {logo: 'newman-architects-logo-1.png'},
                    {logo: 'sgpa-architecture-and-planning-logo-1.png'},
                    {logo: 'new-york-city-logo-1.png'},
                    {logo: 'zimmer-gunsul-frasca-architects-logo.webp'},
                    {logo: 'wrns-studio-logo.webp'},
                    {logo: 'woods-bagot-logo.webp'},
                    {logo: 'wold-architects-and-engineers-logo.webp'},
                    {logo: 'wm-ware-malcomb-logo.webp'},
                    {logo: 'wiss-janney-elstner-associates-logo.webp'},
                    {logo: 'wendel-companies-logo-vector.webp'},
                    {logo: 'tsp-arhitecture-engineering-planning-logo.webp'},
                    {logo: 'treanorhl-logo.webp'},
                    {logo: 'studio-w-architects-logo.webp'},
                    {logo: 'studios-architecture-logo.webp'},
                    {logo: 'group.webp'},
                    {logo: 'group-1087.webp'},
                    {logo: 'gkkworks-25th-anniversary-logo-square.webp'},
                    {logo: 'aecom-logo.webp'},
                ],
            }
        },
        components: {
            AssetImage,
            Carousel,
            Slide,
        },
        mixins: [
            Mobile
        ]
    }
</script>

<style lang="scss" scoped>
    .companyLogos {
        .VueCarousel-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .logoContainer {
        max-height: 40px;
    }
</style>