<template>
    <v-card outlined>
        <v-card-text>
            <v-form ref="form" @submit.prevent="submitFormData">
                <h5 class="mb-2 text-h5 font-weight-medium">Ask New Question</h5>
                <p class="text-body-1 mb-8">Required fields*</p>
                <div class="my-5">
                    <h6 class="text-h6 font-weight-medium">Code Title and Section</h6>
                    <p class="text-body-2 text--secondary mb-2">Select the International Codes (I-Code) that your question applies to.</p>
                    <v-row>
                        <v-col :cols="isMobilePort ? 12 : 6">
                            <v-autocomplete
                                v-model="codeTitle"
                                :items="titles"
                                :loading="titlesLoading"
                                :search-input.sync="titleSearch"
                                :rules="[rules.required]"
                                no-filter
                                outlined
                                hide-no-data
                                clearable
                                hide-details
                                append-icon=""
                                item-text="display_title"
                                item-value="display_title"
                                label="Code Title*"
                                placeholder="Start typing to Search"
                                @keydown="debouncedGetResults">
                                <template v-slot:item="{ item }">
                                    <title-list
                                        style="width: 100%"
                                        :title="item"
                                        type="list"
                                        :showLinkIcon="false"
                                        :listOutline="false"
                                        :preventLink="true"
                                        :hover="false"
                                        background="transparent">
                                    </title-list>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col :cols="isMobilePort ? 12 : 6">
                            <v-text-field
                                v-model="sectionNumber"
                                :rules="[rules.required]"
                                hide-details
                                clearable
                                outlined
                                label="Section Number*"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <div class="my-5">
                    <h6 class="text-h6 font-weight-medium">Question</h6>
                    <p class="text-body-2 text--secondary mb-2">
                        Include all the information the ICC team would need to answer your question.
                    </p>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="subject"
                                :rules="[rules.required]"
                                label="Question Subject*"
                                outlined
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="questionDetails"
                                :rules="[rules.required]"
                                hide-details
                                label="Question Details*"
                                outlined>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-file-input
                                v-model="attachments"
                                outlined
                                multiple
                                hide-details
                                label="Attachments"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox
                                hide-details
                                :rules="[v => !!v || 'You must accept the terms and conditions']"
                                v-model="termsAccepted">
                                <template v-slot:label>
                                    <p class=" mb-0">
                                        I accept the <a
                                            href=""
                                            @click.stop
                                            target="_blank"
                                            class="text-decoration-underline">Terms of Service</a>
                                    </p>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="mb-8">Submitting your question will use 1 credit. You will have 4 credits remaining.</p>
                            <div class="d-flex">
                                <v-btn
                                    depressed
                                    type="submit"
                                    :loading="isProcessing"
                                    color="primary">
                                    Submit Question
                                </v-btn>
                                <div class="mx-1"></div>
                                <v-btn
                                    outlined
                                    color="primary"
                                    :href="this.getRoute('quick_consult')">
                                    Cancel
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import TitleList from '../../../components/titles/cover/Index.vue';
import mobile from '../../inc/mixins/mobile';
import { QuickConsultApi } from '../../../api/QuickConsultApi';

export default {
    data() {
        return {
            termsAccepted: false,
            isProcessing: false,
            codeTitle: '',
            codeYear: '',
            chapter: '',
            sectionNumber: '',
            subject: '',
            questionDetails: '',
            attachments: [],
            titlesLoading: false,
            titleSearch: null,
            rules: {
                required: value => !!value || 'This field is required.',
            },
            titles: []
        }
    },
    components: {TitleList},
    mixins: [mobile],
    methods: {
        async submitFormData(){
            if (this.$refs.form.validate()) {
                this.isProcessing = true;
                const formData = new FormData();
                formData.append('code_title', this.codeTitle);
                formData.append('code_section', this.sectionNumber);
                formData.append('title', this.subject);
                formData.append('description', this.questionDetails);

                this.attachments.forEach((file, index) => {
                    formData.append(`attachments[${index}]`, file);
                });

                QuickConsultApi.postQuestion(formData).then((response) => {
                }).finally(() => {
                    this.isProcessing = false;
                    this.goToRoute('quick_consult');
                });

            } else {
                console.log('Validation error');
            }

        },
        loadTitles(){
            this.titles = [];
            this.titlesLoading = true;
            let postData = {
                search : this.titleSearch,
                forQuickAccess: 0,
                category: 'I-Codes' //only fetch I-Codes titles
            };
            QuickConsultApi.titleSearchAutoComplete(postData).then((response) => {
                this.titles = response;
            })
            .finally(()=>{
                this.titlesLoading = false;
            })
        }
    },
    created() {
        this.debouncedGetResults = _.debounce(() => this.loadTitles(), 500);
    }
}
</script>

<style lang="scss" scoped>

</style>