import Vue from 'vue';
import NoteList from './../partials/noteListOld';
import NotePreview from './../partials/notePreview';
import SectionPrintMenu from './../partials/sectionPrintMenu';

// Rangy stuff.
import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-textrange';
import {SelectionUtil} from "../../../util/SelectionUtil";
import {DocumentInfo} from "../../../classes/DocumentInfo";

export default {
    methods: {
        /**
         * Add or remove the highlight for text.
         *
         * @param {Object} applier      Used to set the span tags, ids and some styles of the highlighted text. https://github.com/timdown/rangy/wiki/Class-Applier-Module
         * @param {HTMLElement} section Used to search for the xpath within the section that contains the highlight.
         * @param {Object} annotation   Object returned by the DB, needed for various data.
         * @param {string} action       What action the method should take.
         */
        async highlightUtil(applier, section, annotation, action) {
            // If we have an old annotation rework the node xpath.
            try {
                let xpaths = this.parseXpaths(annotation);

                if (xpaths) {
                    let startElement = this.findXPathElement(section, xpaths.start, xpaths.startInsTagCount);
                    let endElement = (xpaths.start === xpaths.end) ? null
                            : this.findXPathElement(section, xpaths.end, xpaths.endInsTagCount);
                    this.applyHighlights(annotation, applier, action, startElement, endElement);
                } else {
                    return false;
                }
            } catch (error) {
                throw new Error(error);
            }
        },

        applyHighlights(annotation, applier, action, startElement, endElement = null) {

            // Set our range.
            rangy.init();
            let range = rangy.createRangyRange();

            if (!_.isNull(startElement)) {
                let charsCounted = startElement.textContent.replace(/\s\s+/g, ' ');
                let endOffset = null;
                if (endElement && startElement) {
                    endOffset = charsCounted.length;
                } else {
                    endOffset = annotation.end_offset;
                }
                range.selectCharacters(startElement, annotation.start_offset, endOffset);

                switch (action) {
                    case 'add':
                        applier.applyToRange(range);
                        break;
                    case 'remove':
                        applier.undoToRange(range);
                        break;
                }
            }

            // Highlight our start and end element text.
            if (!_.isNull(startElement) && !_.isNull(endElement)) {
                range.selectCharacters(endElement, 0, annotation.end_offset);

                switch (action) {
                    case 'add':
                        applier.applyToRange(range);
                        break;
                    case 'remove':
                        applier.undoToRange(range);
                        break;
                }

                // Highlight everything in between the two nodes.
                range.setStartAfter(startElement);
                range.setEndBefore(endElement);

                switch (action) {
                    case 'add':
                        applier.applyToRange(range);
                        break;
                    case 'remove':
                        applier.undoToRange(range);
                        break;
                }
        }
        },

        findXPathElement(section, xPath, insTagCount) {
            let element = document.evaluate(xPath, section, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

            if (element && insTagCount) {
                let exactTag = element.getElementsByTagName("ins");
                if (exactTag) {
                    element = exactTag[insTagCount - 1];
                }
            }

            return element;
        },

        parseXpaths(annotation) {
            let xPathStart = annotation.start;
            let xPathEnd = annotation.end;
            let startInsTagCount = null;
            let endInsTagCount = null;

            if (annotation.is_old) {
                xPathStart = this.parseOldElementsXpath(annotation.start);
                xPathEnd = this.parseOldElementsXpath(annotation.end);
            }
            // Todo: Need to record error.
            if (_.isEmpty(xPathStart) || _.isEmpty(xPathEnd)) {
                return false;
            }
            // Check if note starting xpath has ins tag
            if (this.findInsTagInXpath(xPathStart)) {
                let updatedXpath = this.updateXpathForInsTag(xPathStart);
                startInsTagCount = updatedXpath.insTagCount;
                xPathStart = updatedXpath.xpath;
            }
            // Check if note ending xpath has ins tag
            if (this.findInsTagInXpath(xPathEnd)) {
                let updatedXpath = this.updateXpathForInsTag(xPathEnd);
                endInsTagCount = updatedXpath.insTagCount;
                xPathEnd = updatedXpath.xpath;
            }

            return {
                'start': xPathStart,
                'startInsTagCount': startInsTagCount,
                'end': xPathEnd,
                'endInsTagCount': endInsTagCount
            };
        },

        findInsTagInXpath(xpath) {
            let parts = xpath.split("/");

            if (parts) {
                let lastPart = parts[parts.length - 1];
                return lastPart.includes('ins');
            }

            return false;
        },

        updateXpathForInsTag(xpath) {
            // Default
            let response = {
                'xpath': xpath,
                'insTagCount': 0
            };

            let parts = xpath.split("/");
            if (parts) {
                let lastPart = parts[parts.length - 1];
                if (lastPart.includes('ins')) {
                    let matches = lastPart.match(/(\d+)/);
                    if (matches) {
                        parts.splice(-1);
                        response.xpath = parts.join("/");
                        response.insTagCount = matches[0];
                    }
                }
            }

            return response;
        },

        /**
         * Creates rangy applier.
         * https://github.com/timdown/rangy/wiki/Class-Applier-Module
         *
         * @param {Object} data Incoming data.
         */
        applierUtil(data) {
            rangy.init();
            let tagId = data.tagId || 0;
            let rangyOptions = {
                useExistingElements: false,
                elementAttributes: {
                    'id': 'note-annotation-' + data.annotationId,
                    'style': 'background-color: #' + data.tagColor,
                    'data-tag-id': tagId
                }
            };
            return rangy.createClassApplier('highlighter', rangyOptions);
        },

        /**
         * Removes highlighted text.
         *
         * @param {Object} data Incoming data.
         */
        removeHighlight(data) {
            // Set our rangy applier class and section.
            rangy.init();
            let applierData = {
                annotationId: data.note.annotation.id,
                tagColor: this.getTagColor(data.tag.id),
                tagId: data.tag.id
            };
            let applier = this.applierUtil(applierData),
                    section = document.getElementById('action-wrapper-' + data.sectionId);

            // Remove highlight.
            this.highlightUtil(applier, section, data.note.annotation, 'remove');
        },
        parseOldElementsXpath(string) {
            // Split our xpath into an array for parsing.  Drop first two keys associated with the old content rendering.
            let elements = _.omit(_.compact(string.split('/')), [0, 1]);

            // Remove section tags from xpath.
            elements = _.filter(elements, function (o) {
                return !_.includes(o, 'section')
            });

            // Check to see if our first xpath is a div, if so then roll back a key for the old XML div action buttons being recorded.
            if (_.includes(elements[0], 'div')) {
                let index = elements[0].match(/\d+/g) - 1;
                elements[0] = 'div[' + index + ']';
            }
            return _.join(elements, '/');
        },
        stripIds(id) {
            return id.replace(/\D/g, '');
        },

        /**
         * Get the tag color from the tag ID.
         *
         * @param {number} tagID Tag ID that gets converted to an integer.
         * @return {string} Return the tag color.
         */
        getTagColor(tagID) {
            let tag = _.find(this.$store.getters.getTags, {'id': _.toInteger(tagID)});

            return (!_.isUndefined(tag) && tag.color) ? tag.color : this.$getConst('defaultTagColorRaw');
        },
        applyContentProtection() {
            if (_.toNumber(this.contentProtection) || this.isExamPreview()) {
                let _this = this;

                document.addEventListener('keydown', function (e) {
                    _this.bindKey(
                            e,
                            _this.activeDocumentInfo,
                            _.toNumber(_this.contentPremium),
                            _.toNumber(_this.bookAvailableToSubscribe)
                            );
                });

                if (_.isUndefined(this.activeDocumentInfo.contentAccess.canCpp)
                        || !_.toNumber(this.activeDocumentInfo.contentAccess.canCpp)
                        || this.isExamPreview()
                        ) {
                    // Prevent copy/paste (older browsers)
                    document.addEventListener('keydown', function (e) {
                        // "ctrl + c" key
                        if (e.ctrlKey && e.keyCode == 67) {
                            EventBus.fire('disallowed-copy');
                            _this.disabledEvent(e);
                        }
                        // "command + c" key macOS
                        if (e.keyCode == 67 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                            EventBus.fire('disallowed-copy');
                            _this.disabledEvent(e);
                        }
                    });

                    document.addEventListener('copy', function (e) {
                        EventBus.fire('disallowed-copy');
                        e.preventDefault()
                    });
                    document.addEventListener('drag', function (e) {
                        EventBus.fire('disallowed-copy');
                        e.preventDefault()
                    });

                    // Prevent right click
                    document.addEventListener('contextmenu', function (e) {
                        e.preventDefault()
                    });

                    window.addEventListener('beforeprint', function (event) {
                        document.body.classList.add('no-print');
                    });

                    window.addEventListener('afterprint', function (event) {
                        document.body.classList.remove('no-print');
                    });
                }

                // Prevent PDF download
                if ('pdf' === this.type) {
                    EventBus.fire('disable-pdf-download');
                }

                if (_.isUndefined(this.activeDocumentInfo.contentAccess.canAnnotate)
                        || !_.toNumber(this.activeDocumentInfo.contentAccess.canAnnotate)
                        ) {
                    document.body.classList.add('disable-selection');
                }
            } else {
                let _this = this;
                document.addEventListener('keydown', function (e) {
                    _this.bindKeyPC(e);
                });
            }
        },
        redirectToAstmDocument(astmRefStd, sectionNumber) {
            let url = Routing.generate('astm_standard_info', {iccDesignation: astmRefStd});
            this.$http.get(url).then(response => {
                let result = response.data.data ? response.data.data : null;
                let astmDocument = result ? result.document : null;

                if (astmDocument) {
                    window.location.href = Routing.generate("content_document_slug", {
                        documentSlug: astmDocument.document_slug,
                        contentId: sectionNumber
                    });
                } else {
                    this.showAstmErrorModal();
                }
            });
        },
        showAstmErrorModal() {
            let data = {
                show: true,
                astmError: true,
                errorMessage: 'ASTM RefStd not found'
            }
            EventBus.fire("astmModal", data);
        },
        showAcScopeModal(dataAc) {
            let data = {
                show: true,
                acNumber: dataAc
            }
            EventBus.fire("acScopeModal", data);
        },
        bindContentLinks() {
            let _this = this;
            let links = document.getElementsByTagName('a');
            let docId = _.toInteger(_this.documentid);

            // Bind clicks for the ASTM Standard links
            _.forEach(links, function (link) {
                // If data attribute exists (will be available for the reference standards page)
                if (link.getAttribute("astm-modal")
                        && link.getAttribute("section-number")
                        && _this.isAstmCompassEnabled()) {
                    link.addEventListener('click', function (event) {
                        event.preventDefault();
                        let astmRefStd = link.getAttribute("astm-modal");
                        let sectionNumber = link.getAttribute("section-number");
                        _this.redirectToAstmDocument(astmRefStd, sectionNumber);
                    });

                    link.addEventListener('mouseover', function (event) {
                        let data = {
                            show: true,
                            event: event,
                            targetId: link.getAttribute("astm-modal"),
                            documentId: docId,
                            isEsAcLink: false,
                            isAstmLink: _this.checkIsAstmLink(link)
                        };
                        _this.debouncePeakAhead(data);
                    });
                    link.addEventListener('mouseout', function (event) {
                        _this.debouncePeakAhead.cancel();
                        EventBus.fire('togglePeakAhead', false);
                    });
                } else {
                    // If data attribute do not exists then match the regular expressions against the href id
                    let targetId = _this.getContentLinkTargetId(link);
                    if (targetId) {
                        link.setAttribute('href', '/lookup/' + targetId + '/' + docId);
                        link.addEventListener('click', function (event) {
                            event.preventDefault();
                            // Condition to check ASTM link within content and on click open astm modal.
                            if (targetId.includes('_PromASTM_')
                                    && _this.isAstmCompassEnabled()) {
                                let astmRefStd = targetId.match(/RefStd(.*)/);
                                let sectionNumber = link.closest('section').getAttribute("section-number");
                                if (astmRefStd != null
                                        && sectionNumber) {
                                    _this.redirectToAstmDocument(astmRefStd[1], sectionNumber);
                                } else {
                                    _this.showAstmErrorModal();
                                }
                            } else if (targetId.includes('_ASTM_')
                                    && _this.isAstmCompassEnabled()) {
                                let astmRefStd = targetId.match(/ASTM(.*)/);
                                let sectionNumber = link.closest('section').getAttribute("section-number");
                                if (astmRefStd != null
                                        && sectionNumber) {
                                    _this.redirectToAstmDocument(astmRefStd[1], sectionNumber);
                                } else {
                                    _this.showAstmErrorModal();
                                }
                            } else if (link.getAttribute("data-ac")) {
                                if (_this.isEsAcScopeAcEnabled()) {
                                    let dataAc = link.getAttribute("data-ac");
                                    _this.showAcScopeModal(dataAc);
                                }
                            } else {
                                _this.handleContentLink(targetId, docId);
                            }
                        });
                        // Check ES XML AC Link
                        let isEsAcLink = _this.checkIsEsAcLink(link.getAttribute('data-ac'));

                        link.addEventListener('mouseover', function (event) {
                            let data = {
                                show: true,
                                event: event,
                                targetId: targetId,
                                documentId: docId,
                                isEsAcLink: isEsAcLink,
                                isAstmLink: _this.checkIsAstmLink(link)
                            }
                            _this.debouncePeakAhead(data);
                        });
                        link.addEventListener('mouseout', function (event) {
                            _this.debouncePeakAhead.cancel();
                            EventBus.fire('togglePeakAhead', false)
                        });
                    }
                }
            });
        },
        checkIsEsAcLink(isEsAcLink) {
            return isEsAcLink ? this.$getConst('esAcLink') : '';
        },
        checkIsAstmLink(link) {
            return !!link.getAttribute('data-astm-compass');
        },
        showPeakAhead(data) {
            EventBus.fire('togglePeakAhead', data);
        },
        getContentLinkTargetId(link) {
            let targetId = null;
            let href = link.getAttribute('href');

            if (href && href.length > 1 && '#' == href.charAt(0)) {
                targetId = href.replace(/#/, '');
            } else if (href && href.indexOf('/book/linkit/') != -1) {
                targetId = href.replace(/\/book\/linkit\//, '');
            } else if (href && href.indexOf('/book/externallinkit/') != -1) {
                let parts = href.split('/');
                targetId = parts[parts.length - 1];
            }

            return targetId;
        },
        handleContentLink(targetId, documentId) {
            let element = document.querySelector('#contentParent [id*=\'' + targetId + '\' i]');
            if (element) {
                this.handleTargetScroll(element);
            } else {
                window.location = Routing.generate('content_lookup', {'id': targetId, 'docId': documentId});
            }
        },
        handleTargetScroll(element) {
            let navigation_bar_height = document.getElementById('navigation-app').getBoundingClientRect().height;
            this.$scrollTo(element, 100, {offset: navigation_bar_height * -1});

            // Update Url History
            for (let i = 0; i < 7; i++) {
                if (element.tagName != 'SECTION') {
                    element = element.parentElement;
                    continue;
                }
                let iterationElement = element;
                for (let ii = 0; ii < 7; ii++) {
                    var chapter = iterationElement.querySelector('.section-action-wrapper').getAttribute("data-chapter-title");
                    var iterationChapterSlug = chapter.trim().replace(/\s\s+/g, ' ').toLowerCase().split(' ');
                    if (iterationChapterSlug[0] == 'chapter') {
                        break;
                    }
                    iterationElement = iterationElement.parentElement;
                }
                let chapterSlug = iterationChapterSlug.join('-');
                let sectionId = element.id;
                let href = window.location.href;
                href = href.split('#')[0].split('/').splice(0, 5).join('/');
                history.pushState(null, null, href + '/' + chapterSlug + '#' + sectionId);
                break;
            }
        },
        addSpaceAroundSections(){
            const sections = document.querySelectorAll('.section-action-wrapper');

            sections.forEach(section => {
                const elements = section.querySelectorAll('.section_number, .chapter_number');

                elements.forEach(element => {
                    element.innerHTML = ` ${element.innerHTML.trim()} `;
                });
            });
        },
        wrapContent(chapterId = 0, isTrialActive = '0') {
            let currentChapterId = chapterId;
            let _this = this;
            let sections = document.getElementsByClassName('section-action-wrapper');
            let isPermissionAllowed = _.toNumber(this.contentPremium);

            const typedDocument = new DocumentInfo(_this.activeDocumentInfo);

            // Add our HTML and icons to the sections.
            _.forEach(sections, function(value) {
                if(value.getElementsByClassName('section_number')[0]){
                    let sectionNumber = value.getElementsByClassName('section_number')[0].innerHTML;
                    value.getElementsByClassName('section_number')[0].innerHTML = ` ${sectionNumber} `;
                }
                if(value.getElementsByClassName('chapter_number')[0]){
                    let chapterNumber = value.getElementsByClassName('chapter_number')[0].innerHTML;
                    value.getElementsByClassName('chapter_number')[0].innerHTML = ` ${chapterNumber} `;
                }
                let id = _this.stripIds(value.id), html = '';

                html += '<i id="section-action-icon-' + id + '" aria-hidden="true" class="mt-2 icon_apps v-icon material-icons theme--light trigger-section-action float-right disable-selection" style="color: rgba(0,0,0,.54)!important;"></i>';
                html += '<div id="section-action-buttons-' + id + '" class="mt-2 section-action-buttons disable-selection" style="display: none;">';

                // Hide print icon if book access level does not allow it
                if (1 !== _.toNumber(_this.contentProtection)) {
                    html += '<i data-qa="title-icon-action-print" id="' + id + '_print" aria-hidden="true" class="icon_local_printshop print-menu v-icon material-icons theme--light trigger-print-action float-right mr-2" title="Print" style="color: rgba(0,0,0,.54)!important;"></i>';
                }

                html += '<i data-qa="title-icon-action-share" id="' + id + '_share" aria-hidden="true" class="icon_link v-icon material-icons theme--light trigger-share-action float-right mr-2" title="Share" style="color: rgba(0,0,0,.54)!important;"></i>';

                // Annotations and Bookmarks must be disabled if level=12
                if (typedDocument.isBookmarkingSectionsAllowed()) {
                    html += '<i data-qa="title-icon-action-bookmark" id="' + id + '_bookmark" aria-hidden="true" class="icon_bookmark_border v-icon material-icons theme--light trigger-bookmark-action float-right mr-2" title="Bookmark" style="color: rgba(0,0,0,.54)!important;"></i>';
                }

                if (typedDocument.isAnnotationAllowed()) {
                    html += '<i data-qa="title-icon-action-annotate" id="' + id + '_note" aria-hidden="true" class="icon_border_color v-icon material-icons highlight-icon-disabled theme--light trigger-note-action float-right mr-2" title="Select text to enable the highlight and note creation" style="color: rgba(0,0,0,.54)!important;"></i>';
                }

                html += '</div>';
                value.innerHTML = (isPermissionAllowed) ? html + value.innerHTML : value.innerHTML;

                if (isPermissionAllowed) {
                    // Todo:  Need to add this to the icon.
                    // value.setAttribute('title','Click to access premium tools for this section.');
                }
                value.addEventListener('click', function (event) {
                    if (!event.target.classList.contains('trigger-section-action') && isPermissionAllowed)
                        _this.toggleSectionAction(_this.stripIds(event.target.closest('.section-action-wrapper').id), 'show');
                });
            });

            // Add flashcard title for answer.
            let flashcard = document.getElementsByClassName('flashcard');
            let details = document.getElementsByTagName('DETAILS');
            if (flashcard.length && details.length) {
                _.forEach(details, function (value) {
                    value.setAttribute('title', 'Click to show answer.');
                });
            }

            if (isPermissionAllowed) {
                // Configure click for section action icon.
                let actionIcon = document.getElementsByClassName('trigger-section-action');
                _.forEach(actionIcon, function (value) {
                    value.addEventListener('click', function (event) {
                        // Remove highlight.
                        this.highlightedText = {};
                        if (window.getSelection)
                            window.getSelection().removeAllRanges();
                        else if (document.selection)
                            document.selection.empty();
                        _this.toggleSectionAction(_this.stripIds(event.target.id));
                    });
                });
                // Configure click for print action icon.
                let printIcon = document.getElementsByClassName('trigger-print-action');
                _.forEach(printIcon, function (value) {
                    value.addEventListener('click', function (event) {
                        _this.togglePrintAction(_this.stripIds(event.target.id));
                    });
                });
                // Configure click for share action icon.
                let shareIcon = document.getElementsByClassName('trigger-share-action');
                _.forEach(shareIcon, function (value) {
                    value.addEventListener('click', function (event) {
                        _this.toggleShareAction(_this.stripIds(event.target.id));
                    });
                });
                // Configure click for bookmark action icon.
                let bookmarkIcon = document.getElementsByClassName('trigger-bookmark-action');
                _.forEach(bookmarkIcon, function (value) {
                    value.addEventListener('click', function (event) {
                        _this.toggleBookmarkAction(_this.stripIds(event.target.id));
                    });
                });
                // Configure click for note action icon.
                let noteIcon = document.getElementsByClassName('trigger-note-action');
                _.forEach(noteIcon, function (value) {
                    value.addEventListener('click', function (event) {
                        _this.toggleNoteAction(null, _this.stripIds(event.target.id));
                    });
                });
                // Add mouse up event for when text is highlighted.
                document.getElementById('contentParent').addEventListener('mouseup', function (evt) {
                    _this.disableAddNotes();
                    _this.enableAddNote(false, evt);
                });
                // Enable highlight on table notes.
                let tableNotes = document.getElementsByClassName('note');
                _.forEach(tableNotes, function (value) {
                    value.setAttribute('data-enable-highlight', '1');
                });
                // Append section print menu component
                if (currentChapterId) {
                    this.$store.commit('setChapterSections', _.find(this.$store.getters.getChapters, {content_id: _.toNumber(currentChapterId)}));
                }
        }
        },
        applySectionPrintMenu(id = 0) {
            let sectionId = id;
            let printIcon = document.getElementById(sectionId + '_print');

            if (null != printIcon || !_.isUndefined(printIcon)) {
                let ComponentClass = Vue.extend(SectionPrintMenu);
                let instance = new ComponentClass({
                    propsData: {
                        documentId: _.toInteger(this.documentid),
                        chapterId: _.toInteger(this.activeChapter),
                        sectionId: _.toInteger(sectionId)
                    }
                });
                instance.$mount();
                printIcon.after(instance.$el);
                this.togglePrintMenu(id);
        }
        },
        enableHighlight(event) {
            // Get our elements within the bounding box.  Check to make sure we're on a highlightable element.
            let boundingContainer = null;

            // Check to see if our highlight starts on a span and if we have highlightable text.
            let element = (event.target.tagName === 'SPAN') ? event.target.closest(':not(span)') : event.target;
            if (!_.isNull(element) && element.hasAttribute('data-enable-highlight'))
                boundingContainer = event.target.closest(".section-action-wrapper");

            if (!_.isNull(boundingContainer)) {
                let boundingContainerId = boundingContainer.id,
                        elements = boundingContainer.querySelectorAll('p, td, h1');

                // Check to see if we need to disable the previous node highlight.
                if (this.previousHighlightID && boundingContainerId != this.previousHighlightID) {
                    let prevElements = document.getElementById(this.previousHighlightID).querySelectorAll('p, td, h1');
                    _.forEach(prevElements, function (value) {
                        value.style.userSelect = 'none';
                        value.style.msUserSelect = 'none';
                    });
                }

                // Set our previous highlight element.  This is used to disable highlight if highlighting a different node.
                this.previousHighlightID = boundingContainerId;

                // Remove our user select class for the entire bounding box.
                _.forEach(elements, function (value) {
                    value.style.userSelect = 'text';
                    if (/Trident.*rv:/.test(navigator.userAgent)) {
                        value.style.msUserSelect = 'element';
                    } else {
                        value.style.msUserSelect = 'text';
                    }
                });
            }
        },
        getRealNode(node, tagBan) {
            if (_.includes(tagBan, node.tagName)) {
                return this.getParentNode(tagBan, node.parentElement);
            }
            return node;
        },
        /**
         * Checks to see if text is highlighted, if so set some data and enable the notes modal button.
         * @param {boolean} noteMigration
         * @param {MouseEvent} evt
         */
        enableAddNote(noteMigration = false, evt = null) {

            let obj,
                    anchorNode,
                    focusNode,
                    startNode,
                    endNode,
                    startNodeXPath,
                    endNodeXPath,
                    startOffset,
                    endOffset,
                    text = '',
                    endNodeCheck = [
                        'A',
                        'SPAN',
                        'SUP',
                        'INS'
                    ];

            this.highlightedText = {};

            let activeSectionWrapper = document.querySelector('div.section-action-wrapper:not(.v-card--flat)');
            let clickedSectionWrapper = evt ? evt.target.closest('.section-action-wrapper') : null;

            // so which section was REALLY selected???
            let selectedSectionWrapper = activeSectionWrapper ? activeSectionWrapper : clickedSectionWrapper;

            // Check to see if we have some text highlighted.  IE11 supported.
            const fullSelection = SelectionUtil.selectionAsElement();

            // selected text spans how many sections?
            const selectionTruncatedToSingleSection = fullSelection.querySelectorAll('.section-action-wrapper').length > 1;

            const sectionSelectionRange = SelectionUtil.getSelectionRangeWithin(selectedSectionWrapper);

            // console.debug({
            //     selectedSectionWrapper,
            //     fullSelection,
            //     selectionTruncatedToSingleSection,
            //     sectionSelectionRange
            // })

            if (!sectionSelectionRange) {
                return;
            }

            let sectionSelectionRangeElement = SelectionUtil.rangeToElement(sectionSelectionRange);

            // Sometimes the "action" buttons will be highlighted too - ignore them!
            let sectionActionButtons = sectionSelectionRangeElement.querySelector('.section-action-buttons');
            if (sectionActionButtons) {
                sectionSelectionRangeElement.removeChild(sectionActionButtons);
            }

            // selection START
            anchorNode = sectionSelectionRange.startContainer;
            // selection END
            focusNode = sectionSelectionRange.endContainer;

            // sometimes we want our start/end element be different from ACTUAL...
            if (anchorNode) {

                if (anchorNode.nodeType === Node.TEXT_NODE) {
                    anchorNode = anchorNode.parentNode;
                } else if (anchorNode instanceof Element) {

                    if (anchorNode.classList.contains('section-action-buttons')) {
                        anchorNode = anchorNode.nextSibling;
                    }
                }
            }

            if (focusNode) {

                if (focusNode instanceof Element) {

                    if (focusNode.classList.contains('section-action-wrapper')) {
                        focusNode = focusNode.lastChild;
                    } else if (focusNode.classList.contains('section-action-buttons')) {
                        focusNode = focusNode.parentNode.lastChild;
                    }

                } else {
                    focusNode = focusNode.parentNode;
                }
            }

            text = sectionSelectionRangeElement.textContent;
            const brCount = sectionSelectionRangeElement.innerHTML.split('<br>').length - 1;

            // console.debug({
            //     anchorNode,
            //     focusNode,
            //     text
            // })

            if (text.length) {
                // Set our start and end nodes.
                startNode = (anchorNode.compareDocumentPosition(focusNode) & Node.DOCUMENT_POSITION_FOLLOWING) ? anchorNode : focusNode;
                endNode = (startNode === anchorNode) ? focusNode : anchorNode;

                // Set our parent if we have an unwanted element tag.
                startNode = this.getRealNode(startNode, endNodeCheck);
                endNode = this.getRealNode(endNode, endNodeCheck);

                // Build our node XPaths.
                let section = startNode.closest('.section-action-wrapper');
                let chapterTitle = section.getAttribute('data-chapter-title');
                chapterTitle = chapterTitle ? chapterTitle.toLowerCase() : null;

                if (chapterTitle && chapterTitle.includes('definitions')) {
                    startNodeXPath = this.buildExactXPath(section, startNode);
                    endNodeXPath = this.buildExactXPath(section, endNode);
                } else {
                    startNodeXPath = this.buildXPath(section, startNode);
                    endNodeXPath = this.buildXPath(section, endNode);
                }
            
                if (noteMigration === true) {
                    if (startNodeXPath === 'div[1]') {
                        startNodeXPath = 'div[2]';
                    }
                    if (endNodeXPath === 'div[1]') {
                        endNodeXPath = 'div[2]';
                    }
                }
                // compensate for \t character which is observable in some listings
                let labelOffset = 0;
                if (startNode.children.length) {
                    let firstChild = startNode.children[0]
                    // TODO: maybe remove the check for class label and count the \t and offset it that many chars?
                    if (firstChild.classList.contains('label') && startNode.innerText.match('\t')) {
                        labelOffset = 1;
                    }
                }
                // Set our offsets.
                startOffset = SelectionUtil.getOffsetsFromRange(startNode, sectionSelectionRange, 'start');
                endOffset = SelectionUtil.getOffsetsFromRange(endNode, sectionSelectionRange, 'end');
                endOffset = endOffset + brCount;
                // add the label offset to the start
                startOffset += labelOffset;
                // add the label offset to the end
                endOffset += labelOffset;

                // Set our highlighted text info.
                let id = this.stripIds(section.id);
                this.highlightedText = {
                    sectionId: id,
                    text: text,
                    selectionTruncatedToSingleSection: selectionTruncatedToSingleSection,
                    startNode: startNodeXPath,
                    endNode: endNodeXPath,
                    startOffset: startOffset,
                    endOffset: endOffset
                };

                // Check to see if the section is active (open).
                if (id && section.classList.contains('v-card--flat') && !noteMigration) {
                    this.toggleSectionAction(id, 'show');
                }

                // Find our add note icon to enable it.
                if (!noteMigration) {
                    document.getElementById(id + '_note').classList.remove('highlight-icon-disabled');
                }
        }
        },

        /**
         * Loop through and get parent node of an element.
         *
         * @param {array} nodeCheck Array of tags to check against.
         * @param {string} node Node of the element to get the parent.
         *
         * @return {string} Return the node parent.
         */
        getParentNode(nodeCheck, node) {
            if (_.includes(nodeCheck, node.tagName))
                return this.getParentNode(nodeCheck, node.parentElement);
            else
                return node;
        },

        /**
         * Loops through and disables all the add note icons.
         */
        disableAddNotes() {
            let icons = document.getElementsByClassName('trigger-note-action');
            _.forEach(icons, function (value) {
                if (!value.classList.contains('highlight-icon-disabled'))
                    value.classList.add('highlight-icon-disabled');
            });
        },

        /**
         * Builds out XPath for when a note is saved.  This is used to highlight the appropriate text.
         *
         * @param {string} section  Used to identify the parent section.
         * @param {string} node     Node that we need to make the XPath for.
         * @param {array}  xPath    Used to collect the XPaths and do the final return.
         *
         * @return {string} Return the XPath.
         */
        buildXPath(section, node, xPath = []) {
            if (node.classList.contains('section-action-wrapper')) {
                return _.join(_.reverse(xPath), '/');
            } else {
                let tagName = node.tagName.toLowerCase();
                if (tagName !== 'span' && tagName !== 'a')
                    xPath.push(tagName + '[' + this.findIndex(node) + ']');
                return this.buildXPath(section, node.parentElement, xPath);
            }
        },

        buildExactXPath(section, node) {

            let sectionPath = this.getXPath(section);
            let nodeXPath = this.getXPath(node);
                nodeXPath = _.replace(nodeXPath, sectionPath, '');

            let tags = nodeXPath.split("/");
            _.forEach(tags, function (tag, index) {
                if (_.isEmpty(tag)) {
                    tags.splice(index, 1);
                } else if (tag.includes('span')) {
                    tags.splice(index, 1);
                }
            });

            return _.join(tags, '/');
        },

        /**
         * Finds the index of the giving node.
         *
         * @param {string} node Node to find the index on.
         *
         * @return {int} Return the node index.
         */
        findIndex(node) {
            let index;
            // Check index by element type.
            if (node.tagName === 'LI') {
                index = Array.prototype.indexOf.call(node.parentElement.childNodes, node);
            } else {
                let nodes = Array.prototype.slice.call(node.parentElement.querySelectorAll(node.tagName.toLowerCase() + ':not(.tag-icons)'));
                if (node.parentElement.tagName !== 'LI')
                    nodes = _.filter(nodes, function (o) {
                        return o.parentElement.tagName !== 'LI';
                    });
                if (!node.parentElement.classList.contains('exception'))
                    nodes = _.filter(nodes, function (o) {
                        return !o.parentElement.classList.contains('exception');
                    });
                index = nodes.indexOf(node);
            }
            if (index === -1) {
                this.findIndex(node.parentElement)
            } else {
                return index + 1;
            }
        },
        applyNotes() {
            let _this = this;
            _.forEach(this.notes.annotations, function (value) {
                // Set our class applier and section information.
                let data = {
                    annotationId: value.annotation.id,
                    tagColor: _this.getTagColor(value.tag),
                    tagId: value.tag
                };
                let applier = _this.applierUtil(data),
                        section = document.getElementById('action-wrapper-' + value.section_number);

                // Apply our highlights and add note preview.
                if (!_.isNull(section)) {
                    _this.highlightUtil(applier, section, value.annotation, 'add');
                }
            });
            _.forEach(this.notes.bookmarks, function (value) {
                //Update Bookmark icon
                if (value.isCodeAdmin) {
                    _this.updateBookmarkIcon(value.section_id, value);
                }
            });
            // Add event events for notes.
            let noteHovers = document.getElementsByClassName('highlighter');
            _.forEach(noteHovers, function (value) {
                value.addEventListener('mouseenter', function (event) {
                    EventBus.fire(
                            'toggle-note-preview-' + _this.stripIds(event.target.id),
                            {
                                mouse: true,
                                modal: true,
                                event: event
                            }
                    );
                });
                value.addEventListener('mouseleave', function (event) {
                    EventBus.fire(
                            'toggle-note-preview-' + _this.stripIds(event.target.id),
                            {
                                mouse: false,
                                modal: false
                            }
                    );
                });
            });
        },
        addNoteList(notes) {
            // Map out our sections to add notes too.
            let _this = this,
                    annotationSections = _.map(notes.annotations, 'section_number'),
                    bookmarkSections = _.map(notes.bookmarks, 'section_id'),
                    sections = _.uniqBy(_.concat(annotationSections, bookmarkSections));

            // Loop through our sections and add the component
            let ComponentClass = Vue.extend(NoteList);
            _.forEach(sections, function (value) {
                let baseElement = document.getElementById('action-wrapper-' + value)
                let element = baseElement.parentElement;

                if (element) {
                    // Get our list of annotations and bookmarks by section id.
                    let annotations = _.filter(notes.annotations, {section_number: value}),
                            bookmarks = _.filter(notes.bookmarks, {section_id: value}),
                            notesList = {
                                'annotations': (!_.isUndefined(annotations)) ? annotations : {},
                                'bookmarks': (!_.isUndefined(bookmarks)) ? bookmarks : {}
                            };

                    // Add  component.
                    let instance = new ComponentClass({
                        propsData: {
                            data: notesList,
                            tags: _this.$store.getters.getTags,
                            sectionId: _.toInteger(value)
                        }
                    });
                    instance.$mount();

                    // Skip past PBC tags if they are present.
                    let pbcTags = document.getElementById('tag-category-' + value)
                    if (pbcTags) {
                        pbcTags.after(instance.$el)
                    } else {
                        if (baseElement.nextSibling) {
                            baseElement.nextSibling.before(instance.$el)
                        } else {
                            element.after(instance.$el)
                        }
                    }
                }
            });
            this.$nextTick(() => {
                const activeHash = this.$store.getters.getActiveHash;
                if (activeHash) {
                    let content_id = _.replace(activeHash, new RegExp('text-id-', 'g'), '');
                    const element = document.getElementById('text-id-' + content_id);
                    if (element) {
                        EventBus.fire('scroll-to-target', element);
                        this.$store.commit('setActiveHash', null);
                    }
                }
            });
        },
        updateBookmarkIcon(sectionId, bookmark) {
            let element = document.getElementById('action-wrapper-' + sectionId),
                    bookmarkIcon = document.getElementById(sectionId + '_bookmark');

            if (element) {
                if (bookmark) {
                    //Update Bookmark icon
                    element.dataset.bookmark = bookmark.id;
                    bookmarkIcon.setAttribute('style', 'color: ' + this.$vuetify.theme.currentTheme.accent2 + ';');
                    bookmarkIcon.innerText = 'bookmark';
                } else {
                    element.dataset.bookmark = '';
                    bookmarkIcon.style.color = null;
                    bookmarkIcon.innerText = 'bookmark_border';
                }
            }
        },
        checkCopyPasteAccess(cpPublic) {
            if (cpPublic) {
                let elements = document.getElementById('contentParent')
                        .querySelectorAll('p,h1,td');
                _.forEach(elements, function (element) {
                    element.style.userSelect = 'text';
                });
            }
        },
        hasSubsections(id)
        {
            let hasChilds = false;
            if (id) {
                _.eachDeep(this.$store.getters.getChapterSections, function (dataId, key, path) {
                    if (key === 'parentContentID' && (dataId == id)) {
                        hasChilds = true;
                    }
                });
            }

            return hasChilds;
        },
        togglePrintMenu(id)
        {
            let element = document.getElementById('print-menu-' + id);
            if (element != null && !_.isUndefined(element) && ('none' == element.style.display || '' == element.style.display)) {
                element.style.display = 'block';
            } else if (element != null && !_.isUndefined(element)) {
                element.style.display = 'none';
            }
        },
        printMenuOn(id)
        {
            let element = document.getElementById('print-menu-' + id);
            if (element != null && !_.isUndefined(element)) {
                element.style.display = 'block';
            }
        },
        printMenuOff(id)
        {
            let element = document.getElementById('print-menu-' + id);
            if (element != null && !_.isUndefined(element)) {
                element.style.display = 'none';
            }
        },
        applyTitleAttributes() {
            // Blue text
            this.insertTitle(
                    [
                        '[data-changed="changed_level0"]',
                        '.changed_ICC',
                        '.change_virginia'
                    ],
                    'Technical code changes from previous edition of the I-Codes are shown in blue text'
                    );
            // Red text
            this.insertTitle(
                    [
                        '[data-changed="changed_level1"]',
                        '[class^=\'changed_nonICC\']'
                    ],
                    'National (outside the US) and state amendments and errata to the I-Codes are shown in red text'
                    );
            // Fuchsia text
            this.insertTitle(
                    [
                        '[data-changed="changed_level2"]',
                        '.changed_level2',
                        '.changed_nonICC_NYC'
                    ],
                    'City of local amendments and errata to the I-codes are shown in fuchsia text'
                    );
            // Deletion markers
            this.insertTitle(
                    [
                        '[class^=\'deletion_marker\']',
                        '.deletionMarker'
                    ],
                    'Deletion indicators are provided in margin where an entire section, paragraph, table or exception has been deleted from the prior cycle of the title.',
                    '\u{27A1}'
                    );
            // Relocation text
            this.insertTitle(
                    [
                        '.relocated_to'
                    ],
                    'Single asterisk indicates text or table has been relocated within the code.  Full relocation table listed in Preface.'
                    );
            // Changed text
            this.insertTitle(
                    [
                        '.changed_to'
                    ],
                    'Double asterisk indicates text or table immediately following it has been relocated from elsewhere in the code.  Full relocation table listed in Preface.'
                    );
        },
        insertTitle(selectorArray, message, icon = null) {
            let changed = document.querySelectorAll(selectorArray);
            let i;
            for (i = 0; i < changed.length; i++) {
                // Apply title attrib text
                changed[i].title = message;

                // Apply deletion marker icon
                let style = changed[i].className;
                let iccChangedDeletion = style ? _.includes(style, 'deletion_marker_nonICC') : false;
                let hasText = false;

                if (icon && !iccChangedDeletion) {
                    const parent = changed[i].parentNode;
                    if (parent && changed[i].textContent.trim() !== '') {
                        // span.deletion_marker has text - need to separate it out into it's own span
                        let textSpan = document.createElement('span');
                        textSpan.classList.add('deletion-marker-text'); // just in case
                        textSpan.textContent = changed[i].textContent;
                        changed[i].textContent = '';
                        parent.insertBefore(textSpan, changed[i].nextSibling);
                        hasText = true;
                    }
                    let iconClass = 'hex-deletion-marker '
                    if (changed[i].tagName === 'TD' || changed[i].classList.contains("in-table")) {
                        iconClass += 'hex-deletion-marker-table '
                        if (!changed[i].textContent) {
                            iconClass += 'hex-deletion-marker-table-blank'
                        }
                        changed[i].closest('table').classList.add('hex-deletion-marker-table-margin');
                    } else if (changed[i].classList.contains("in-table-note")) {
                        iconClass += 'hex-deletion-marker-table-note'
                    } else if (changed[i].classList.contains("in-def-item")) {
                        iconClass += 'hex-deletion-def-item'
                    } else if (changed[i].tagName === 'LI' || changed[i].classList.contains("in-list")) {
                        iconClass += 'hex-deletion-marker-list-text'
                    } else {
                        if (parent && parent.textContent) {
                            if (parent.tagName === 'DIV' || hasText) {
                                iconClass += 'hex-deletion-marker-div-text'
                            } else if (parent.tagName === 'P') {
                                iconClass += 'hex-deletion-marker-paragraph-text'
                            }
                        }
                    }
                    changed[i].innerHTML = `<span class="${iconClass}">${icon}</span> ${changed[i].textContent}`
                }
            }
        },
        isExamPreview() {
            return this.examId && '' !== this.examId;
        },

        /**
         * Get absolute xPath position from dom element
         * xPath position will does not contain any id, class or attribute, etc selector
         * Because, Some page use random id and class. This function should ignore that kind problem, so we're not using any selector
         *
         * @param {Element} element element to get position
         * @returns {String} xPath string
         */
        getXPath(element) {
            // Selector
            let selector = '';
            // Loop handler
            let foundRoot;
            // Element handler
            let currentElement = element;

            // Do action until we reach html element
            do {
                // Get element tag name
                const tagName = currentElement.tagName.toLowerCase();
                // Get parent element
                const parentElement = currentElement.parentElement;

                // Count children
                if (parentElement.childElementCount > 1) {
                    // Get children of parent element
                    const parentsChildren = [...parentElement.children];
                    // Count current tag
                    let tag = [];
                    parentsChildren.forEach(child => {
                        if (child.tagName.toLowerCase() === tagName)
                            tag.push(child) // Append to tag
                    })

                    // Is only of type
                    if (tag.length === 1) {
                        // Append tag to selector
                        selector = `/${tagName}${selector}`;
                    } else {
                        // Get position of current element in tag
                        const position = tag.indexOf(currentElement) + 1;
                        // Append tag to selector
                        selector = `/${tagName}[${position}]${selector}`;
                    }

                } else {
                    //* Current element has no siblings
                    // Append tag to selector
                    selector = `/${tagName}${selector}`;
                }

                // Set parent element to current element
                currentElement = parentElement;
                // Is root
                foundRoot = parentElement.tagName.toLowerCase() === 'html';
                // Finish selector if found root element
                if (foundRoot)
                    selector = `/html${selector}`;
            } while (foundRoot === false);

            // Return selector
            return selector;
        }
    },
    created() {
        this.debouncePeakAhead = _.debounce(this.showPeakAhead, 750);
    }
}
