<template>
    <v-row dense v-if="displayPremiumTrialBlock">
        <v-col class="pt-0">
            <v-alert
                class="trial-block-alert-box"
                type="success"
                colored-border
                elevation="0"
                icon="mdi-creation"
                border="left"
                color="accent2"
                :style="`background-color: ${this.$vuetify.theme.themes.light.accent2Light};`">
                <div>
                    <h6 class="mb-2 text-h6">Upgrade to Digital Codes Premium for access</h6>
                    <p class="text-body-1">Subscribe to Premium to view real code questions, asked by users like you and answered by the ICC team of experts.</p>
                </div>
                <v-btn
                    @click="premiumSubscribe"
                    depressed
                    class="font-weight-bold mb-2"
                    color="accent2">
                    Subscribe to Premium
                </v-btn>
                <StartFreeTrial
                    color="black"
                    class="mb-2"
                    outlined
                    :maxHeight="35"/>
                <v-btn
                    text
                    class="font-weight-bold mb-2"
                    target="_blank"
                    :href="getRoute('premium_features')">
                    Learn About Premium
                </v-btn>
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
    import StartFreeTrial from '../buttons/StartFreeTrial.vue';

    export default {
        data() {
            return {
                isLoading: false,
                showCancelConfirmDialog: false,
            }
        },
        components: {StartFreeTrial},
        computed: {
            displayPremiumTrialBlock() {
                const isSubscriptionLoaded = this.$store.getters.isSubscriptionLoaded;
                const user = this.$store.getters.getUser;
                if (user && isSubscriptionLoaded && (!this.hasPremiumSubscription || this.trialActive)) {
                    return true;
                }

                return false;
            }
        },
        methods: {
            premiumSubscribe() {
                this.goToRoute('pricing_page');
            }

        },
    }
</script>