<template>
    <div>
        <loading
            v-if="!user"
            class="my-12">
        </loading>
        <div v-else>
            <trial-active v-if="trialActive"></trial-active>
            <template v-else>
                <trial-expired v-if="trialStatus === 'expired' && !canStartTrial"></trial-expired>
                <template v-else>
                    <trial-header>
                        <template v-slot:header>
                            Start your FREE 14-Day Trial
                        </template>
                        <template v-slot:subHeading>
                            Enjoy the full-featured version a of Digital Codes <b>Premium Complete</b> subscription on a 14-Day no-risk trial.
                        </template>
                        <template v-slot:description>
                            <v-btn
                                x-large
                                color="primary"
                                :block="isMobilePort"
                                class="mb-8"
                                @click="triggerStartTrial"
                                :disabled="isBusy">
                                Start FREE Trial
                            </v-btn>
                            <p class="caption primary--text">
                                Try <b>Premium Complete</b> FREE for 14-days.<br/>
                                No credit card required.
                            </p>
                        </template>
                        <template
                            v-if="!isMobilePort"
                            v-slot:image>
                            <img
                                style="max-width: 400px"
                                :src="asset(require('../../images/illistrations/loud_speaker.webp'))"/>
                        </template>
                    </trial-header>
                </template>
                <what-are-premium-tools>
                    <template v-slot:button>
                        <v-btn
                            x-large
                            color="primary"
                            @click="getPremiumCompleteBook()">
                            Get Premium Complete
                        </v-btn>
                    </template>
                </what-are-premium-tools>
                <testimonials :testimonials="testimonials">
                </testimonials>
            </template>
        </div>
    </div>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';

    import Loading from "../content/partials/loading";
    import TrialHeader from './TrialHeader';
    import WhatArePremiumTools from "../content/partials/WhatArePremiumTools";
    import Testimonials from "../content/partials/Testimonials";
    import TrialExpired from "./TrialExpired.vue";
    import TrialActive from "./TrialActive.vue";

    export default {
        data() {
            return {
                isBusy: false,
                testimonials: [
                    {
                        text: 'The access to all the data, connections, history and related standards make this option invaluable to anyone involved in the codes world-architects, engineers , contractors and the general will benefit with this selection of products. I will save a hundred hours with this premium set of digital codes. Thank You!',
                        name: '— Design Professional and ICC Digital Codes Premium user'
                    },
                    {
                        text: 'I would recommend to any architect, interior designer, structural engineer, electrical engineer, etc.',
                        name: '— Professional Architect and ICC Digital Codes Premium user'
                    }
                ],
            }
        },
        mixins: [Mobile],
        components: {
            TrialActive,
            TrialExpired,
            Loading,
            TrialHeader,
            WhatArePremiumTools,
            Testimonials
        },
        computed: {
            /**
             *
             * @returns {User}
             */
            user() {
                return this.$store.getters.getUser;
            }
        },
        methods: {
            async triggerStartTrial() {
                this.isBusy = true;
                await this.pathmonkConversionPixel()
                this.startTrial();
            }
        }
    }
</script>
