var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container pt-2 pb-0" },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                {
                  attrs: {
                    dense: "",
                    align: !_vm.isMobilePort ? "center" : "top",
                  },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "float-left mr-1 mt-1",
                          staticStyle: { "border-radius": "12px" },
                          attrs: { size: 56 },
                        },
                        [_vm._t("avatar")],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("h1", {
                      staticStyle: { "line-height": "1.2" },
                      domProps: { innerHTML: _vm._s(_vm.title) },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "mb-0 grey--text text--darken-1",
                      domProps: { innerHTML: _vm._s(_vm.subTitle) },
                    }),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.quickAskEnabled
                ? _c(
                    "v-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "v-col",
                        [
                          !_vm.hasPremiumSubscription
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "primaryLight",
                                    staticStyle: {
                                      width: "auto",
                                      "border-radius": "4px 0 0 4px",
                                      display: "inline-block",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-2",
                                        attrs: { color: "primary", left: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                mdi-lock-outline\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "caption primary--text font-weight-bold mt-1 mr-2",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                PREMIUM REQUIRED\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          "x-small": _vm.isMobilePort,
                                          color: "accent2",
                                          elevation: "0",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleExpand()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                SUBSCRIBE TO PREMIUM\n                                "
                                        ),
                                        _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.expandActive
                                                  ? "mdi-menu-up"
                                                  : "mdi-menu-down"
                                              ) +
                                              "\n                                "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    color: "accent2",
                                    elevation: "0",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleExpand()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(
                                      "\n                            mdi-creation\n                        "
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                        PREMIUM ACTIVE\n                        "
                                  ),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.expandActive
                                            ? "mdi-menu-up"
                                            : "mdi-menu-down"
                                        ) +
                                        "\n                        "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.quickAskEnabled
        ? _c(
            "v-tabs",
            {
              staticClass: "white",
              attrs: { "align-tabs": "left" },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "v-tab",
                {
                  staticClass: "font-weight-bold",
                  class: _vm.activeTab === 0 ? "white--text" : "grey--darken-1",
                  attrs: { value: "0" },
                  on: { click: _vm.goToDatabase },
                },
                [_vm._v("\n            Q&A Database\n        ")]
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  staticClass: "font-weight-bold px-4",
                  class: _vm.activeTab === 1 ? "white--text" : "grey--darken-1",
                  attrs: { value: "1" },
                  on: { click: _vm.goToQuickConsult },
                },
                [_vm._v("\n            Quick Consult\n        ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }