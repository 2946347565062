var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-8 pb-4" },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: _vm.isMobilePort ? "12" : "" } }, [
                _c("h2", [_vm._v("Premium Subscriptions")]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: _vm.isMobilePort ? "12" : "" } },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      class: _vm.isMobilePort
                        ? "d-flex flex-row"
                        : "float-right mr-2",
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.toggleFilter,
                        callback: function ($$v) {
                          _vm.toggleFilter = $$v
                        },
                        expression: "toggleFilter",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          class: _vm.isMobilePort && "flex-grow-1",
                          attrs: {
                            outlined: "",
                            color: "primary",
                            value: "Active",
                            height: "40",
                            disabled: !_vm.hasSubscriptions,
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Active\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          class: _vm.isMobilePort && "flex-grow-1",
                          attrs: {
                            outlined: "",
                            color: "primary",
                            value: "Inactive",
                            height: "40",
                            disabled: !_vm.hasSubscriptions,
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Inactive\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          class: _vm.isMobilePort && "flex-grow-1",
                          attrs: {
                            dark: _vm.hasSubscriptions,
                            outlined: "",
                            color: "primary",
                            value: "All",
                            height: "40",
                            disabled: !_vm.hasSubscriptions,
                          },
                        },
                        [_vm._v("\n                    All\n                ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: _vm.isMobilePort ? "auto" : "3" } },
                [
                  _c("v-select", {
                    staticClass: "d-flex",
                    attrs: {
                      disabled: !_vm.hasSubscriptions,
                      label: "Sort By",
                      items: _vm.sortBy,
                      "menu-props": { bottom: true, offsetY: true },
                      dense: "",
                      outlined: "",
                      flat: "",
                    },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1 premium-subscriptions-table",
            attrs: { headers: _vm.headers, items: _vm.userSubscriptionsObj },
            on: {
              "update:items": function ($event) {
                _vm.userSubscriptionsObj = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "no-data",
                fn: function () {
                  return [
                    _c("p", { staticClass: "pt-4" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.basicMsg) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.Title",
                fn: function ({ item }) {
                  return [
                    _c("span", [_vm._v(_vm._s(item.year + " " + item.title))]),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        href: _vm.getRoute(
                                          "current_edition_redirect",
                                          { documentSlug: item.slug }
                                        ),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { small: "" } },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                                mdi-open-in-new\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "Open " +
                              _vm._s(item.year + " " + item.title) +
                              " in new tab"
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    item.status === "active"
                      ? _c(
                          "v-chip",
                          {
                            attrs: {
                              small: "",
                              color: "green darken-3",
                              dark: "",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s("Active") +
                                "\n                "
                            ),
                          ]
                        )
                      : _c(
                          "v-chip",
                          {
                            attrs: {
                              color:
                                item.sub_status === "expired"
                                  ? ""
                                  : "blue darken-4",
                              small: "",
                              dark: item.sub_status !== "expired",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.capitalized(item.sub_status)) +
                                "\n                "
                            ),
                          ]
                        ),
                  ]
                },
              },
              {
                key: "item.licences",
                fn: function ({ item }) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.licences) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.isAddRemoveLicenseFeatureEnabled &&
                    _vm.isLicenseRemoved(item)
                      ? _c(
                          "p",
                          {
                            staticClass: "caption grey--text text--darken-1",
                            class: _vm.isMobilePort && "text-wrap",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.renewalMsg(item)) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.BillingFrequency",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      { attrs: { align: "start", "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "mr-3",
                            attrs: { cols: "1", align: "left" },
                          },
                          [
                            "active" === item.status
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  class:
                                                    item.status === "active"
                                                      ? ""
                                                      : "d-none",
                                                  attrs: { icon: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            color:
                                                              item.auto_renew_date !==
                                                              ""
                                                                ? "green darken-3"
                                                                : "accent2",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            item.auto_renew_date !==
                                                              ""
                                                              ? "mdi-autorenew"
                                                              : "mdi-autorenew-off"
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            "" !== item.auto_renew_date
                                              ? "Subscription renews automatically"
                                              : "Subscription will not renew"
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "10", align: "left" } }, [
                          _c("span", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.billingType(item)) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "caption grey--text text--darken-1",
                              class: _vm.isMobilePort && "text-wrap",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.billingMsg(item)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isChangeDurationFeatureEnabled &&
                          _vm.hasPendingDurationChangeRequest(item)
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "caption grey--text text--darken-1",
                                  class: _vm.isMobilePort && "text-wrap",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.billingRenewalMsg(item)) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          left: "",
                          "open-on-click": "",
                          "offset-y": "",
                          transition: "false",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g({ attrs: { color: "black" } }, on),
                                    [_vm._v("mdi-dots-vertical")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.showActionsOrderDetails(item)
                                  },
                                },
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v("Order Details"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.isAddRemoveLicenseFeatureEnabled
                              ? [
                                  _vm.isSubscriptionChangeAllowed(item)
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.showActionsLicenseQuantity(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Change License Quantity"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isChangeDurationFeatureEnabled
                              ? [
                                  _vm.isSubscriptionChangeAllowed(item)
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.showActionsBillingFrequency(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Change Billing Frequency"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isSubscriptionChangeAllowed(item)
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.actionsCancelAutoRenew(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "red--text" },
                                      [_vm._v("Cancel Auto-renew")]
                                    ),
                                  ],
                                  1
                                )
                              : item.status === "inactive"
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.actionsCancelAutoRenew(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Resubscribe"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "confirm-dialog",
        {
          attrs: {
            "max-width": _vm.isMobilePort && 500,
            "is-open": _vm.dialogVisible,
          },
          on: { closed: _vm.emitClosed },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", { staticClass: "d-flex align-center" }, [
                    _vm._v(
                      "\n                Cancel Auto-renewal\n            "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-full text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4 primary--text",
                          attrs: { outlined: "" },
                          on: { click: _vm.emitClosed },
                        },
                        [
                          _vm._v(
                            "\n                       No\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary white--text",
                          attrs: { loading: _vm.isLoading },
                          on: { click: _vm.cancelSubscription },
                        },
                        [
                          _vm._v(
                            "\n                        Yes, Cancel Auto-renewal\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("p", { staticClass: "mb-0 pt-3 roboto fs-16" }, [
            _vm._v(
              "\n            Are you sure you want to cancel auto-renewal of your Digital Codes Premium subscription to\n            "
            ),
            _c("b", [
              _vm._v(
                _vm._s(
                  `${this.cancelSubscriptionObj.year} ${this.cancelSubscriptionObj.title}`
                )
              ),
            ]),
            _vm._v("?\n            "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n            You will continue to have access to your subscription contents and features through\n            "
            ),
            _c("b", [
              _vm._v(
                _vm._s(
                  _vm.getFormattedDate(this.cancelSubscriptionObj.expiration)
                ) + "."
              ),
            ]),
            _vm._v(
              "\n            After that date, your subscription will end and you will not receive any additional charges.\n        "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-overlay",
        { attrs: { value: _vm.isLoading } },
        [
          _c("v-progress-circular", {
            attrs: { size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }