<template>
    <v-menu
        offset-y
        left
        open-on-click
        allow-overflow
        z-index="1005"
        max-width="500px">
        <template v-slot:activator="{ on: onMenu }">

          <v-tooltip bottom>

            <template v-slot:activator="{on: onTooltip}">
              <v-btn
                  data-qa="navigation-logo-menu"
                  icon
                  text
                  role="button"
                  aria-label="ICC Solutions"
                  class="logo"
                  v-on="{...onMenu, ...onTooltip}">
                <v-icon color="black">mdi-apps</v-icon>
              </v-btn>
            </template>

            <span>More ICC Solutions</span>
          </v-tooltip>

        </template>
        <v-card class="menu-icons">
            <v-container fluid grid-list-sm>
                <v-layout row>
                    <v-flex>
                        <p class="text-center primary--text my-4 title font-weight-bold">ICC Family of Solutions</p>
                    </v-flex>
                </v-layout>
                <v-layout
                    row
                    wrap
                    class="text-center">
                    <v-flex
                        v-if="icons"
                        v-for="(icon, index) in icons"
                        :key="index"
                        xs4>
                        <v-card
                            :data-qa="'navigation-logo-menu-'+icon.fileSlug"
                            flat
                            :href="icon.link"
                            :aria-label="ariaLabelForUrl(icon.link)"
                            target="_blank"
                            class="py-2">
                            <v-card-text>
                                <img :src="icon.dataUri" :alt="icon.text">
                                <p class="mb-0 primary--text">{{ icon.text }}</p>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </v-menu>
</template>

<script>
    import {AppParameters} from "../../../AppParameters";

    export default {
        data: function () {
            return {
                icons: [
                    {
                        fileSlug: 'home',
                        text: 'ICC Home',
                        link: 'https://www.iccsafe.org/',
                        dataUri: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNjM2LjkgNTY4LjIiPjxkZWZzPjxzdHlsZT4uY3tmaWxsOm5vbmU7c3Ryb2tlOiMwYzU5NDA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDoyMXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYyIgZD0iTTIyNi42LDMwNS42YzAtMTYuNiwxMy40LTMwLDMwLTMwaDEyMy43YzE2LjYsMCwzMCwxMy40LDMwLDMwdjE4Ny45YzAsMTYuNiwxMy40LDMwLDMwLDMwaDEwMy4xYzE2LjYsMCwzMC4xLTEzLjUsMzAuMS0zMC4xVjIwNC42YzAtMTAuMS01LjEtMTkuNi0xMy42LTI1LjFMMzE4LjUsMjIsNzksMTc5LjVjLTguNCw1LjYtMTMuNSwxNS0xMy41LDI1LjF2Mjg4LjhjMCwxNi42LDEzLjUsMzAuMSwzMC4xLDMwLjFoMTAxLjFjMTYuNiwwLDMwLTEzLjQsMzAtMzB2LTE4Ny45aDBaIi8+PC9zdmc+',
                    },
                    {
                        fileSlug: 'my_icc',
                        text: 'myICC',
                        link: AppParameters.my_icc_url,
                        dataUri: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNjM2LjkgNTY4LjIiPjxkZWZzPjxzdHlsZT4uY3tmaWxsOm5vbmU7c3Ryb2tlOiMwYzU5NDA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDoyMXB4O308L3N0eWxlPjwvZGVmcz48cmVjdCBjbGFzcz0iYyIgeD0iMzYwLjEiIHk9IjQxMS4yIiB3aWR0aD0iMTI1LjUiIGhlaWdodD0iMTEyLjMiIHJ4PSIzMCIgcnk9IjMwIi8+PGxpbmUgY2xhc3M9ImMiIHgxPSIzODIuOCIgeTE9IjM5MS4yIiB4Mj0iMzgyLjgiIHkyPSI0MTIuMSIvPjxsaW5lIGNsYXNzPSJjIiB4MT0iNDYzLjUiIHkxPSIzOTEuMiIgeDI9IjQ2My41IiB5Mj0iNDEyLjMiLz48bGluZSBjbGFzcz0iYyIgeDE9IjQyMi45IiB5MT0iNDg3LjUiIHgyPSI0MjIuOSIgeTI9IjQ1My40Ii8+PGNpcmNsZSBjbGFzcz0iYyIgY3g9IjQyMi45IiBjeT0iNDUzLjQiIHI9IjYuNyIvPjxwYXRoIGNsYXNzPSJjIiBkPSJNMTM1LDUwNi41YzAtMTAxLjMsODIuMS0xODMuNCwxODMuNC0xODMuNGgwIi8+PGNpcmNsZSBjbGFzcz0iYyIgY3g9IjMxOC41IiBjeT0iMTYwLjYiIHI9IjExNC42Ii8+PGxpbmUgY2xhc3M9ImMiIHgxPSIxMzUiIHkxPSI1MDYuNSIgeDI9IjEzNSIgeTI9IjUyMy41Ii8+PHBhdGggY2xhc3M9ImMiIGQ9Ik0zODIuOCwzOTEuMmMwLTIyLjMsMTguMS00MC4zLDQwLjMtNDAuM3M0MC4zLDE4LjEsNDAuMyw0MC4zIi8+PC9zdmc+'
                    },
                    {
                        fileSlug: 'online_codes',
                        text: 'Digital Codes',
                        link: 'https://codes.iccsafe.org/',
                        dataUri: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNjM2LjkgNTY4LjIiPjxkZWZzPjxzdHlsZT4uY3tmaWxsOm5vbmU7c3Ryb2tlOiMwYzU5NDA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDoyMXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYyIgZD0iTTM4My40LDI1NC4zbDUxLjEtNTEuMU0zNTEuOSwyMjNsMzEuNSwzMS4zIi8+PHBhdGggY2xhc3M9ImMiIGQ9Ik00MDksMTQ5LjVsLTExNC4zLjJNMzc4LjgsMTE4LjJsMzEuNSwzMS4zTTM3OC44LDE4MWwzMS41LTMxLjMiLz48cGF0aCBjbGFzcz0iYyIgZD0iTTEzNC43LDIyOC4xaDExMy44TTEzNC43LDI3NS42aDExMy44TTEzNC43LDMyMy4yaDkxLjkiLz48cGF0aCBjbGFzcz0iYyIgZD0iTTI0OC40LDE0OS43bC0xNTIuOS0uMmMtMTYuNiwwLTMwLDEzLjQtMzAsMzB2MjY3LjFjMCwxNi42LDEzLjQsMzAsMzAsMzBoNDQ4LjFjMTYuNiwwLDMwLTEzLjQsMzAtMzBWMTc5LjVjMC0xNi42LTEzLjQtMzAtMzAtMzBsLTMzLjQtLjRNMjA2LDUyMy41aDIyNC45TTM4My40LDQ3Ni42djQ2LjlNMjUzLjUsNDc2LjZ2NDYuOSIvPjxwYXRoIGNsYXNzPSJjIiBkPSJNMzE4LjQsMTgxLjJ2MTYzLjVjMCwxNi42LDEzLjQsMzAsMzAsMzBoMTAwLjFjMTYuNiwwLDMwLTEzLjQsMzAtMzBWMTAwLjJjMC0xNi42LTEzLjQtMzAtMzAtMzBoLTEwMGMtMTYuNiwwLTMwLDEzLjQtMzAsMzB2MTcuNiIvPjwvc3ZnPg=='
                    },
                    {
                        fileSlug: 'cdp',
                        text: 'cdpACCESS',
                        link: 'https://www.cdpaccess.com/',
                        dataUri: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNjM2LjkgNTY4LjIiPjxkZWZzPjxzdHlsZT4uY3tmaWxsOm5vbmU7c3Ryb2tlOiMwYzU5NDA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDoyMXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYyIgZD0iTTU0My41LDUyMy41SDk1LjRjLTE2LjYsMC0zMC0xMy40LTMwLTMwdi0xODcuOWMwLTE2LjYsMTMuNC0zMCwzMC0zMGg2M3YxNDUuOWgzMjB2LTE0NS45aDY1LjFjMTYuNiwwLDMwLDEzLjQsMzAsMzB2MTg3LjljMCwxNi42LTEzLjQsMzAtMzAsMzBaIi8+PHBhdGggY2xhc3M9ImMiIGQ9Ik0xNTguNSwyNzUuNlY5OS41YzAtMTYuNiwxMy40LTMwLDMwLTMwaDI2MGMxNi42LDAsMzAsMTMuNCwzMCwzMHYxNzYuMSIvPjxwYXRoIGNsYXNzPSJjIiBkPSJNMjk2LjcsMzQ1LjFsMTEzLjYtMTEzLjRNMjI2LjcsMjc1LjZsNzAsNjkuNSIvPjwvc3ZnPg=='
                    },
                    {
                        fileSlug: 'store',
                        text: 'Store',
                        link: 'https://shop.iccsafe.org/',
                        dataUri: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNjM2LjkgNTY4LjIiPjxkZWZzPjxzdHlsZT4uY3tmaWxsOm5vbmU7c3Ryb2tlOiMwYzU5NDA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDoyMXB4O308L3N0eWxlPjwvZGVmcz48bGluZSBjbGFzcz0iYyIgeDE9IjE1My41IiB5MT0iMzgxLjEiIHgyPSI0ODEuNiIgeTI9IjM4MS4xIi8+PGxpbmUgY2xhc3M9ImMiIHgxPSIxODAuOCIgeTE9IjQ1MiIgeDI9IjQ1Mi43IiB5Mj0iNDUyIi8+PGxpbmUgY2xhc3M9ImMiIHgxPSI1MDEuOCIgeTE9IjI3NS42IiB4Mj0iMzgzIiB5Mj0iNzAuMiIvPjxsaW5lIGNsYXNzPSJjIiB4MT0iMjU0IiB5MT0iNzAuMiIgeDI9IjEzNC43IiB5Mj0iMjc1LjMiLz48cGF0aCBjbGFzcz0iYyIgZD0iTTk1LjQsMjI4LjFjLTE2LjYsMC0zMCwxMy40LTMwLDMwdjU1YzAsMy40LjMsNi44LjksMTAuMXMxLjQsNi40LDIuNCw5LjVsNTIsMTUwLjRjOC40LDI0LjIsMzEuMSw0MC40LDU2LjcsNDAuNGgyODIuNWMyNS40LDAsNDgtMTYsNTYuNS0zOS45bDUzLjYtMTUwLjdjMS4xLTMuMiwyLTYuNCwyLjYtOS44cy45LTYuOS45LTEwLjR2LTU0LjdjMC0xNi42LTEzLjQtMzAtMzAtMzBIOTUuNFoiLz48bGluZSBjbGFzcz0iYyIgeDE9IjU3Mi42IiB5MT0iMzIzLjIiIHgyPSI2Ni4zIiB5Mj0iMzIzLjIiLz48L3N2Zz4='
                    },
                    {
                        fileSlug: 'support',
                        text: 'Support',
                        link: 'https://support.iccsafe.org/',
                        dataUri: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNjM2LjkgNTY4LjIiPjxkZWZzPjxzdHlsZT4uY3tmaWxsOm5vbmU7c3Ryb2tlOiMwYzU5NDA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDoyMXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYyIgZD0iTTI1My41LDQ3NC41aC0zNS4xYy0zMy4xLDAtNjAtMjYuOS02MC02MHYtMjA1YzAtODguNCw3MS42LTE2MCwxNjAtMTYwaDBjODguNCwwLDE2MCw3MS42LDE2MCwxNjB2MTYzIi8+PHBhdGggY2xhc3M9ImMiIGQ9Ik0zMTguNSw5Ny4xIi8+PHBhdGggY2xhc3M9ImMiIGQ9Ik0yNzcuMyw0NTAuN2gxNi44YzEzLjEsMCwyMy44LDEwLjYsMjMuOCwyMy44aDBjMCwxMy4xLTEwLjcsMjMuOC0yMy44LDIzLjhoLTE2LjhjLTEzLjEsMC0yMy44LTEwLjYtMjMuOC0yMy44aDBjMC0xMy4xLDEwLjctMjMuOCwyMy44LTIzLjhaIi8+PHBhdGggY2xhc3M9ImMiIGQ9Ik0xNTguNSwyMjYuNmgtNDljLTE2LjYsMC0zMCwxMy40LTMwLDMwdjg1LjljMCwxNi42LDEzLjQsMzAsMzAsMzBoNDkiLz48cGF0aCBjbGFzcz0iYyIgZD0iTTQ3OC41LDIyNi4zbDUxLjIuMmMxNi41LDAsMjkuOSwxMy41LDI5LjksMzB2ODZjMCwxNi42LTEzLjQsMzAtMzAsMzBoLTUxLjEiLz48L3N2Zz4='
                    },
                ]
            }
        },
        methods: {
            ariaLabelForUrl(url) {
                const host = new URL(url).host;
                return `Link opens ${host} in a new tab`;
            }
        }
    }
</script>

<style lang="scss">
    .menu-icons {
      img {
        width: 50px;
      }

      a:hover {
        background: #eff4f2;
      }
    }
    @media all and (-ms-high-contrast:none)
    {
        *::-ms-backdrop, .menu-icons {
            min-width: 500px;
        }
    }
</style>
