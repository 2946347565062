var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      staticClass: "rounded py-0 px-2 py-1 my-1 mb-2",
      attrs: { color: "accent2Light" },
    },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-icon",
                { staticClass: "mt-n1", attrs: { color: "accent2" } },
                [_vm._v("\n                mdi-sale-outline\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", [
            _c("h4", { staticClass: "font-weight-bold" }, [
              _vm._v("\n                Discounts Applied:\n            "),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.savingsText, function (item, key) {
                return _c("li", { key: key }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.discount) +
                      "% " +
                      _vm._s(item.label) +
                      "\n                "
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }