var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "primaryLight text-center py-2",
          staticStyle: { "word-break": "normal" },
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", [
                _c("img", {
                  attrs: {
                    width: "auto",
                    height: "90",
                    src: _vm.asset(
                      require("../../images/banners/premium_answers.png")
                    ),
                    alt: "PREMIUMANSWERS",
                    title: "premiumanswers",
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tab === 0
        ? _c(
            "v-card-text",
            { staticClass: "pt-4" },
            [
              _c("p", [
                _vm._v(
                  "\n            Premium Answers delivers a constantly expanding searchable database of questions and their carefully crafted interpretations from the ICC team of technical experts. Support for expedited custom questions is upcoming.\n        "
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.blurbs, function (item, key) {
                return _c(
                  "v-row",
                  { key: key, attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("v-icon", { attrs: { color: "primary" } }, [
                          _vm._v(
                            "\n                    mdi-check\n                "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-col", [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item) +
                            "\n                "
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _c(
            "v-card-text",
            { staticClass: "pt-4" },
            [
              _c("html-block", { attrs: { slug: "quick-ask" } }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "fs-16 mt-4 text-center font-weight-medium w-full",
                  },
                  [
                    _vm._v("\n                No information yet."),
                    _c("br"),
                    _vm._v("  Please check back later.\n            "),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.sampleQuestionsPdfUrl !== ""
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        href: _vm.asset(_vm.sampleQuestionsPdfUrl),
                        target: "_blank",
                        large: "",
                        outlined: "",
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v(
                          "\n                mdi-download-outline\n            "
                        ),
                      ]),
                      _vm._v("\n            Sample Question\n        "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }