var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.user
        ? _c("loading", { staticClass: "my-12" })
        : _c(
            "div",
            [
              _vm.trialActive
                ? _c("trial-active")
                : [
                    _vm.trialStatus === "expired" && !_vm.canStartTrial
                      ? _c("trial-expired")
                      : [
                          _c("trial-header", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                        Start your FREE 14-Day Trial\n                    "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "subHeading",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                        Enjoy the full-featured version a of Digital Codes "
                                      ),
                                      _c("b", [_vm._v("Premium Complete")]),
                                      _vm._v(
                                        " subscription on a 14-Day no-risk trial.\n                    "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "description",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mb-8",
                                          attrs: {
                                            "x-large": "",
                                            color: "primary",
                                            block: _vm.isMobilePort,
                                            disabled: _vm.isBusy,
                                          },
                                          on: { click: _vm.triggerStartTrial },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Start FREE Trial\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "caption primary--text",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Try "
                                          ),
                                          _c("b", [_vm._v("Premium Complete")]),
                                          _vm._v(" FREE for 14-days."),
                                          _c("br"),
                                          _vm._v(
                                            "\n                            No credit card required.\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                !_vm.isMobilePort
                                  ? {
                                      key: "image",
                                      fn: function () {
                                        return [
                                          _c("img", {
                                            staticStyle: {
                                              "max-width": "400px",
                                            },
                                            attrs: {
                                              src: _vm.asset(
                                                require("../../images/illistrations/loud_speaker.webp")
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                    _vm._v(" "),
                    _c("what-are-premium-tools", {
                      scopedSlots: _vm._u([
                        {
                          key: "button",
                          fn: function () {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: { "x-large": "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getPremiumCompleteBook()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Get Premium Complete\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("testimonials", {
                      attrs: { testimonials: _vm.testimonials },
                    }),
                  ],
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }