var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c(
                "v-row",
                [_c("v-col", [_c("loading", { staticClass: "my-12" })], 1)],
                1
              ),
            ],
            1
          )
        : [
            !_vm.premiumAnswers.length
              ? _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("p", [
                        _vm._v(
                          "\n                    No results found.  Please update your search and try again.\n                "
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.premiumAnswers, function (item, key) {
              return _c(
                "v-card",
                { key: key, staticClass: "mb-5", attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-3 pb-1" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", [
                            _c(
                              "h3",
                              { staticClass: "primary--text mb-3 fs-18" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.getRoute(
                                        "premium_answers_view",
                                        {
                                          answerId: item.questionId,
                                          slug: item.slug,
                                        }
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.question) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("access-icon", {
                                attrs: {
                                  accessLevel: _vm.hasPremiumSubscription
                                    ? "premiumActive"
                                    : "premium",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      item.questionInDetail
                        ? _c("div", {
                            staticClass: "question-details",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.truncatedText(item.questionInDetail)
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "auto" } },
                            [
                              _c("show-titles", {
                                attrs: {
                                  booksInfo: item.titles,
                                  answerViewPage: false,
                                },
                                on: {
                                  titleClick: (data) =>
                                    _vm.chipClick("titleClick", data),
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { class: !_vm.isMobilePort && "text-right" },
                            [
                              _c("show-topics", {
                                attrs: {
                                  topics: item.topics,
                                  answerViewPage: false,
                                },
                                on: {
                                  topicClick: (data) =>
                                    _vm.chipClick("topicClick", data),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _vm.showMore
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              large: "",
                              color: "primary",
                              href: _vm.getRoute("premium_answers", {
                                page: _vm.page + 1,
                              }),
                              loading: _vm.lazyLoading,
                              disabled: _vm.lazyLoading,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.$emit("nextPage")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    Show More\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.loading && _vm.showScrollToTop
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              fab: "",
                              outlined: "",
                              color: "primary",
                              small: "",
                            },
                            on: { click: _vm.scrollTop },
                          },
                          [
                            _c("v-icon", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "\n                        mdi-arrow-collapse-up\n                    "
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }