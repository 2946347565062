<template>
    <v-menu
            offset-y
            left
            open-on-click
            z-index="1005"
            max-width="500px">
        <template v-slot:activator="{ on: onMenu }">

            <v-tooltip bottom>

                <template v-slot:activator="{on: onTooltip}">
                    <v-btn
                            data-qa="navigation-logo-menu"
                            icon
                            text
                            role="button"
                            aria-label="Help icon button opens help and feedback options"
                            class="logo"
                            v-on="{...onMenu, ...onTooltip}">
                        <v-icon color="black">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </template>

                <span>Help and Feedback</span>
            </v-tooltip>

        </template>

        <v-list class="navigation-help-menu">
            <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    :href="item.href ? item.href : null"
                    :target="item.newWindow ? '_blank' : '_self'"
                    v-on="{ click: item.onclick ? item.onclick : handlerThatDoesNothing}"
            >
                <v-list-item-icon class="mr-4">
                    <v-icon v-text="item.icon" class="black--text"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

    </v-menu>
</template>

<script>
import FeedbackChat from '../../inc/mixins/feedback_chat';
import {AsyncUtils} from "../../../util/AsyncUtils";
import {openLiveChat} from "../../../global";
export default {
    data() {
        return {
            items: [
                {
                    title: 'Browse Guides',
                    icon: 'mdi-lightning-bolt',
                    href: 'https://support.iccsafe.org/article-categories/digital-codes/',
                    newWindow: true
                },
                {
                    title: 'Onboarding Videos',
                    icon: 'mdi-television-play',
                    href: 'https://www.youtube.com/playlist?list=PL0sbkPS30egMHcaIqxGhmY9wAcOakCKAP',
                    newWindow: true
                },
                {
                    title: 'Contact Us',
                    icon: 'mdi-email-outline',
                    href: 'https://www.iccsafe.org/about/contact-icc/',
                    newWindow: true
                },
                {
                    title: 'Give Feedback',
                    icon: 'mdi-thumbs-up-down-outline',
                    onclick: () => {
                        EventBus.fire('show-feedback-modal');
                    }
                },
                {
                    title: 'Live Chat',
                    icon: 'mdi-tooltip-question-outline',
                    onclick: () => {
                        openLiveChat();
                    }
                }
            ]
        }
    },
    mixins: [FeedbackChat],
    methods: {
        handlerThatDoesNothing() {
            // do nothing
        }
    },
    mounted() {
        // this.initLiveChat();
    }
}
</script>
