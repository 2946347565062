var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.displayPremiumTrialBlock
    ? _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pt-0" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "trial-block-alert-box",
                  style: `background-color: ${this.$vuetify.theme.themes.light.accent2Light};`,
                  attrs: {
                    type: "success",
                    "colored-border": "",
                    elevation: "0",
                    icon: "mdi-creation",
                    border: "left",
                    color: "accent2",
                  },
                },
                [
                  _c("div", [
                    _c("h6", { staticClass: "mb-2 text-h6" }, [
                      _vm._v("Upgrade to Digital Codes Premium for access"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-body-1" }, [
                      _vm._v(
                        "Subscribe to Premium to view real code questions, asked by users like you and answered by the ICC team of experts."
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-bold mb-2",
                      attrs: { depressed: "", color: "accent2" },
                      on: { click: _vm.premiumSubscribe },
                    },
                    [
                      _vm._v(
                        "\n                Subscribe to Premium\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("StartFreeTrial", {
                    staticClass: "mb-2",
                    attrs: { color: "black", outlined: "", maxHeight: 35 },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-bold mb-2",
                      attrs: {
                        text: "",
                        target: "_blank",
                        href: _vm.getRoute("premium_features"),
                      },
                    },
                    [
                      _vm._v(
                        "\n                Learn About Premium\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }