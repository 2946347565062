<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        id="login_form"
        method="post"
        :action="formAction"
        @submit.prevent="loginSubmit">
        <input
            v-if="referer != ''"
            type="hidden"
            name="_target_path"
            :value="referer">
        <input
            type="hidden"
            v-model="token"
            name="_csrf_token">
        <v-row>
            <v-col>
                <v-text-field
                    outlined
                    hide-details
                    id="emailAddress"
                    name="email"
                    maxlength="160"
                    type="email"
                    :readonly="isEnterprise"
                    :class="{ 'input-group--dirty': dirtyEmail}"
                    :error="emailAddressHasError"
                    :rules="[rules.required, validEmail]"
                    v-model="emailAddress"
                    v-on:keyup="keyMonitor"
                    placeholder="Email Address"
                    @blur="handleEmail"
                    @paste="onPaste"
                    required>
                    <v-icon
                        slot="append"
                        color="red"
                        v-if="emailAddressHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    outlined
                    hide-details
                    class="pass"
                    id="password"
                    name="password"
                    maxlength="50"
                    type="password"
                    :class="{ 'input-group--dirty': dirtyPassword}"
                    :error="passwordHasError"
                    :rules="[rules.required]"
                    @blur="checkError"
                    @paste="onPaste"
                    placeholder="Password"
                    v-model="password"
                    v-on:keyup="keyMonitor"
                    required>
                    <v-icon
                        slot="append"
                        color="red"
                        v-if="passwordHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="loginFailedCount >= 3">
            <v-col>
                <p class="mb-3"
                   :class="isEnterprise ? 'font-weight-bold' : ''">
                    Spam Protection
                </p>
                <v-text-field
                    outlined
                    hide-details
                    id="spamProtection"
                    name="spamProtection"
                    v-model="spamProtection"
                    :label="'What is '+captcha+'?'"
                    :error="captchaHasError"
                    :rules="[rules.required]"
                    @blur="captchaCheckError"
                    class="spam"
                    required>
                    <v-icon
                        slot="append"
                        color="red"
                        v-if="captchaHasError"
                    >
                        mdi-alert
                    </v-icon>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row
            dense
            align="center">
            <v-col :order="this.isEnterprise ? 2 : ''" :cols="this.isEnterprise && 12">
                <input
                    class="mr-1"
                    id="rememberMe"
                    type="checkbox"
                    name="rememberMe"
                    value="1">
                <label
                    :class="this.isEnterprise ? 'font-weight-bold': 'caption'"
                    for="rememberMe">
                    Keep me logged in for 30 days
                </label>
            </v-col>
            <v-col cols="auto" :order="this.isEnterprise ? 1 : ''">
                <a
                    :href="getRoute('request_reset')"
                    target="_blank">
                    <p :class="this.isEnterprise ? 'text-decoration-underline' : 'caption mb-0'">
                        Forgot your Password?
                    </p>
                </a>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                    block
                    large
                    color="primary"
                    :disabled="disabled"
                    @click="loginSubmit">
                    <template v-if="!disabled">
                        <b>Sign In</b>
                    </template>
                    <v-progress-circular
                        v-else
                        size="16"
                        indeterminate>
                    </v-progress-circular>
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import Forms from '../mixins/forms';

    export default {
        data() {
            return {
                rules: {
                    required: value => !!value || 'This field is required.'
                },
                passwordHasError: false,
                emailAddressHasError: false,
                captchaHasError: false,
                spamProtection: '',
                captcha: '',
                valid: true,
                dirtyEmail: null,
                dirtyPassword: null,
                emailAddress: '',
                password: '',
                disabled: false
            };
        },
        props: {
            formAction: {
                required: false,
                type: String
            },
            referer: {
                required: false,
                type: String
            },
            token: {
                required: true,
                type: String
            },
            loginFailed: {
                required: false,
                type: Boolean
            },
            isEnterprise :{
                required: false,
                type: Boolean,
                default: false
            },
            inviteEmail: {
                required: false,
                type: String,
                default: ''
            }
        },
        mixins: [
            Mobile,
            Forms
        ],
        methods: {
            handleEmail() {
                if (!_.isEmpty(this.emailAddress)) {
                    if (this.validEmail(this.emailAddress)) {
                        this.emailAddressHasError = false;
                    } else {
                        this.emailAddressHasError = true;
                    }
                } else {
                    this.emailAddressHasError = true;
                }
            },
            captchaCheckError(element) {
                if ('spamProtection' === element.target.name && element.target._value !== '') {
                    this.valdiateCaptcha(this.spamProtection);
                }
            },
            checkError(element){
                let errorName = element.target.name + 'HasError';
                let data = this;
                if(element.target._value === ''){
                    data[errorName] = true;
                } else {
                    data[errorName] = false;
                }
            },
            validateFields(){
                this.passwordHasError = !this.password ? true : false;
                this.emailAddressHasError = !this.emailAddress ? true : false;
                this.captchaHasError = !this.spamProtection ? true : false;
            },
            hasAnyError(){
                if (this.emailAddressHasError || this.passwordHasError){
                        this.isError = true;
                } else {
                    this.isError = false;
                }

                return this.isError;
            },
            loginSubmit(e) {
                this.validateFields();
                if (this.$refs.form.validate() && !this.hasAnyError()) {
                    this.checkUserEmailVerification();
                } else {
                    this.isError = true;
                    this.gtmEvent('Sign Up', 'Free Trial', 'Sign In Error');
                }
            },
            keyMonitor(event) {
                if (event.key == "Enter") {
                    this.loginSubmit();
                }
            },
            validEmail:function(email) {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            checkUserEmailVerification() {
                const statusNotVerified = 2;
                this.$http.get(Routing.generate('is_user_verified', {email: this.emailAddress}))
                    .then(response => {
                        this.gtmEvent('Sign Up', 'Free Trial', 'Sign In');
                        if (response.data.status == statusNotVerified) {
                            window.location = Routing.generate('confirm_register', {email: this.emailAddress});
                        } else {
                            document.getElementById("login_form").submit();
                        }
                    });
            },
            getFormCaptcha() {
                this.$http.get(Routing.generate('get_captcha')).then(
                    response => {
                        if (response.data) {
                            this.captcha = response.data;
                        }
                    }
                );
            },
            valdiateCaptcha(value){
                this.$http.get(Routing.generate('check_captcha', { 'solve' : value })).then(
                    response => {
                        if (response.data) {
                            this.captchaHasError = false;
                            this.isError = true;
                        } else {
                            this.captchaHasError = true;
                            this.isError = false;
                        }
                    }
                );
            },
            onPaste(evt) {

                /** @type {ClipboardEvent} */
                const ev = evt;

                let pasteData = (ev.clipboardData || window.clipboardData).getData('text');

                if (typeof pasteData === 'string' && pasteData.length > 0) {
                    const trimmed = pasteData.trim();

                    // prevent actual pasting and update this on our own
                    ev.preventDefault();

                    if (ev.target.id === 'emailAddress') {

                        this.$nextTick(() => {
                            // TODO: this.emailAddress = trimmed;
                        });

                        document.execCommand('insertText', false, trimmed);
                    } else if (ev.target.id === 'password') {
                        document.execCommand('insertText', false, trimmed);
                    }
                }
            }
        },
        mounted() {
            if(this.isEnterprise) {
                this.emailAddress = this.inviteEmail;
            }
            this.getFormCaptcha();
            let times = 0;
            const interval = setInterval(() => {
                times += 1;
                if ((this.dirtyEmail && this.dirtyPassword) || times === 40) {
                    clearInterval(interval);
                }
                this.dirtyEmail = document.querySelector('input[type="email"]:-webkit-autofill');
                this.dirtyPassword = document.querySelector('input[type="password"]:-webkit-autofill');
            }, 100);
        }
    }
</script>
