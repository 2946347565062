var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _vm.isLoading
        ? _c("loading", { staticClass: "my-12" })
        : [
            _c(
              "v-card-text",
              { staticClass: "py-0" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c(
                        "a",
                        {
                          staticClass: "fs-16",
                          attrs: { href: _vm.getRoute("quick_consult") },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mt-n1",
                              attrs: { color: "primary", left: "" },
                            },
                            [
                              _vm._v(
                                "\n                            mdi-arrow-left\n                        "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                        Back to Quick Consult\n                    "
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-divider"),
            _vm._v(" "),
            _c(
              "v-card-text",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("div", { staticClass: "mb-2" }, [
                          _c("h2", { staticClass: "oxygen" }, [
                            _vm._v(_vm._s(_vm.question?.title)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text-caption text--secondary" },
                          [
                            _vm._v(
                              "Submitted " +
                                _vm._s(_vm.formatDate(_vm.question?.createdAt))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.question?.description),
                          },
                        }),
                        _vm._v(" "),
                        _vm.question?.attachments
                          ? _c(
                              "div",
                              { staticClass: "mb-5" },
                              [
                                _c("h3", { staticClass: "oxygen mb-2" }, [
                                  _vm._v("Attachments"),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.question.attachments,
                                  function (attachment) {
                                    return _c(
                                      "a",
                                      {
                                        key: attachment.name,
                                        staticClass:
                                          "text-decoration-none mr-3",
                                        attrs: { href: "#" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(attachment.name) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _c("h3", { staticClass: "oxygen mb-2" }, [
                            _vm._v("Code Title and Section"),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "mb-1",
                            domProps: {
                              innerHTML: _vm._s(_vm.question?.codeTitle),
                            },
                          }),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "mb-1",
                            domProps: {
                              innerHTML: _vm._s(_vm.question?.codeSection),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.question?.answer
                          ? _c(
                              "div",
                              {
                                staticClass: "w-full mt-5 px-3",
                                staticStyle: {
                                  "border-left": "6px solid #ffa201",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center mb-3",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "oxygen mb-0 mr-3 fs-20" },
                                      [_vm._v("Answer from ICC Team")]
                                    ),
                                    _vm._v(" "),
                                    _c("v-chip", { attrs: { small: "" } }, [
                                      _vm._v(_vm._s(_vm.question?.status.text)),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.question.answer),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.questionWebLinks.length !== 0
                                  ? _c(
                                      "div",
                                      { staticClass: "my-4" },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "oxygen pb-2" },
                                          [_vm._v("References")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.questionWebLinks,
                                          function (item) {
                                            return _c("div", { key: item.id }, [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: item.object.url,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        item.object.title
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "disclaimer caption" },
                                  [
                                    _c("p", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("Disclaimer")]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        "\n\n                                ICC Premium Answers™ consist of advisory code opinions and interpretations issued by the\n                                International\n                                Code Council, Inc. (“ICC”) staff based on the ICC model codes. ICC Premium Answers do not imply\n                                approval\n                                of an equivalency, specific product, specific design, or specific installation and cannot be\n                                republished\n                                in any form implying ICC approval. As ICC Premium Answers are only advisory, the final code-related\n                                decision is the responsibility of the designated authority charged with the administration and\n                                enforcement of codes."
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "\n                                ICC will make reasonable efforts to provide accurate information as part of its ICC Premium Answers\n                                service. However, ICC makes no guarantees or warranties, express or implied, as to the accuracy of\n                                any\n                                information provided, including, without limitation, any warranties of merchantability or fitness\n                                for a\n                                particular purpose. ICC will not be held liable for any damages or loss, whether direct, indirect,\n                                consequential, or punitive, that may arise through your use of any information provided as part of\n                                ICC\n                                Premium Answers."
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.question?.answerAttachmentInfo
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-5" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              loading: _vm.isDownloading,
                                              color: "primary",
                                            },
                                            on: {
                                              click: _vm.downloadAnswerPdf,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "pr-1" },
                                              [
                                                _vm._v(
                                                  "\n                                    mdi-download-outline\n                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                Download Answer [PDF]\n                            "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _c("v-chip", { staticClass: "mt-2" }, [
                              _vm._v(_vm._s(_vm.question?.status.text)),
                            ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }