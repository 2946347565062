<template>
    <v-card outlined>

        <loading class="my-12" v-if="isLoading"></loading>

        <template v-else>
            <v-card-text class="py-0">
                <v-row>
                    <v-col>
                        <a
                            class="fs-16"
                            :href="getRoute('quick_consult')">
                            <v-icon
                                color="primary"
                                class="mt-n1"
                                left>
                                mdi-arrow-left
                            </v-icon>
                            Back to Quick Consult
                        </a>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider>
            </v-divider>
            <v-card-text>
                <v-row>
                    <v-col>
                        <div class="mb-2">
                            <h2 class="oxygen">{{ question?.title }}</h2>
                        </div>

                        <p class="text-caption text--secondary">Submitted {{ formatDate(question?.createdAt) }}</p>

                        <p v-html="question?.description"></p>

                        <div v-if="question?.attachments" class="mb-5">
                            <h3 class="oxygen mb-2">Attachments</h3>

                            <a href="#" v-for="attachment in question.attachments" :key="attachment.name" class="text-decoration-none mr-3">
                                {{ attachment.name }}
                            </a>
                        </div>

                        <div>
                            <h3 class="oxygen mb-2">Code Title and Section</h3>
                            <p class="mb-1" v-html="question?.codeTitle"></p>
                            <p class="mb-1" v-html="question?.codeSection"></p>
                        </div>

                        <div v-if="question?.answer"
                             class="w-full mt-5 px-3"
                             style="border-left: 6px solid #ffa201"
                        >

                            <div class="d-flex align-items-center mb-3">
                                <h3 class="oxygen mb-0 mr-3 fs-20">Answer from ICC Team</h3>
                                <v-chip small>{{ question?.status.text }}</v-chip>
                            </div>

                            <div v-html="question.answer"></div>

                            <div v-if="questionWebLinks.length !== 0" class="my-4">
                                <h3 class="oxygen pb-2">References</h3>
                                    <div
                                        v-for="item in questionWebLinks"
                                        :key="item.id">
                                        <a
                                            :href="item.object.url"
                                            target="_blank">
                                            {{item.object.title}}
                                        </a>
                                    </div>
                            </div>

                            <div class="disclaimer caption">

                                <p>
                                    <span class="font-weight-bold">Disclaimer</span>
                                    <br>

                                    ICC Premium Answers™ consist of advisory code opinions and interpretations issued by the
                                    International
                                    Code Council, Inc. (“ICC”) staff based on the ICC model codes. ICC Premium Answers do not imply
                                    approval
                                    of an equivalency, specific product, specific design, or specific installation and cannot be
                                    republished
                                    in any form implying ICC approval. As ICC Premium Answers are only advisory, the final code-related
                                    decision is the responsibility of the designated authority charged with the administration and
                                    enforcement of codes.</p>

                                <p>
                                    ICC will make reasonable efforts to provide accurate information as part of its ICC Premium Answers
                                    service. However, ICC makes no guarantees or warranties, express or implied, as to the accuracy of
                                    any
                                    information provided, including, without limitation, any warranties of merchantability or fitness
                                    for a
                                    particular purpose. ICC will not be held liable for any damages or loss, whether direct, indirect,
                                    consequential, or punitive, that may arise through your use of any information provided as part of
                                    ICC
                                    Premium Answers.</p>
                            </div>
                            <div v-if="question?.answerAttachmentInfo" class="mt-5">
                                <v-btn
                                    :loading="isDownloading"
                                    color="primary"
                                    @click="downloadAnswerPdf">
                                    <v-icon class="pr-1">
                                        mdi-download-outline
                                    </v-icon>
                                    Download Answer [PDF]
                                </v-btn>
                            </div>
                        </div>
                        <v-chip v-else class="mt-2">{{ question?.status.text }}</v-chip>
                    </v-col>
                </v-row>
            </v-card-text>
        </template>
    </v-card>
</template>

<script>
import { QuickConsultApi } from "../../../api/QuickConsultApi";
import Loading from "../../content/partials/loading.vue";
import {DateUtil} from "../../../util/DateUtil";

export default {
    components: {Loading},
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            question: null,
            isDownloading: false,
            questionWebLinks: []
        }
    },
    methods: {
        async loadQuestion(id) {
            this.isLoading = true;

            await QuickConsultApi.getQuestionDetails(id).then((res) => {
                this.question = res;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        loadQuestionWebLinks(id) {
            QuickConsultApi.getQuestionWebLinks(id).then((res) => {
                this.questionWebLinks = res;
            })
        },
        formatDate(date) {
            return date ? DateUtil.timeToMdy(date) : '';
        },
        downloadAnswerPdf() {
            this.isDownloading = true;
            QuickConsultApi.getAttachment(this.question.answerAttachmentInfo?.attachmentId).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.question.answerAttachmentInfo?.attachmentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).finally(() => {
                this.isDownloading = false;
            });
        }
    },
    mounted() {
        this.loadQuestion(this.id).then(()=>{
            if (this.question?.status.text === 'Answered') { // only answered qustion can see references
                this.loadQuestionWebLinks(this.id);
            }
        })
    }
}
</script>
