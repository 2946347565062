import {AppParameters} from "../AppParameters";

export const PremiumAnswersModule = {
    state: {
        premiumAnswersTab: 0,
    },
    getters: {
        getPremiumAnswersTab: (state) => state.premiumAnswersTab,
    },
    mutations: {
        setPremiumAnswersTab: (state, tab) => state.premiumAnswersTab = tab,
    },
}