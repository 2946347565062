<template>
    <div>
        <v-card
            v-if="loading"
            outlined>
            <v-row>
                <v-col>
                    <loading class="my-12">
                    </loading>
                </v-col>
            </v-row>
        </v-card>
        <template v-else>
            <v-row v-if="!premiumAnswers.length">
                <v-col>
                    <p>
                        No results found.  Please update your search and try again.
                    </p>
                </v-col>
            </v-row>
            <v-card
                outlined
                class="mb-5"
                v-for="(item, key) in premiumAnswers"
                :key="key">
                <v-card-text class="pt-3 pb-1">
                    <v-row dense>
                        <v-col>
                            <h3 class="primary--text mb-3 fs-18">
                                <a :href="getRoute('premium_answers_view', {answerId: item.questionId, slug: item.slug})">
                                    {{item.question}}
                                </a>
                            </h3>
                        </v-col>
                        <v-col cols="auto">
                            <access-icon
                                :accessLevel="hasPremiumSubscription ? 'premiumActive' : 'premium'">
                            </access-icon>
                        </v-col>
                    </v-row>
                    <div
                        v-if="item.questionInDetail"
                        v-html="truncatedText(item.questionInDetail)"
                        class="question-details">
                    </div>
                    <v-row>
                        <v-col cols="12" sm="auto">
                            <show-titles
                                :booksInfo="item.titles"
                                :answerViewPage="false"
                                @titleClick="(data) => chipClick('titleClick', data)">
                            </show-titles>
                        </v-col>
                        <v-col :class="!isMobilePort && 'text-right'">
                            <show-topics
                                :topics="item.topics"
                                :answerViewPage="false"
                                @topicClick="(data) => chipClick('topicClick', data)">
                            </show-topics>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-row justify="center">
                <v-col cols="auto">
                    <v-btn
                        v-if="showMore"
                        large
                        color="primary"
                        :href="getRoute('premium_answers', {page: page + 1})"
                        @click.prevent="$emit('nextPage')"
                        :loading="lazyLoading"
                        :disabled="lazyLoading"
                        class="mr-2">
                        Show More
                    </v-btn>
                    <v-btn
                        v-if="!loading && showScrollToTop"
                        fab
                        outlined
                        color="primary"
                        small
                        class="ml-1"
                        @click="scrollTop">
                        <v-icon
                            class="font-weight-bold">
                            mdi-arrow-collapse-up
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
    import Loading from "../content/partials/loading.vue";
    import AccessIcon from "../icons/AccessIcon.vue";
    import Mobile from "../inc/mixins/mobile";
    import ShowTitles from "./ShowTitles.vue";
    import ShowTopics from "./ShowTopics.vue";

    export default {
        name: 'AnswerList',
        components: {
            AccessIcon,
            Loading,
            ShowTitles,
            ShowTopics
        },
        props: {
            loading: {
                required: true,
                type: Boolean
            },
            premiumAnswers: {
                required: true,
                type: Array
            },
            lazyLoading: {
                required: false,
                type: Boolean,
                default: false
            },
            showMore: {
                required: false,
                type: Boolean,
                default: true
            },
            page: {
                required: false,
                type: Number,
                default: 1
            },
            showScrollToTop: {
                required: false,
                type: Boolean,
                default: true
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            scrollTop() {
                this.$scrollTo('#premiumAnswersFilter', 500, {offset: -90})
            },
            chipClick(emitType, id) {
                this.scrollTop()
                this.$emit(emitType, id)
            },
            truncatedText(text) {
                const limit = 175
                if (text.length > limit) {
                    return text.slice(0, limit) + '...'
                }
                return text
            }
        }
    }
</script>
