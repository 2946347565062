export class ArrayUtils {

    static rand(arr) {

        if (Array.isArray(arr)) {
            const randomIndex = Math.floor(Math.random() * arr.length);
            return arr[randomIndex];
        }

        return null;
    }

    /**
     *
     * @param {Array} arr
     * @param defaultValue
     * @return {Array}
     */
    static mapUndefinedInto(arr, defaultValue = null) {

        if (!Array.isArray(arr)) {
            return arr;
        }

        return arr.map(function (value) {
            return (typeof value === 'undefined') ? defaultValue : value;
        });
    }

    /**
     *
     * @param {Array} haystack
     * @param {Array} needles
     * @returns {boolean}
     */
    static containsAll(haystack, needles) {

        if (Array.isArray(haystack) && Array.isArray(needles)) {

            return needles.every((value) => {
                return haystack.includes(value);
            });
        }

        return false;
    }

    /**
     *
     * @param {Array} arr
     * @param value
     */
    static removeInPlaceWhere(arr, value) {

        if (Array.isArray(arr)) {

            const index = arr.indexOf(value);

            if (index !== -1) {
                arr.splice(index, 1);

                this.removeInPlaceWhere(arr, value);
            }
        }
    }

    /**
     *
     * @param {Array} arr
     * @param value
     */
    static addIfNotExist(arr, value) {

        if (Array.isArray(arr)) {

            if (!arr.includes(value)) {
                arr.push(value);
            }
        }
    }

    static convertObjectToArray(obj, key) {
        let returnArray = [];

        _.forEach(obj, function(value) {
            if (value[key]) {
                returnArray.push(value[key]);
            }
        })

        return returnArray;
    }

    /**
     * array: [{year: 1}, {year: 2}, {year: 3}]
     * callback: (item) => item.year
     *
     * Result: {year: 3}
     *
     * @param array
     * @param callback
     * @param defaultValue
     * @return {*|null}
     */
    static max(array, callback, defaultValue = null) {

        if (Array.isArray(array) && array.length && typeof callback === 'function') {

            return array.reduce((accumulator, current) => {

                const accumulatorVal = callback(accumulator);
                const currentVal = callback(current);

                return accumulatorVal > currentVal ? accumulator : current;

            });
        }

        return defaultValue;
    }

    // return shuffled array using Fisher-Yates
    static shuffle(array) {

        // Copy the slides array to avoid mutating the original array
        let copied = [...array];

        // Fisher-Yates shuffle algorithm
        for (let i = copied.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [copied[i], copied[j]] = [copied[j], copied[i]];
        }

        return copied;
    }
}
