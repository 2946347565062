var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "mb-0" }, [
      _vm._v("\n        For assistance,\n        "),
      _c(
        "a",
        {
          staticClass: "text-decoration-underline",
          attrs: {
            href: _vm.$getConst("links")["contactSupport"],
            target: "_blank",
          },
        },
        [_vm._v("\n            please contact customer support.\n        ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }