<template>
    <div>
        <p class="mb-0">
            For assistance,
            <a
                :href="$getConst('links')['contactSupport']"
                class="text-decoration-underline"
                target="_blank">
                please contact customer support.
            </a>
        </p>
    </div>
</template>
<script>
export default {
}
</script>
