<template>
    <v-container class="content-container py-6">
        <sub-main-heading
            main-heading="Hear from our users">
        </sub-main-heading>
        <v-carousel
            v-model="slideNumber"
            :show-arrows="false"
            hide-delimiters
            light
            height="auto"
            class="mb-10 mx-auto"
            id="top_carousel"
            style="max-width: 790px;">
            <v-carousel-item
                v-for="(item, i) in testimonials"
                :key="i">
                <div class="pa-5">
                    <v-card
                        elevation="7"
                        class="rounded-lg">
                        <v-card-text>
                            <v-icon
                                x-large
                                color="primary">
                                mdi-format-quote-close-outline
                            </v-icon>
                            <p class="fs-20">
                                {{ item.text }}
                            </p>
                        </v-card-text>
                    </v-card>
                </div>
            </v-carousel-item>
        </v-carousel>

        <v-carousel
            v-model="slideNumber"
            hide-delimiters
            height="auto"
            id="bottom_carousel"
            ref="bottom_carousel">

            <v-carousel-item
                v-for="(item, i) in testimonials"
                :key="i">
                <v-sheet
                    height="100%"
                    tile
                    light
                    color="transparent">

                    <v-row
                        class="d-flex flex-column fill-height mx-auto"
                        justify="center"
                        align="center"
                        no-gutters>
                        <p class="font-weight-medium mb-1 fs-20">{{ item.name }}</p>
                        <p class="mb-0 font-weight-medium">{{ item.title }}</p>
                    </v-row>

                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </v-container>
</template>

<script>
import SubMainHeading from "../headers/SubMain.vue";

export default {
    name: 'CustomerTestimonials',
    components: {SubMainHeading},
    data() {
        return {
            slideNumber: 0,
            testimonials: [
                {
                    text: `It's truly a full library at your fingertips.  Code enforcement cases that require having access to the code that was in place at the time of construction
                is invaluable.  Having access to current code development rationale and past code development rationale is instrumental in helping to make
                appropriate code interpretations and applications.  I am one who also uses the margins of my code books to insert meaningful comments
                that help remind me of the many nuances of the applications of the code.  As such, I \"lose\" these instructive and helpful notes every three years
                and the ability to have such notes automatically transferred to the next edition of the code is brilliant!  I look forward to the full experience of this electronic advantage!
                `,
                    name: "Kevin Jorgensen",
                    title: "Chief Building Official, City of Tracy"
                },
                {
                    text: `ICC digital codes have streamlined our electronic review process by allowing us to attach code references to our plan comments easily.`,
                    name: "Andie Lorenz",
                    title: "Building Official, Adams County"
                },
                {
                    text: `The ability to quickly reference our libraries of Digital Codes no matter where we and the ability to conduct advanced searches of those codes saves
                    us time every day and makes the Digital Codes a tool that we count on every day.`,
                    name: "Jeff Brown",
                    title: "State Building Codes Office Director, Virginia DHCD"
                },
                {
                    text: `The Digital Codes allows access to volumes of code information without the need to have the code books with you.
                    We utilize the digital code for our field inspectors which in turn made the inspector more proficient and provides the
                    capability the code professional to shear information with contractors, homeowners, and design professionals on the jobsite, from their mobile device.
                    It has also made the access of code sections quickly which has dramatically decreased the time it takes to review plans.`,
                    name: "James Moss",
                    title: "Project Manager"
                }
            ]
        };
    }
}
</script>

<style lang="scss" scoped>
    #top_carousel {
        min-height: 200px !important;
        display: flex;
        align-items: center;
    }

    #bottom_carousel {
        max-width: 550px;
        margin: 0 auto;
    }
</style>
