var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.askQuestionFormVisible
        ? _c("new-question-form")
        : _vm.showQuestion
        ? _c("question-view", { attrs: { id: _vm.showQuestion } })
        : [
            _c("your-questions-intro-block", { staticClass: "mb-5" }),
            _vm._v(" "),
            _c("your-questions-list"),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }