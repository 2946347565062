var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { id: "premiumAnswersFilter", outlined: "" } },
    [
      _c("v-card-text", [
        _c("h2", { staticClass: "mb-5" }, [
          _vm._v("Quick Consult - code questions answered by an expert"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Ask code questions on any of the International Codes (I-Codes).\n            Our ICC experts will provide a professional opinion of how the codes\n            apply to your specific circumstances within 2 business days.\n        "
          ),
        ]),
        _vm._v(" "),
        _vm.isAuthenticated
          ? _c("p", [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.creditsAvailable) +
                    " credits - buy credits to ask a question."
                ),
              ]),
              _vm._v(
                " Note that this is a paid service.\n            You must buy credits to ask questions, regardless of your Premium subscription status."
              ),
            ])
          : _c("p", [
              _c(
                "a",
                {
                  staticClass: "text-decoration-underline",
                  attrs: { href: this.getRoute("security_login") },
                },
                [_vm._v("Sign in\n            ")]
              ),
              _vm._v(" or\n            "),
              _c(
                "a",
                {
                  staticClass: "text-decoration-underline",
                  attrs: { href: this.getRoute("signup") },
                },
                [_vm._v("\n                create an account\n            ")]
              ),
              _vm._v(" "),
              _c("b", [_vm._v("to buy credits to ask a question.")]),
              _vm._v(
                " Note that this is a paid service.\n            You must buy credits to ask questions, regardless of your Premium subscription status.\n        "
              ),
            ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "v-btn",
              {
                attrs: {
                  disabled: !_vm.canAskQuestions,
                  color: "primary",
                  href: this.getRoute("ask_question"),
                },
              },
              [_vm._v("\n                Ask New Question\n            ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mx-1" }),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: {
                  disabled: !_vm.canPurchaseCredits,
                  outlined: "",
                  color: "primary",
                  href: "https://shop.iccsafe.org/",
                  target: "_blank",
                },
              },
              [_vm._v("\n                Purchase Credits\n            ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }