<template>
    <v-row
        class="hero-container"
        :class="{
            'py-16': !isMobilePort,
            'pb-8': isMobilePort
        }"
        align="center"
        justify="center">
        <v-col
            cols="12"
            sm="6">
            <h1
                class="text-h3 text-h4-lg font-weight-bold mb-6 oxygen"
                v-html="title">
            </h1>
            <h2
                v-if="subtitle"
                class="mb-6 text-body-1"
                v-html="subtitle">
            </h2>
            <slot name="button">
            </slot>
            <slot name="footer">
            </slot>
        </v-col>
        <v-col :class="{'text-center': isMediumAndDown}">
            <slot name="image">
            </slot>
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';

    export default {
        name: 'HeroBannerNew',
        props: {
            title: {
                required: true,
                type: String
            },
            subtitle: {
                required: false,
                type: String
            }
        },
        mixins: [
            Mobile
        ]
    }
</script>

<style
    lang="scss"
    scoped>
    .hero-container {
        img {
            max-width: 100%;
        }
    }
</style>
