var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "grey lighten-4 rounded-lg",
      attrs: { flat: "", height: "100%" },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-4" },
        [
          _c("v-icon", { attrs: { "x-large": "", color: _vm.iconColor } }, [
            _vm._v("\n            " + _vm._s(_vm.item.icon) + "\n        "),
          ]),
          _vm._v(" "),
          _c("h4", { staticClass: "text-h6 py-2" }, [
            _vm._v("\n            " + _vm._s(_vm.item.title) + "\n        "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-body-1 mb-0" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.item.description) + "\n        "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }