var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "font-weight-bold",
      attrs: {
        large: "",
        depressed: "",
        color: "accent2",
        href: `mailto:${_vm.$getConst("sales_email_address")}`,
      },
    },
    [_vm._v("\n    Contact Sales\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }