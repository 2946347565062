<template>
    <confirm-dialog
        v-if="dialog"
        :is-open="dialog"
        borderStyle
        showHeaderBorder
        noGrayActionArea
        :fullscreen="isMobilePort"
        @closed="emitClosed('quantity')">
        <template v-slot:title>
            {{ dialogTitle }}
        </template>
        <template v-slot:default>
            <alert-bar
                class="mt-3"
                v-if="hasLicenseRemovalRequest && !invalidVisaCart"
                id="display_license_remove_info"
                :message="`Subscription has pending change. Submitting a new change will overwrite the previously pending changes. <br />Pending change to be overwritten:
&emsp; <ul><li> Licenses quantity changes to ${getNextRenewalLicense()} licenses. Auto-renews on ${formatAutoRenewDate()} for $${getNextRenewalCost()}.</li></ul>`"
                notificationType="warning">
                <span v-if="isCancellingRequest">
                    <v-progress-circular
                        indeterminate
                        :size="25"
                        color="primary">
                    </v-progress-circular>
                </span>
                <a
                    v-else
                    @click="cancelLicenceChange()"
                    class="text-decoration-underline black--text">
                    Cancel previous license quantity change
                </a>
            </alert-bar>
            <div class="pt-4">
                <h3>Title</h3>
                <p class="mb-0">{{ displayDocumentTitle }}</p>
            </div>
            <license-quantity
                displayBigger
                v-if="step === 1"
                :actionDisabled="invalidVisaCart">
            </license-quantity>
            <div class="mt-2" v-else>
                <h3>License Quantity</h3>
                <p class="mb-0">
                    {{quantity}} licenses
                </p>
            </div>
            <p class="green--text darken-3"
                v-if="quantity !== item.licences">
                {{ licenseMessage }}
            </p>
            <discount-text
                v-if="savingsText.length"
                :savingsText="savingsText">
            </discount-text>
            <div class="mb-4">
                <h3>Billing Frequency</h3>
                <p class="mb-0">{{ displayBillingType() }}</p>
                <p class="mb-0">
                    Auto-renews {{ formatAutoRenewDate() }} for
                    <span v-if="loadingPrice">
                        <v-progress-circular
                            indeterminate
                            :size="25"
                            color="primary">
                        </v-progress-circular>
                    </span>
                    <span v-else>${{ getPrice |  formatPrice }}</span>
                </p>
                <p class="green--text darken-3 mb-0" v-if="quantity !== item.licences">
                    Changed from ${{ initialPrice | formatPrice }} / {{ billingPeriodShortText }}
                </p>
            </div>
            <div>
                <h3>Payment Method</h3>
                <p v-if="!invalidVisaCart">{{ visaCardNumber }}</p>
                <alert-bar
                    class="mt-3"
                    v-if="invalidVisaCart"
                    id="display_http_messages"
                    :message="`Payment method information not found. <a href='${getShopManagePaymentsPage()}' target='_blank' class='black--text text-decoration-underline'>
                        Update your payment method</a> to continue.`"
                    notificationType="warning">
                </alert-bar>
            </div>
            <p v-if="step === 2 && quantity > item.licences && !invalidVisaCart && getProRatePrice">
                You will be charged a prorated price of ${{ getProRatePrice |  formatPrice }} today.
                Your subscription will auto-renew with these changes for the full price of
                ${{ getPrice |  formatPrice }} {{ formatAutoRenewDate() }}. Includes applicable volume discounts.
            </p>
            <p v-else-if="step === 2 && quantity > item.licences && !invalidVisaCart && isFreeTransaction">
                There is no immediate charge required for the additional license(s) being added.
                Your subscription will auto-renew for the increased quantity of licenses at the price of ${{ getPrice |  formatPrice }} on {{ formatAutoRenewDate() }}.
                Includes applicable volume discounts.
            </p>
            <p v-else-if="step === 2 && quantity < item.licences && !invalidVisaCart">
                No payment required. This change will take effect when your subscription auto-renews.
            </p>
            <contact-link-info></contact-link-info>
        </template>
        <template v-slot:buttons>
            <v-container class="py-0 px-2">
                <v-row dense>
                    <v-col>
                        <alert-bar
                            dismissible
                            @dismiss="dismissAlert"
                            id="display_http_messages"
                            :message="message"
                            :notificationType="notifyType">
                        </alert-bar>
                    </v-col>
                </v-row>
                <v-row dense class="px-2" v-if="step === 2 && quantity > item.licences">
                    <v-col>
                        <h3 class="fs-20">Total Price Today</h3>
                    </v-col>
                    <v-col cols="auto">
                        <h3 class="fs-20">
                            <span v-if="loadingProratePrice">
                                <v-progress-circular
                                    indeterminate
                                    :size="25"
                                    color="primary">
                                </v-progress-circular>
                            </span>
                            <span v-else-if="getProRatePrice">${{ getProRatePrice |  formatPrice }}</span>
                            <span v-else-if="isFreeTransaction">$0.00</span>
                        </h3>
                    </v-col>
                </v-row>
                <action-buttons
                    :step="step"
                    :isDisableReviewBtn="isDisableReviewBtn"
                    :loadingSubmit="loadingSubmit"
                    :submitText="`Submit ${addedLicenseCount > 0 ? 'and Pay' : ''}`"
                    @go-back="handleGoBack"
                    @close="emitClosed('quantity')"
                    @review-changes="reviewChanges"
                    @submit-and-pay="submitAndPay">
                </action-buttons>
            </v-container>
        </template>
    </confirm-dialog>
</template>

<script>
    import AlertBar from '../../notifications/AlertBar.vue';
    import ConfirmDialog from '../../ConfirmDialog.vue';
    import LicenseQuantity from '../../pricing/LicenseQuantity.vue';
    import DiscountText from './DiscountText.vue';
    import ContactLinkInfo from './ContactLinkInfo.vue';
    import ActionButtons from './ActionButtons.vue';
    import subcriptionChangeMixin from './subcriptionChange.mixin';
    import { BillingApi } from '../../../api/BillingApi';
    import Mobile from '../../inc/mixins/mobile'

    export default {
        components: { AlertBar, ConfirmDialog, LicenseQuantity , DiscountText, ContactLinkInfo, ActionButtons},
        data() {
            return {
                isCancellingRequest: false
            }
        },
        mixins: [
            Mobile,
            subcriptionChangeMixin
        ],
        props: {
            shopUrl: {
                required: true,
                type: String
            },
            userId: {
                type: String,
                required: false
            }
        },
        computed: {
            licenseMessage() {
                let quantityDiff = 0;
                let actionType = '';
                if (this.quantity > this.item.licences) { //increased quantity
                    quantityDiff = this.addedLicenseCount;
                    actionType = 'Added';
                } else {
                    quantityDiff = this.removedLicenseCount;
                    actionType = 'Removed';
                }

                return `${actionType} ${quantityDiff} licenses`;
            },
            addedLicenseCount() {
                return this.quantity - this.item.licences;
            },
            removedLicenseCount() {
                return this.item.licences - this.quantity;
            },
            isDisableReviewBtn(){
                return this.item.licences === this.quantity || this.invalidVisaCart;
            },
            isDisableSubmitAndPayBtn(){
                return  (this.quantity > this.item.licences && this.proRatePrice === '') ||
                        this.loadingProratePrice === true;
            },
            isFreeTransaction() {
                return '0' === this.proRatePrice;
            },
            actionTriggered() {
                if (this.quantity < this.item.licences) {
                    return 'Remove Licenses';
                }
                return 'Add Licenses';
            },
            newValue() {
                return this.item.licences != this.quantity ? this.quantity : '';
            },
            priorValue() {
                return this.item.licences;
            },
            renewalQuantity(){
                return this.quantity;
            },
            renewalDuration(){
                return this.renewalBillingType;
            }
        },
        methods: {
            cancelLicenceChange(){
                this.isCancellingRequest = true;
                this.cancelLicenseRemovalRequest(true);
            },
            getNextRenewalLicense() {
                return this.item.next_renewal_license;
            },
            getNextRenewalCost() {
                return new Intl.NumberFormat('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(this.item.next_renewal_cost);
            },
            reviewChanges(){
                this.step = 2;
                if (this.quantity < this.item.licences) { // if licenses decreased then no need of prorate price.
                    this.logSubscriptionChange();
                    return false;
                }
                this.loadingProratePrice = true;
                const request = {
                    orderId: this.item.parent_order_id,
                    sku: this.item.sku + this.item.subscription_type,
                    licenses: this.quantity
                }
                BillingApi.proRatePrice(request)
                    .then((respose) => {
                        if (respose.data.success) {
                            this.proRatePrice =  respose.data.prorated_price;
                            this.logSubscriptionChange();
                        } else {
                            this.proRatePrice = '';
                            const notifyObj = {
                                'success': false,
                                'message': respose.data.message
                            };
                            this.notifyUser(notifyObj);
                        }
                    })
                    .catch((error) => {
                        const notifyObj = {
                            'success': false,
                            'message': error.response.data.error
                        };
                       this.notifyUser(notifyObj);
                    })
                    .finally(()=>this.loadingProratePrice = false)
            },
            addQuantityAction() {
                this.loadingSubmit = true;
                let paymentMethodInfo = {};

                if('0' === this.proRatePrice) {
                    paymentMethodInfo = {
                        method: 'free',
                    };
                } else {
                    paymentMethodInfo = {
                        method: 'paymentechhpf',
                        additional_data: {
                            customer_ref_num: this.getPaymentProfile.profile_id
                        }
                    };
                }
                const request = {
                    purchaseId: this.item.parent_increment_id,
                    paymentMethod: paymentMethodInfo,
                    billingAddress: this.getPaymentProfile.billing_address,
                    quantity: this.quantity - this.item.licences,
                    sku: this.item.sku,
                    subscription_type: this.item.subscription_type,
                    custom_price: this.proRatePrice,
                    subscription_id: this.item.subscription_id,
                    action: 'Add subscription licenses',
                    userId: parseInt(this.userId)
                }
                BillingApi.addLicensesCheckout(request)
                    .then((respose) => {
                        if (respose.data.success) {
                            const message = `${this.displayDocumentTitle} update successful - order #${this.item.parent_increment_id}. <a href='${this.getOrderDetailsUrl()}' target='_blank' class='black--text text-decoration-underline'>See order details for more information.</a>`;
                            this.logMessage = message;
                            this.logStatus = 'success';

                            // Check and cancel any license removal request as well
                            if(this.item.licences >= this.item.next_renewal_license) {
                                this.cancelLicenseRemovalRequest();
                            }
                            this.logSubscriptionChange();
                            EventBus.fire('sync-billing-page', true);
                            this.dialog = false;
                            this.setBillingUpdateMessage(message);
                        } else {
                            this.logMessage = respose.data.message;
                            this.logStatus = 'failed';
                            this.logSubscriptionChange();

                            const notifyObj = {
                                'success': false,
                                'message': respose.data.message
                            };
                            this.notifyUser(notifyObj);
                            this.proRatePrice = ''; // To disable the submit button
                        }
                    })
                    .catch((error) => {
                        this.logMessage = error.response.data.error;
                        this.logStatus = 'error';
                        this.logSubscriptionChange();

                        const notifyObj = {
                            'success': false,
                            'message': 'Oops, something went wrong. Please try again later.'
                        };
                        this.notifyUser(notifyObj);
                    })
                    .finally(()=>this.loadingSubmit = false)
            },
            removeQuantityAction() {
                this.loadingSubmit = true;
                const request = {
                    purchaseId: this.item.parent_increment_id,
                    orderId: this.item.parent_order_id,
                    sku: this.item.sku + this.item.subscription_type,
                    qty: this.quantity,
                    userId: parseInt(this.userId)
                }
                BillingApi.removeLicensesCheckout(request)
                    .then((respose) => {
                        if (respose.data.success) {
                            const message = `Subscription to ${this.displayDocumentTitle} updated successfully.`;

                            this.logMessage = message;
                            this.logStatus = 'success';
                            this.logSubscriptionChange();

                            EventBus.fire('sync-billing-page', false);
                            this.dialog = false;
                            this.setBillingUpdateMessage(message);
                        } else {
                            this.logMessage = respose.data.message;
                            this.logStatus = 'failed';
                            this.logSubscriptionChange();

                            const notifyObj = {
                                'success': false,
                                'message': respose.data.message
                            };
                            this.notifyUser(notifyObj);
                        }
                    })
                    .catch((error) => {
                        this.logMessage = error.response.data.error;
                        this.logStatus = 'error';
                        this.logSubscriptionChange();

                        const notifyObj = {
                            'success': false,
                            'message': error.response.data.error
                        };
                        this.notifyUser(notifyObj);
                    })
                    .finally(()=>this.loadingSubmit = false)
            },
            cancelLicenseRemovalRequest(cancelLicenseRequest = false) {
                const request = {
                    purchaseId: this.item.parent_increment_id,
                    subscriptionId: this.item.subscription_id
                }
                BillingApi.cancelLicenseRemovalRequest(request)
                    .then((respose) => {
                        if (respose.data.success) {
                            EventBus.fire('sync-billing-page', true);
                        }
                    })
                    .finally(()=>{
                        if (cancelLicenseRequest) {
                            this.isCancellingRequest = false;
                            this.dialog = false;
                            const message = `Subscription to ${this.displayDocumentTitle} updated successfully.`;
                            this.logMessage = message;
                            this.logStatus = 'canceled';
                            this.logSubscriptionChange();
                            this.setBillingUpdateMessage(message);
                        }
                    })
            },
            submitAndPay() {
                if (this.quantity > this.item.licences) { //licenses increased
                    this.addQuantityAction();
                } else {
                    this.removeQuantityAction();
                }
            },
        },
        created(){
            EventBus.listen('license-quantity-update-dialog', (data) => {
                this.subscriptionChangeType = 'License Quantity';
                this.resetState();
                this.item = data;
                this.emptyCart();
                this.setFrequency(data.subscription_type);
                this.setQuantity(data.licences);
                this.getDocInfoWithPrice(data.sku);
            });
        }
    }
</script>
