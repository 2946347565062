<template>
    <div class="grey lighten-5 container-height">
        <v-card outlined class="pb-4">
            <h1 class="content-container pt-3 pl-4">Billing</h1>
        </v-card>
        <div>
            <v-container class="content-container">
                <alert id="display_http_messages"
                    :message="message"
                    :notificationType="notifyType"
                    dismissible
                    @dismiss="dismissAlert">
                </alert>
                <div v-if="isDataLoaded">
                    <v-row align="center">
                        <v-col :cols="isMobilePort && 12">
                            <active-licenses
                                :userPremiumSubscriptions="userPremiumSubscriptions"
                                :remoteProfile="remoteProfile"
                                :userId="userid"
                                :shopurl="shopurl">
                            </active-licenses>
                        </v-col>
                        <v-col :cols="isMobilePort && 12">
                            <active-tier
                                :userPremiumSubscriptions="userPremiumSubscriptions"
                                :remoteProfile="remoteProfile"
                                :userId="userid">
                            </active-tier>
                        </v-col>
                    </v-row>
                    <premium-subscriptions
                        :userPremiumSubscriptions="userPremiumSubscriptions"
                        :remoteProfile="remoteProfile"
                        :userId="userid">
                    </premium-subscriptions>
                    <subscription-update
                        :userId="userid"
                        :shop-url="shopurl">
                    </subscription-update>
                </div>
                <div v-else class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                </div>
            </v-container>
        </div>
    </div>
</template>

<script>
    import Mobile from "../inc/mixins/mobile";
    import ActiveTier from "./ActiveTier.vue";
    import ActiveLicenses from "./ActiveLicenses.vue";
    import PremiumSubscriptions from "./PremiumSubscriptions.vue";
    import Alert from "../notifications/AlertBar.vue";
    import SubscriptionUpdate from './SubscriptionUpdate.vue';
    import {BillingApi} from '../../api/BillingApi';
    import {ProfileApi} from '../../api/ProfileApi';
    import { ApiService } from "../../api/ApiService";
    import { mapActions, mapGetters } from 'vuex';


    export default {
        name: 'BillingPage',
        data: () => ({
                userPremiumSubscriptions: [],
                loading: true,
                notifyType: null,
                message: '',
                remoteProfile: null
            }),
        components: {
            ActiveTier,
            ActiveLicenses,
            PremiumSubscriptions,
            Alert,
            SubscriptionUpdate
        },
        computed: {
            ...mapGetters('BillingModule', [
                'getBillingUpdateMessage',
            ]),
            isDataLoaded() {
                if (this.userid) {
                    return this.remoteProfile && !this.loading;
                } else {
                    return this.personalProfileName && !this.loading;
                }
            }
        },
        watch: {
            getBillingUpdateMessage(val){
                if (val) {
                    const notifyObj = {
                        'success': true,
                        'message': val
                    };
                    this.notifyUser(notifyObj);
                    setTimeout(() => this.$scrollTo(
                        document.getElementById('display_http_messages'),
                        {offset: -140}
                    ), 1000);
                    }
            }
        },
        props: {
            shopurl: {
                required: true,
                type: String
            },
            userid: {
                type: String,
                required: false
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            ...mapActions('BillingModule', [
                'setPaymentProfile'
            ]),
            initialize(shopSync = false) {
                BillingApi.getUserSubscriptions(parseInt(this.userid))
                    .then((response)=> {
                        this.userPremiumSubscriptions = response.data;
                        this.loading = false;
                        if (shopSync) {
                            ApiService.shopSync();
                        }
                    }).catch((error) =>{
                        const notifyObj = {
                            'success': false,
                            'message': error.response.data.error
                        };
                       this.notifyUser(notifyObj);
                       this.loading = false;
                    });
            },
            notifyUser(data){
                this.notifyType = !data.success ? 'error' : 'success';
                this.message = data.message;
            },
            dismissAlert(){
                this.message = '';
            },
            getPaymentMethodInfo() {
                BillingApi.getPaymentProfile(parseInt(this.userid))
                    .then((response)=> {
                        if (response.data.success) {
                            this.setPaymentProfile(response.data.payment_profile);
                        } else {
                            const notifyObj = {
                                'success': false,
                                'message': response.data.message
                            };
                            this.notifyUser(notifyObj);
                        }
                    }).catch((error) =>{
                        const notifyObj = {
                            'success': false,
                            'message': error.response.data.message
                        };
                       this.notifyUser(notifyObj);
                    });
            }
        },
        created() {
            if (this.userid) {
                ProfileApi.getProfiles(this.userid)
                    .then((response)=> {
                        this.remoteProfile = response.data[0];
                    });
            }
            this.initialize();
            this.getPaymentMethodInfo();
            EventBus.listen('notify-cancel-subscription-auto-renew', (data) => {
                if(data.success){
                    this.initialize();
                }
                this.notifyUser(data);
                setTimeout(() => this.$scrollTo(
                    document.getElementById('display_http_messages'),
                    {offset: -140}
                ), 1000);
            });
            EventBus.listen('sync-billing-page', (shopSync = false)=>{
                this.initialize(shopSync);
            });
        }
    }

</script>
