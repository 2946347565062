<template>
    <v-card
        flat
        height="100%"
        class="grey lighten-4 rounded-lg">
        <v-card-text class="pa-4">
            <v-icon
                x-large
                :color="iconColor">
                {{item.icon}}
            </v-icon>
            <h4 class="text-h6 py-2">
                {{item.title}}
            </h4>
            <p class="text-body-1 mb-0">
                {{item.description}}
            </p>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        props: {
            item: {
                required: true,
                type: Object
            },
            iconColor: {
                required: false,
                type: String,
                default: 'primary'
            }
        }
    }
</script>